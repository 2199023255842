import React, { useEffect } from "react";
import { Div, Icon, Input } from "atomize";
import { Transition } from "react-transition-group";

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function SelectModalDropdown({
  list,
  label,
  placeholder,
  onChange,
  name,
  appliedFilters,
  getFilteredCars,
  ...rest
}) {
  const menu = React.useRef(null);
  const [value, setValue] = React.useState(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedListIndex, setSelectedListIndex] = React.useState(0);

  const scrollIntoView = (index) => {
    const outOfViewMin = 12;
    const outOfViewMax = menu.current.clientHeight - 32;
    const scrollPos = menu.current.scrollTop;
    const currentElementTop = index * 32 + 12;

    const checkViewPos = currentElementTop - scrollPos;

    // If down overflow
    if (checkViewPos > outOfViewMax) {
      menu.current.scrollTop = currentElementTop - outOfViewMax + 12;
    }
    // If up overflow
    else if (checkViewPos < outOfViewMin) {
      menu.current.scrollTop = currentElementTop - 12;
    }
  };

  const focusKeyDown = (e) => {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }

    if (isOpen) {
      const filteredModels = list.filter((item) =>
        item.name.toLowerCase().startsWith(appliedFilters.model.toLowerCase())
      );

      // Enter key
      if (e.keyCode === 13) {
        e.preventDefault();
        filteredModels[selectedListIndex] &&
          setValue(filteredModels[selectedListIndex].name);
        filteredModels[selectedListIndex] &&
          onChange &&
          onChange(name, filteredModels[selectedListIndex].name);
        filteredModels[selectedListIndex] &&
          getFilteredCars({
            ...appliedFilters,
            [name]: filteredModels[selectedListIndex].name,
          });
        setIsOpen(false);
      }

      // Up key
      else if (e.keyCode === 38) {
        let newValue = selectedListIndex - 1;

        if (newValue < 0) {
          newValue = list.length - 1;
        }

        setSelectedListIndex(newValue);
        scrollIntoView(newValue);
      }

      // Down key
      else if (e.keyCode === 40) {
        let newValue = selectedListIndex + 1;

        if (newValue > list.length - 1) {
          newValue = 0;
        }

        setSelectedListIndex(newValue);
        scrollIntoView(newValue);
      }
    } else {
      // Enter key
      if (e.keyCode === 13) {
        setIsOpen(true);
      }
    }
  };

  const onFocus = () => {
    setIsFocused(true);
    setIsOpen(true);
  };

  const onBlur = () => {
    setIsFocused(false);
    setIsOpen(false);
  };

  const onMenuItemClick = (item, index) => {
    setSelectedListIndex(index);
    setValue(item.name);
    scrollIntoView(index);
    setIsOpen(false);
    onChange && onChange(name, item.name);
    getFilteredCars({ ...appliedFilters, [name]: item.name });
  };

  useEffect(() => {
    isOpen && scrollIntoView(selectedListIndex);
  }, [isOpen]);

  return (
    <>
      <Div
        flexGrow="1"
        d="flex"
        align="center"
        justify="space-between"
        textWeight="500"
        p="0 1rem 0 1rem"
        border={{ r: "1px solid" }}
        borderColor="o-light"
        cursor="pointer"
        pos="relative"
        className="make-dropdown"
        {...rest}
      >
        <Div pos="absolute" top="0" left="0" right="0" bottom="0">
          <Input
            h="62px"
            minW="100%"
            cursor="pointer"
            p="1.25rem 1.25rem 1.25rem 3rem"
            textSize="paragraph"
            border="none"
            textColor="text-dark"
            placeholder="Select a model"
            placeholderTextColor="medium"
            bg="transparent"
            rounded="6px 0 0 6px"
            className="make-dropdown"
            value={appliedFilters.model ? appliedFilters.model : ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(name, e.target.value);
              // setIsOpen(e.target.value !== "");
              // e.target.value === "" && e.target.blur();
            }}
            onKeyDown={focusKeyDown}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Div>
        <Icon
          name="Search"
          color={isFocused ? "dark" : "medium"}
          size="20px"
          m={{ r: "0.75rem" }}
        />
        <Icon
          name="DownArrow"
          color={isFocused ? "dark" : "medium"}
          size="20px"
          style={{ pointerEvents: "none" }}
        />
        <Transition in={isOpen} timeout={duration} unmountOnExit>
          {(state) => (
            <Div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              pos="absolute"
              zIndex="2"
              top="calc(100% + 1px)"
              left="0"
              right="0"
              bg="white"
              rounded="md"
              border="1px solid"
              borderColor="o-light"
              shadow="3"
              p="0.75rem"
              maxH="11.5rem"
              overflow="auto"
              className="dropdown--menu"
              ref={menu}
            >
              {list &&
                list
                  .filter((item) =>
                    item.name
                      .toLowerCase()
                      .startsWith(appliedFilters.model.toLowerCase())
                  )
                  .map((item, index) => (
                    <Div
                      key={index}
                      onClick={() => onMenuItemClick(item, index)}
                      p="0.25rem 0.75rem"
                      rounded="lg"
                      h="2rem"
                      bg={selectedListIndex === index ? "brand100" : "white"}
                      textColor={
                        selectedListIndex === index ? "dark" : "medium"
                      }
                      hoverTextColor="dark"
                      textWeight="500"
                      cursor="pointer"
                      transition
                    >
                      {item.name}
                    </Div>
                  ))}
            </Div>
          )}
        </Transition>
      </Div>
    </>
  );
}
