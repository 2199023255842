import React from "react";
import { Div, Text } from "atomize";
// import { capitalizeFirstLetter } from "../../utilities/helpers";

export default function ProductSpecifications({ listing }) {
  const specifications = [
    {
      field: "Make",
      value: listing.make,
    },
    {
      field: "Model",
      value: listing.model,
    },
    {
      field: "Manufacturing year",
      value: listing.manufacturingYear,
    },
    {
      field: "Trim package",
      value: listing.trim,
    },
    {
      field: "Fuel type",
      value: listing.fuelType,
    },
    {
      field: "Transmission",
      value: listing.transmission,
    },
    {
      field: "Color",
      value: listing.color,
    },
    {
      field: "Max passengers",
      value: listing.maxPassengers,
    },
  ];
  return (
    <Div p={{ t: "4.5rem" }} maxW={{ md: "85%", lg: "70%" }}>
      <Text textSize="title" textWeight="500">
        Specifications
      </Text>
      <Div
        rounded="xl"
        shadow={{ sm: "2" }}
        border={{ sm: "1px solid" }}
        borderColor="o-light"
        p={{ xs: "1.5rem 0", sm: "1.5rem", md: "1.5rem 2rem" }}
        m={{ t: "1rem" }}
      >
        {specifications.map((item, index, id) => {
          return item.value ? (
            <Div
              d="flex"
              // flexDir={{ xs: "column", sm: "row" }}
              justify="space-between"
              // align={{ md: "center" }}
              // align=""
              key={index}
              p=".5rem 0"
              border={{ t: index === 0 ? "0" : "1px solid" }}
              borderColor="o-light"
            >
              <Text
                // textColor={{ xs: "light", sm: "medium" }}
                textColor="medium"
                textWeight="500"
                textSize="paragraph"
              >
                {item.field}
              </Text>
              <Text
                // textAlign={{ sm: "right" }}
                textWeight="500"
                textSize="paragraph"
              >
                {item.value}
              </Text>
            </Div>
          ) : null;
        })}
      </Div>
    </Div>
  );
}
