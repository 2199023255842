import React from "react";
import { Div, Button, Icon } from "atomize";
import MakeDropdown from "./MakeDropdown";
import SelectModalDropdown from "./SelectModalDropdown";

export default function SearchFilters({
  isLoading,
  appliedFilters,
  setFilters,
  getFilteredCars,
  carMakesAndModels,
  updateModels,
  allModels,
}) {
  return (
    <Div
      h="4rem"
      w="100%"
      shadow="3"
      rounded="md"
      border="1px solid"
      borderColor="o-light"
      m={{ b: "1.5rem" }}
      d="flex"
    >
      {/* Make Dropdown */}
      <MakeDropdown
        name="make"
        onChange={(name, value) => setFilters(name, value)}
        list={carMakesAndModels.carMakes}
        updateModels={updateModels}
        appliedFilters={appliedFilters}
        allModels={allModels}
        getFilteredCars={getFilteredCars}
      />

      {/* Modal Dropdown */}
      <SelectModalDropdown
        list={carMakesAndModels.carModels}
        name="model"
        onChange={(name, value) => setFilters(name, value)}
        appliedFilters={appliedFilters}
        getFilteredCars={getFilteredCars}
      />

      {/* Search Button */}
      <Div w="168px" p="0.5rem">
        <Button
          textSize="subheader"
          bg={isLoading ? "brand800" : "brand700"}
          hoverBg="brand800"
          rounded="md"
          w="100%"
          h="100%"
          textWeight="600"
          onClick={() => getFilteredCars(appliedFilters)}
        >
          {isLoading ? <Icon name="Loading2" color="white" /> : "Search"}
        </Button>
      </Div>
    </Div>
  );
}
