import React, { useState } from "react";
import { Text, Div, Icon, Checkbox } from "atomize";
import DefaultInput from "../common/atoms/DefaultInput";

export default function SeachableDropdown({
  list,
  appliedFilters,
  setFilters,
}) {
  const [value, setvalue] = useState("");
  const handleChange = (item, isSelected) => {
    if (!isSelected) {
      setFilters("userIds", [...appliedFilters.userIds, item.id]);
      return;
    }
    setFilters("userIds", [
      ...appliedFilters.userIds.filter((el) => el !== item.id),
    ]);
  };

  return (
    <>
      <Div p="1rem 1.5rem" border={{ b: "1px solid" }} borderColor="o-light">
        <DefaultInput
          placeholder="Search email"
          suffix={
            <Icon
              name="Search"
              size="20px"
              color="text-medium"
              pos="absolute"
              top="50%"
              right="1rem"
              transform="translateY(-50%)"
            />
          }
          handleChange={(e) => setvalue(e.target.value)}
        />
      </Div>
      <Div p="1rem 1.5rem" maxH="16rem" overflow="auto">
        {list
          .filter((item) =>
            item.email.toLowerCase().startsWith(value.toLowerCase())
          )
          .map((item) => {
            const isSelected = appliedFilters.userIds.includes(item.id);
            return (
              <Div
                tag="label"
                cursor="pointer"
                tabIndex="0"
                d="flex"
                h="2rem"
                align="center"
                key={item.id}
              >
                <Checkbox
                  size="20px"
                  activeColor="brand700"
                  m={{ r: "1rem" }}
                  checked={isSelected}
                  onChange={() => handleChange(item, isSelected)}
                />
                <Text textColor="text-medium" textWeight="500">
                  {item.email}
                </Text>
              </Div>
            );
          })}
      </Div>
    </>
  );
}
