import React from "react";
import { Text, Div, Checkbox } from "atomize";

export default function Plan({ plans, appliedFilters, setFilters }) {
  const handleChange = (plan, isSelected) => {
    if (!isSelected) {
      setFilters("plans", [...appliedFilters.plans, plan.toLowerCase()]);
      return;
    }
    setFilters(
      "plans",
      appliedFilters.plans.filter((item) => item !== plan.toLowerCase())
    );
  };

  return (
    <>
      <Div p="1rem 1.5rem" maxH="16rem" overflow="auto">
        {plans.map((plan) => {
          const isSelected = appliedFilters.plans.includes(plan.toLowerCase());
          return (
            <Div
              tag="label"
              cursor="pointer"
              tabIndex="0"
              d="flex"
              h="2rem"
              align="center"
            >
              <Checkbox
                size="20px"
                activeColor="brand700"
                m={{ r: "1rem" }}
                checked={isSelected}
                onChange={() => handleChange(plan, isSelected)}
              />
              <Text textColor="text-medium" textWeight="500">
                {plan}
              </Text>
            </Div>
          );
        })}
      </Div>
    </>
  );
}
