import React, { useContext } from "react";
import { Div, Text, Row, Col, Icon } from "atomize";
import { Link, useRouteMatch } from "react-router-dom";
import CustomContainer from "../atoms/CustomContainer";
import { AuthContext } from "../../contexts/auth";

export default function ProfileSidebar() {
  const { url } = useRouteMatch();
  const { user } = useContext(AuthContext);

  const profileOptions = [
    {
      name: "Edit profile",
      icon: "EditSolid",
      link: "/profile/edit-profile",
      id: "editProfile",
    },
    {
      name: "Account settings",
      icon: "SettingsSolid",
      link: "/profile/account-settings",
      id: "accountSettings",
    },
    {
      name: "Team members",
      icon: "UserSolid",
      link: "/profile/team-members",
      id: "teamMembers",
      hide: user.role !== "ADMIN",
    },
  ];

  return (
    <Div
      d={{ xs: "none", lg: "block" }}
      pos="fixed"
      top="7.5rem"
      left="0"
      right="0"
    >
      <CustomContainer>
        <Row>
          <Col size={{ lg: "3" }} offset={{ lg: "1" }}>
            <Div pos="relative">
              <Div pos="absolute" top="0" right="0" left="0">
                <Div fontFamily="primary">
                  {profileOptions
                    .filter((item) => !item.hide)
                    .map((option) => (
                      <Link key={option.name} to={option.link}>
                        <Div m={{ b: "1rem" }} d="flex" align="center">
                          <Icon
                            m={{ r: "15.68px" }}
                            name={option.icon}
                            color={url === option.link ? "medium" : "light"}
                            size="20px"
                          ></Icon>
                          <Text
                            textSize="paragraph"
                            textWeight="600"
                            textColor={url === option.link ? "dark" : "light"}
                          >
                            {option.name}
                          </Text>
                        </Div>
                      </Link>
                    ))}
                </Div>
              </Div>
            </Div>
          </Col>
        </Row>
      </CustomContainer>
    </Div>
  );
}
