import React, { useState, useContext } from "react";
import { Div, Text, Button, Image } from "atomize";
import GoogleLogin from "react-google-login";

import { AuthContext } from "../contexts/auth";
import { getErrorMessage } from "../utilities/helpers";
import { axiosInstance } from "../utilities/axiosInstance";

import DefaultInput from "../components/atoms/DefaultInput";
import PasswordInput from "../components/atoms/PasswordInput";
import FormGlobalMessage from "../components/atoms/FormGlobalMessage";
import { toast } from "react-toastify";

export default function SignIn() {
  const { loginUser } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({});
  const [formInteractions, setFormInteractions] = useState({
    isSubmitting: false,
    globalMessage: null,
    error: null,
  });

  /**
   * Change the interaction state of forms
   * @param {interaction states} e
   */
  const changeFormIntraction = (e) => {
    setFormInteractions({ ...formInteractions, ...e });
  };

  /**
   * Changes formValue on input change
   * @param {input event} e
   */
  const handleChange = (e) => {
    const { value, name } = e.target;

    let newFormValue = { ...formValues };
    newFormValue[name] = value;
    setFormValues(newFormValue);
  };

  /**
   * Form on submit
   * @param {form event} e
   */
  const signinUser = (e) => {
    e.preventDefault();

    if (!formValues.email || !formValues.password) {
      changeFormIntraction({
        globalMessage: "Please enter an email and password",
      });
      return;
    }
    changeFormIntraction({ isSubmitting: true, globalMessage: null });

    axiosInstance
      .post("/admin/login", formValues)
      .then((response) => {
        if (response.data.success) {
          const appState = {
            token: response.data.meta.token,
            user: response.data.data,
          };
          loginUser(appState);
        }
      })
      .catch((error) => {
        changeFormIntraction({
          isSubmitting: false,
          error: getErrorMessage(error),
        });
      });
  };

  const googleSuccessResponse = (response) => {
    axiosInstance
      .post("/admin/login/google", { tokenId: response.tokenId })
      .then((response) => {
        let appState = {
          token: response.data.meta.token,
          user: response.data.data,
        };
        loginUser(appState);
      })
      .catch((error) => {
        changeFormIntraction({
          isSubmitting: false,
          error: getErrorMessage(error),
        });
      });
  };

  const googleFailureResponse = (error) => {
    toast.error("Something went wrong");
  };

  return (
    <Div
      bg="brand100"
      p={{ x: { xs: "14px", md: "4rem" }, y: "4rem" }}
      minH="100vh"
    >
      <Div
        maxW="35rem"
        bg="white"
        border="1px solid"
        borderColor="gray300"
        rounded="lg"
        shadow="1"
        m="0 auto"
      >
        <Div
          p="1rem"
          d="flex"
          justify="space-between"
          border={{ b: "1px solid" }}
          borderColor="o-light"
        >
          <Text
            textSize="display1"
            textColor="black900"
            textWeight="600"
            p={{ xs: "1.5rem 1rem 0.5rem", sm: "1.5rem 1rem 0.5rem 2rem" }}
          >
            Sign in
          </Text>
        </Div>

        <Div p={{ xs: "3rem 2rem 3rem", sm: "3rem 3rem 4rem" }}>
          <GoogleLogin
            render={(renderProps) => (
              <Button
                pos="relative"
                w="100%"
                bg="brand600"
                rounded="lg"
                h="3rem"
                m={{ b: "1.5rem" }}
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <Image
                  src="/images/icons/google.svg"
                  w="16px"
                  pos="absolute"
                  top="50%"
                  left="1rem"
                  transform="translateY(-50%)"
                ></Image>
                Sign in with Google
              </Button>
            )}
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            buttonText="Sign in with Google"
            onSuccess={googleSuccessResponse}
            onFailure={googleFailureResponse}
            cookiePolicy={"single_host_origin"}
          />
          <Div d="flex" align="center" m={{ b: "1.5rem" }}>
            <Div bg="o-light" h="1px" flexGrow="1"></Div>
            <Div
              h="2rem"
              w="2rem"
              bg="white"
              d="flex"
              align="center"
              justify="center"
            >
              <Text textColor="light" textWeight="500" textAlign="center">
                OR
              </Text>
            </Div>
            <Div bg="o-light" h="1px" flexGrow="1"></Div>
          </Div>

          <form onSubmit={signinUser}>
            {formInteractions.globalMessage && (
              <FormGlobalMessage
                warning={formInteractions.globalMessage}
                m={{ b: "1.5rem" }}
              />
            )}
            <DefaultInput
              label="Email address"
              placeholder="Enter your email address"
              m={{ b: "1.5rem" }}
              value={formValues.email}
              type="email"
              name="email"
              handleChange={handleChange}
            />
            <Div
              d="flex"
              justify="space-between"
              textColor="medium"
              textWeight="500"
              m={{ b: "0.25rem" }}
            >
              <Text textSize="caption" textColor="text-light">
                Password
              </Text>
              {/* <Text
                textSize="caption"
                textAlign="right"
                textDecor="underline"
              //   onClick={openResetPasssword}
              >
                Forgot password?
              </Text> */}
            </Div>
            <PasswordInput
              placeholder="Enter your password"
              m={{ b: "2.5rem" }}
              name="password"
              error={formInteractions.error}
              handleChange={handleChange}
              value={formValues.password}
              showEyeIcon={false}
            />
            <Div
              d="flex"
              flexDir={{ xs: "column", sm: "row" }}
              align="center"
              justify="space-between"
            >
              <Button
                type="submit"
                bg="brand700"
                rounded="circle"
                maxW={{ sm: "13.5rem" }}
                flexGrow={{ sm: "1" }}
                h="3rem"
                shadow="1"
                w={{ xs: "100%", sm: "auto" }}
                textWeight="600"
                m={{ xs: "0 0 0.5rem 0", sm: "0 1.5rem 0 0" }}
                disabled={formInteractions.isSubmitting}
              >
                Sign in
              </Button>
            </Div>
          </form>
        </Div>
      </Div>
    </Div>
  );
}
