import React from "react";
import { Div } from "atomize";
import { Swiper, SwiperSlide } from "swiper/react";

import PricingListFull from "../pricing/PricingListFull";

export default function PricingListMobile({
  setCurrentPlan,
  listing,
  close,
  plans,
}) {
  return (
    <Swiper
      spaceBetween={16}
      draggable={true}
      slidesPerView="auto"
      style={{ overflow: "visible" }}
    >
      <SwiperSlide style={{ width: "300px", maxWidth: "calc(100vw - 3.5rem)" }}>
        <Div shadow="3" bg="white" rounded="lg" overflow="hidden">
          <PricingListFull
            theme="pastel6"
            plans={plans}
            listKey="basic"
            setCurrentPlan={setCurrentPlan}
            currentPlan={listing.plan}
            close={close}
          />
        </Div>
      </SwiperSlide>
      <SwiperSlide style={{ width: "300px", maxWidth: "calc(100vw - 3.5rem)" }}>
        <Div shadow="3" bg="white" rounded="lg" overflow="hidden">
          <PricingListFull
            theme="brand700"
            plans={plans}
            listKey="advanced"
            setCurrentPlan={setCurrentPlan}
            currentPlan={listing.plan}
            close={close}
          />
        </Div>
      </SwiperSlide>
    </Swiper>
  );
}
