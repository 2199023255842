import React, { useState, useContext } from "react";
import { Div, Text, Button, Row, Col } from "atomize";
import { toast } from "react-toastify";

//http://localhost:3000/signup?action=invite&token=5312bce1d0e88131fb89b1c072c3fa57bac4bdcdd07e110d5d068340cf55
import { AuthContext } from "../contexts/auth";
import {
  isValidStr,
  getValidationMessage,
  getErrorMessage,
} from "../utilities/helpers";
import { axiosInstance } from "../utilities/axiosInstance";
import { isPasswordStrong } from "../utilities/helpers";

import DefaultInput from "../components/atoms/DefaultInput";
import PasswordInput from "../components/atoms/PasswordInput";
import FormGlobalMessage from "../components/atoms/FormGlobalMessage";

export default function SignUp({ token }) {
  const { loginUser } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({});
  const [formInteractions, setFormInteractions] = useState({
    isSubmitting: false,
    globalMessage: null,
    errors: {},
  });

  /**
   * Change the interaction state of forms
   * @param {interaction states} e
   */
  const changeFormIntraction = (e) => {
    setFormInteractions({ ...formInteractions, ...e });
  };

  /**
   * Changes formValue on input change
   * @param {input event} e
   */
  const handleChange = (e) => {
    const { value, name } = e.target;

    let newFormValue = { ...formValues };
    newFormValue[name] = value;
    setFormValues(newFormValue);
  };

  /**
   * Form on submit
   * @param {form event} e
   */
  const signupUser = (e) => {
    e.preventDefault();

    if (!formValues.firstName.trim()) {
      return changeFormIntraction({
        globalMessage: "Please enter firstname",
      });
    }

    if (!formValues.lastName.trim()) {
      return changeFormIntraction({
        globalMessage: "Please enter lastname",
      });
    }

    if (!formValues.password.trim()) {
      return changeFormIntraction({
        globalMessage: "Please enter password",
      });
    }

    if (
      formValues.password.trim() !== formValues.password_confirmation.trim()
    ) {
      return changeFormIntraction({
        globalMessage: "Password does not match.",
      });
    }

    const { error, msg } = isPasswordStrong(formValues.password);

    if (error) {
      return changeFormIntraction({
        globalMessage: msg,
      });
    }

    changeFormIntraction({
      isSubmitting: true,
      globalMessage: null,
      errors: {},
    });

    axiosInstance
      .post("admin/signup", { ...formValues, token })
      .then((response) => {
        if (response.data.success) {
          const appState = {
            token: response.data.meta.token,
            user: response.data.data,
          };
          loginUser(appState);
          toast.success("Signup success");
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          changeFormIntraction({
            isSubmitting: false,
            errors: {
              firstName: getValidationMessage(error, "firstName"),
              lastName: getValidationMessage(error, "lastName"),
              email: getValidationMessage(error, "email"),
              password: getValidationMessage(error, "password"),
              password_confirmation: getValidationMessage(
                error,
                "password_confirmation"
              ),
            },
          });
        } else {
          changeFormIntraction({ globalMessage: getErrorMessage(error) });
        }
      });
  };

  return (
    <Div bg="brand100" p="4rem" minH="100vh">
      <Div
        maxW="35rem"
        bg="white"
        border="1px solid"
        borderColor="gray300"
        rounded="lg"
        shadow="1"
        m="0 auto"
      >
        <Div
          p="1rem"
          d="flex"
          justify="space-between"
          border={{ b: "1px solid" }}
          borderColor="o-light"
        >
          <Text
            textSize="display1"
            textColor="black900"
            textWeight="600"
            p={{ xs: "1.5rem 1rem 0.5rem", sm: "1.5rem 1rem 0.5rem 2rem" }}
          >
            Sign Up
          </Text>
        </Div>
        <Div p={{ xs: "1.5rem 2rem 3rem", sm: "1.5rem 3rem 4rem" }}>
          <form onSubmit={signupUser}>
            {formInteractions.globalMessage && (
              <FormGlobalMessage
                error={formInteractions.globalMessage}
                m={{ b: "1.5rem" }}
              />
            )}
            <Row>
              <Col size={{ xs: "16", sm: "8" }}>
                <DefaultInput
                  label="First Name"
                  placeholder="Enter your first name"
                  m={{ b: "1.5rem" }}
                  name="firstName"
                  type="text"
                  value={formValues.firstName}
                  handleChange={handleChange}
                  required
                  error={
                    isValidStr(formInteractions.errors.firstName) &&
                    formInteractions.errors.firstName
                  }
                />
              </Col>
              <Col size={{ xs: "16", sm: "8" }}>
                <DefaultInput
                  label="Last Name"
                  placeholder="Enter your last name"
                  m={{ b: "1.5rem" }}
                  name="lastName"
                  type="text"
                  value={formValues.lastName}
                  handleChange={handleChange}
                  required
                  error={
                    isValidStr(formInteractions.errors.lastName) &&
                    formInteractions.errors.lastName
                  }
                />
              </Col>
            </Row>
            <Div
              d="flex"
              justify="space-between"
              textColor="medium"
              textWeight="500"
              m={{ b: "0.25rem" }}
            >
              <Text textSize="caption" textColor="text-light">
                Password
              </Text>
              <Text textSize="caption" textAlign="right" textColor="light">
                8 characters minimum
              </Text>
            </Div>
            <PasswordInput
              placeholder="Enter your password"
              m={{ b: "1.5rem" }}
              name="password"
              value={formValues.password}
              handleChange={handleChange}
              required
              error={
                isValidStr(formInteractions.errors.password) &&
                formInteractions.errors.password
              }
            />
            <PasswordInput
              label="Re-type Password"
              placeholder="Enter your password"
              m={{ b: "2.5rem" }}
              name="password_confirmation"
              value={formValues.password_confirmation}
              handleChange={handleChange}
              required
              error={
                isValidStr(formInteractions.errors.password_confirmation) &&
                formInteractions.errors.password_confirmation
              }
              showEyeIcon={false}
            />
            <Text
              textSize="caption"
              textWeight="500"
              m={{ b: "1.5rem" }}
              textColor="light"
            >
              By clicking the "Sign Up" button, you are creating a Swopr
              account, and you agree to Swopr's{" "}
              <Text tag="span" textColor="brand800">
                Terms of Use
              </Text>{" "}
              and{" "}
              <Text tag="span" textColor="brand800">
                Privacy Policy
              </Text>
              .
            </Text>
            <Div
              d="flex"
              flexDir={{ xs: "column", sm: "row" }}
              align="center"
              justify="space-between"
            >
              <Button
                bg="brand700"
                rounded="circle"
                maxW={{ sm: "13.5rem" }}
                flexGrow={{ sm: "1" }}
                h="3rem"
                shadow="1"
                textWeight="600"
                w={{ xs: "100%", sm: "auto" }}
                m={{ xs: "0 0 0.5rem 0", sm: "0 1.5rem 0 0" }}
                disabled={formInteractions.isSubmitting}
              >
                Sign up
              </Button>
            </Div>
          </form>
        </Div>
      </Div>
    </Div>
  );
}
