import React, { useState } from "react";
import { Div, Text, Row, Col, Checkbox, Button, Icon } from "atomize";
// import { toast } from "react-toastify";
// import Link from "next/link";
import ListingDeleteModal from "./ListingDeleteModal";
// import { axiosInstance } from "../../utilities/axiosInstance";
// import { getErrorMessage } from "../../utilities/helpers";
// import firestore from "../../firebase";

import ListingListItem from "./ListingListItem";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utilities/helpers";
import { axiosInstance } from "../../utilities/axiosInstance";

export default function ListingList({ listings, totalListings, updateState }) {
  const [select, setSelect] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  /**
   * Add or remove to selected Item
   * @param {list item} item
   */
  const addToSelected = (item) => {
    let newSelected = [...select];
    const indexOfItem = newSelected.indexOf(item);
    if (indexOfItem > -1) {
      newSelected.splice(indexOfItem, 1);
    } else {
      newSelected.push(item);
    }
    setSelect(newSelected);
  };

  /**
   * Select All the listing
   */
  const selectAll = () => {
    let newSelected = [];
    if (select?.length !== listings?.length) {
      newSelected = listings;
    } else {
      newSelected = [];
    }
    setSelect(newSelected);
  };

    const removeListings = () => {
    setDeleting(true);
    const ids = [];
    select.forEach((element) => {
      ids.push(element.id);
    });

    axiosInstance
      .post("listings/bulk-delete", { ids })
      .then(() => {
        updateState(ids);
        setDeleting(false);
        setShowDeleteModal(false);
        setSelect([]);
        toast.success("Selected listings have been removed");
      })
      .catch((error) => {
        setDeleting(false);
        setShowDeleteModal(false);
        toast.error(getErrorMessage(error));
      });
  };

  const handleClose = () => {
    setShowDeleteModal(false);
    setSelect([]);
  };

  return (
    <>
      {/* <Div
        p={{ b: "3rem" }}
        d="flex"
        flexDir={{ xs: "column", md: "row" }}
        align={{ md: "center" }}
        justify="space-between"
      >
        <Text
          textSize="display2"
          m={{ b: { xs: "1rem", md: "0" } }}
          textWeight="600"
        >
          All Listings
        </Text>
        <Button
          bg="brand700"
          w="9rem"
          h="3rem"
          rounded="lg"
          textSize="paragraph"
          textWeight="600"
          p="0.75rem 1.25rem 0.75rem 1rem"
          prefix={
            <Icon
              name="Plus"
              size="1.25rem"
              m={{ r: "0.75rem" }}
              color="white"
            />
          }
          //   onClick={() => createListing()}
        >
          New listing
        </Button>
      </Div> */}
      <Div
        border={{ t: "1px solid" }}
        borderColor="o-light"
        p="0.5rem 0"
        textSize="caption"
        textColor="light"
        textWeight="500"
      >
        <Row>
          <Col size={{ md: "6", lg: "8" }}>
            <Div
              h="1.5rem"
              d="flex"
              align="center"
              onClick={selectAll}
              cursor="pointer"
            >
              <Checkbox
                checked={select.length === listings.length}
                undetermine={
                  select.length !== listings.length && select.length !== 0
                }
                activeColor="brand600"
                m={{ r: { xs: "1rem", sm: "1.875rem" } }}
              />
              {select.length === 0 ? (
                <Text textSize="caption" textColor="light" textWeight="500">
                  {totalListings} listings
                </Text>
              ) : (
                <Text textSize="caption" textColor="light" textWeight="500">
                  {select.length === listings.length ? "All" : select.length}{" "}
                  selected
                </Text>
              )}
              {/* <Text textSize="caption" textColor="light" textWeight="500">
                {totalListings} Listings
              </Text> */}
              {select.length > 0 && (
                <Div
                  d="flex"
                  align="center"
                  m={{ l: "1.5rem" }}
                  border={{ l: "1px solid" }}
                  borderColor="o-medium"
                  h="1.5rem"
                  flexGrow="1"
                  p={{ l: "1rem" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteModal(true);
                  }}
                >
                  <Div
                    p="0"
                    h="auto"
                    bg="white"
                    textColor="danger600"
                    textWeight="600"
                    textSize="caption"
                  >
                    <Button
                      p="0"
                      h="auto"
                      bg="white"
                      textColor="danger600"
                      textWeight="600"
                      textSize="caption"
                    >
                      <Icon
                        name="DeleteSolid"
                        color="danger600"
                        size="16px"
                        m={{ r: "0.25rem" }}
                      />
                      Delete
                    </Button>
                  </Div>
                </Div>
              )}
            </Div>
          </Col>
          <Col d={{ xs: "none", md: "block" }} size="3">
            Monthly payment
          </Col>
          <Col size={{ md: "4", lg: "2" }} d={{ xs: "none", md: "block" }}>
            <Div m={{ l: "1rem" }}> Listing type</Div>
          </Col>
          <Col size="3"></Col>
        </Row>
      </Div>
      <Div>
        {listings?.map((listItem, index) => {
          return (
            <ListingListItem
              key={listItem.id}
              listItem={listItem}
              index={index}
              select={select}
              addToSelected={addToSelected}
            />
          );
        })}
      </Div>
      <ListingDeleteModal
        isOpen={showDeleteModal}
        close={handleClose}
        onOkay={removeListings}
        loading={deleting}
        count={select.length}
      />
    </>
  );
}

// export default function ListingList({ listings, createListing, updateState }) {
//   const [select, setSelect] = React.useState([]);
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [deleting, setDeleting] = useState(false);

//   const removeListings = () => {
//     setDeleting(true);
//     const ids = [];
//     select.forEach((element) => {
//       ids.push(element.id);
//     });

//     const db = firestore();
//     const listingMessages = db
//       .collection("conversations")
//       .where("listingId", "in", ids);
//     listingMessages.get().then(function (querySnapshot) {
//       querySnapshot.forEach(function (doc) {
//         doc.ref.delete();
//       });
//     });

//     axiosInstance
//       .post("listings/bulk-delete", { ids })
//       .then(() => {
//         updateState(ids);
//         setDeleting(false);
//         setShowDeleteModal(false);
//         setSelect([]);
//         toast.success("Your listings have been removed");
//       })
//       .catch((error) => {
//         setDeleting(false);
//         setShowDeleteModal(false);
//         toast.error(getErrorMessage(error));
//       });
//   };

//   const handleClose = () => {
//     setShowDeleteModal(false);
//     setSelect([]);
//   };

// }
