import React from "react";
import { Text, Textarea } from "atomize";

export default function DefaultTextarea({ label, ...rest }) {
  return (
    <>
      {label && (
        <Text
          textSize="caption"
          textColor="text-light"
          textWeight="500"
          m={{ b: "0.25rem" }}
        >
          {label}
        </Text>
      )}
      <Textarea
        bg="gray200"
        border="1px solid"
        borderColor="o-light"
        focusBorderColor="o-base"
        fontFamily="primary"
        placeholderTextColor="disabled"
        textSize="paragraph"
        textWeight="500"
        rounded="md"
        h="7rem"
        minH="7rem"
        p="0.75rem 1rem"
        textColor="text-medium"
        transition="borderColor 0.4s ease-in-out"
        {...rest}
      />
    </>
  );
}
