import React from "react";
import { Div, Icon, Text, Button } from "atomize";
import Modal from "../atoms/Modal";
import PasswordInput from "../atoms/PasswordInput";
import {
  isValidStr,
  getValidationMessage,
  getErrorMessage,
} from "../../utilities/helpers";
import { axiosInstance } from "../../utilities/axiosInstance";
import { isPasswordStrong } from "../../utilities/helpers";
import FormGlobalMessage from "../atoms/FormGlobalMessage";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      formSubmitting: false,
      globalError: null,
      errors: {},
      globalSuccess: null,
    };
    this.changePassword = this.changePassword.bind(this);
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  changePassword = (e) => {
    e.preventDefault();
    const { newPassword, oldPassword } = this.state;

    const { error, msg } = isPasswordStrong(newPassword);

    if (error) {
      return this.setState({
        globalError: msg,
      });
    }

    this.setState({
      formSubmitting: true,
      globalSuccess: null,
      globalError: null,
      errors: {},
    });
    axiosInstance
      .post("users/update-password", { newPassword, oldPassword })
      .then((response) => {
        this.setState({
          formSubmitting: false,
          globalSuccess: "Password updated",
          oldPassword: "",
          newPassword: "",
        });
      })
      .catch((error) => {
        this.setState({ formSubmitting: false });
        if (error.response && error.response.status === 422) {
          this.setState({
            formSubmitting: false,
            errors: {
              oldPassword: getValidationMessage(error, "oldPassword"),
              newPassword: getValidationMessage(error, "newPassword"),
            },
          });
        } else {
          this.setState({
            globalError: getErrorMessage(error),
          });
        }
      });
  };

  render() {
    const { close, isOpen } = this.props;
    const {
      newPassword,
      oldPassword,
      formSubmitting,
      errors,
      globalError,
      globalSuccess,
    } = this.state;
    return (
      <Modal
        maxW="35rem"
        isOpen={isOpen}
        // close={close}
        align="flex-start"
        m={{ t: { lg: "5rem" } }}
      >
        <Div
          d="flex"
          justify="space-between"
          border={{ b: "1px solid" }}
          borderColor="o-light"
          p="1.5rem"
        >
          <Text
            textSize="heading"
            textColor="black900"
            textWeight="600"
            p={{ l: "1.5rem" }}
          >
            Change Password
          </Text>
          <Button
            onClick={close}
            type="button"
            h="2.5rem"
            w="2.5rem"
            bg="gray200"
            border="1px solid"
            borderColor="gray300"
            rounded="circle"
            d="flex"
            align="center"
            justify="center"
          >
            <Icon name="Cross" color="black800" />
          </Button>
        </Div>
        <Div p={{ xs: "1.5rem 2rem 3rem", sm: "2.5rem 3rem 4rem" }}>
          <form onSubmit={this.changePassword}>
            {
              <FormGlobalMessage
                error={globalError}
                success={globalSuccess}
                m={{ b: "1.5rem" }}
              />
            }
            <PasswordInput
              label="Old password"
              m={{ b: "1.5rem" }}
              placeHolder="Enter your old password"
              name="oldPassword"
              value={oldPassword}
              onChange={this.handleChange}
              error={isValidStr(errors.oldPassword) && errors.oldPassword}
              required
            />
            <Div
              d="flex"
              justify="space-between"
              textColor="light"
              textWeight="500"
              m={{ b: "0.25rem" }}
            >
              <Text textSize="caption">New password</Text>
            </Div>
            <PasswordInput
              m={{ b: "2.5rem" }}
              placeHolder="Enter your new password"
              name="newPassword"
              value={newPassword}
              onChange={this.handleChange}
              error={isValidStr(errors.newPassword) && errors.newPassword}
              required
            />

            <Div d="flex" flexDir={{ xs: "column", sm: "row" }} align="center">
              <Button
                type="submit"
                bg="brand700"
                border="1px solid"
                borderColor="brand700"
                // maxW={{ sm: "13rem" }}
                // flexGrow={{ sm: "1" }}
                h="3rem"
                shadow="1"
                // w={{ xs: "100%", sm: "auto" }}
                w="100%"
                textWeight="600"
                m={{ xs: "0 0 0.5rem 0", sm: "0 1rem 0 0" }}
                disabled={formSubmitting}
              >
                Change Password
              </Button>
              <Button
                type="button"
                bg="white"
                textColor="text-medium"
                border="1px solid"
                borderColor="o-medium"
                // maxW={{ sm: "9rem" }}
                // flexGrow={{ sm: "1" }}
                h="3rem"
                shadow="1"
                // w={{ xs: "100%", sm: "auto" }}
                w="100%"
                textWeight="600"
                m={{ xs: "0 0 0.5rem 0", sm: "0" }}
                onClick={close}
              >
                Cancel
              </Button>
            </Div>
          </form>
        </Div>
      </Modal>
    );
  }
}

export default ChangePassword;
