import React from "react";
import { Button, Image } from "atomize";

const SecondaryButton = ({
  children,
  image,
  alignImage,
  isdisabled,
  style,
  ...rest
}) => (
  <Button
    m={{ t: "1.75rem" }}
    d="flex"
    bg="brand200"
    h="40px"
    textSize="paragraph"
    textWeight="600"
    textColor="brand700"
    shadow="1"
    hoverShadow="0"
    transition
    disabled={isdisabled}
    style={{ whiteSpace: "nowrap", ...style }}
    prefix={
      <Image
        src={image}
        w="15px"
        pos="absolute"
        style={{ opacity: isdisabled ? "0.2" : 1 }}
        right={alignImage === "right" ? "18.5px" : "initial"}
        left={alignImage === "left" ? "18.5px" : "initial"}
      />
    }
    {...rest}
  >
    {children}
  </Button>
);

export default SecondaryButton;
