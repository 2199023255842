import React, { useState } from "react";
import { Div, Image, Icon } from "atomize";

const MultiImageThumbnail = ({ imageSrc, onClick, id }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      pos="relative"
      // m={{ r: "1rem", b: "1rem" }}
      w="4.5rem"
      h="3rem"
      d="flex"
      align="center"
      justify="center"
      rounded="md"
      overflow="hidden"
      shadow="1"
      onClick={(e) => {
        e.stopPropagation();
        onClick(id);
      }}
    >
      <Image src={imageSrc} d="block"></Image>
      {isHovered && (
        <>
          <Div
            pos="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="black"
            opacity="0.72"
          ></Div>
          <Icon
            name="Delete"
            pos="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            size="20px"
            color="white"
          />
        </>
      )}
    </Div>
  );
};

export default MultiImageThumbnail;
