import React from "react";
import { Text, Input, Div, Icon } from "atomize";

export default function PasswordInput({
  label,
  error,
  warning,
  success,
  m,
  handleChange,
  showEyeIcon = true,
  ...rest
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Div m={m}>
      {label && (
        <Text
          className="truncate-text"
          textSize="caption"
          textColor="light"
          textWeight="500"
          m={{ b: "0.25rem" }}
        >
          {label}
        </Text>
      )}
      <Div pos="relative">
        <Input
          bg="gray200"
          border="1px solid"
          borderColor="o-light"
          type={showPassword ? "text" : "password"}
          focusBorderColor="o-base"
          shadow="inner1"
          placeholderTextColor="disabled"
          textSize="paragraph"
          textWeight="500"
          rounded="md"
          p="0.75rem 1rem"
          textColor="text-medium"
          h="3rem"
          onChange={handleChange}
          {...rest}
        ></Input>
        {showEyeIcon && (
          <Div
            d="flex"
            pos="absolute"
            top="50%"
            right="0"
            p="0.75rem"
            type="button"
            rounded="sm"
            bg="transparent"
            transform="translateY(-50%)"
            cursor="pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon
              name="EyeSolid"
              color={showPassword ? "brand700" : "disabled"}
              size="20px"
            />
          </Div>
        )}
      </Div>
      {error && (
        <Div
          bg="danger200"
          m={{ t: "0.5rem" }}
          rounded="md"
          d="flex"
          align="center"
          p="0.75rem 0.75rem 0.75rem 1rem"
        >
          <Icon
            name="AlertSolid"
            color="danger700"
            size="20px"
            m={{ r: "0.75rem" }}
          />
          <Text textWeight="500">{error}</Text>
        </Div>
      )}
      {success && (
        <Div
          bg="success200"
          m={{ t: "0.5rem" }}
          rounded="md"
          d="flex"
          align="center"
          p="0.75rem 0.75rem 0.75rem 1rem"
          {...rest}
        >
          <Icon
            name="Success"
            color="success700"
            size="20px"
            m={{ r: "0.75rem" }}
          />
          <Text textWeight="500" textColor="medium">
            {success}
          </Text>
        </Div>
      )}
      {warning && (
        <Div
          bg="warning200"
          m={{ t: "0.5rem" }}
          rounded="md"
          d="flex"
          p="0.75rem 0.75rem 0.75rem 1rem"
          {...rest}
        >
          <Icon
            name="AlertSolid"
            color="warning800"
            size="20px"
            m={{ r: "0.75rem" }}
          />
          <Text textWeight="500">{warning}</Text>
        </Div>
      )}
    </Div>
  );
}
