import React, { useCallback, useContext } from "react";
import { Div, Text, Button } from "atomize";
import { useDropzone } from "react-dropzone";
import { AuthContext } from "../../contexts/auth";

export default function ProfilePicEditor({
  profilePic,
  setProfilePic,
  // user,
  removeProfilePic,
}) {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setProfilePic(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [profilePic]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    maxSize: 2000000,
    multiple: false,
  });

  const { user } = useContext(AuthContext);

  return (
    <Div m={{ b: "3.5rem" }} d="flex" align="flex-end">
      {profilePic?.length > 0 ? (
        profilePic?.map((file) => (
          <Div
            w="4.5rem"
            h="4.5rem"
            shadow="2"
            rounded="circle"
            bgImg={file.preview}
            bgSize="cover"
            bgPos="center"
            m={{ r: "2rem" }}
          />
        ))
      ) : (
        <Div
          w="4.5rem"
          h="4.5rem"
          shadow="2"
          rounded="circle"
          cla
          bgImg={user.avatarUrl || "/images/users/default-img.svg"}
          bgSize="cover"
          bgPos="center"
          m={{ r: "2rem" }}
        />
      )}
      <Div d="flex" flexDir="column" align="flex-start">
        <Div m={{ b: "0.25rem" }} d="flex" align="center">
          <Div
            d="flex"
            align="center"
            justify="center"
            rounded="lg"
            bg="brand200"
            minW="6.5rem"
            h="2.5rem"
            cursor="pointer"
            textWeight="600"
            textColor="brand700"
            {...getRootProps()}
            m={{ r: "0.5rem" }}
          >
            <input {...getInputProps()}></input>
            Update pic
          </Div>
          {user.avatarUrl && (
            <Button
              textWeight="600"
              textColor="danger600"
              minW="5rem"
              bg="white"
              cursor="pointer"
              type="button"
              onClick={() => removeProfilePic()}
            >
              Remove
            </Button>
          )}
        </Div>
        <Text
          textWeight="500"
          textColor="text-light"
          textSize="caption"
          maxW="6.5rem"
          textAlign="Center"
          transform="translateX(2px)"
        >
          Max size: 2MB
        </Text>
      </Div>
    </Div>
  );
}
