import React, { forwardRef } from "react";
import { Text, Input, Div, Icon } from "atomize";

const DefaultInput = forwardRef(
  (
    { label, error, warning, success, m, handleChange, onPressEnter, ...rest },
    ref
  ) => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();

        onPressEnter && onPressEnter();
      }
    };

    return (
      <Div m={m}>
        {label && (
          <Text
            className="truncate-text"
            textSize="caption"
            textColor="text-light"
            textWeight="500"
            m={{ b: "0.25rem" }}
          >
            {label}
          </Text>
        )}
        <Input
          bg="gray200"
          border="1px solid"
          borderColor="o-light"
          focusBorderColor="o-base"
          shadow="inner1"
          placeholderTextColor="disabled"
          textSize="paragraph"
          textWeight="500"
          rounded="md"
          p="0.75rem 1rem"
          textColor="text-medium"
          h="3rem"
          transition="background,box-shadow,borderColor 0.4s ease-in-out"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          ref={ref}
          {...rest}
        ></Input>
        {error && (
          <Div
            bg="danger200"
            m={{ t: "0.5rem" }}
            rounded="md"
            d="flex"
            align="center"
            p="0.75rem 0.75rem 0.75rem 1rem"
          >
            <Icon
              name="AlertSolid"
              color="danger700"
              size="20px"
              m={{ r: "0.75rem" }}
            />
            <Text textWeight="500">{error}</Text>
          </Div>
        )}
        {success && (
          <Div
            bg="success200"
            m={{ t: "0.5rem" }}
            rounded="md"
            d="flex"
            align="center"
            p="0.75rem 0.75rem 0.75rem 1rem"
            {...rest}
          >
            <Icon
              name="Success"
              color="success700"
              size="20px"
              m={{ r: "0.75rem" }}
            />
            <Text textWeight="500" textColor="medium">
              {success}
            </Text>
          </Div>
        )}
        {warning && (
          <Div
            bg="warning200"
            m={{ t: "0.5rem" }}
            rounded="md"
            d="flex"
            p="0.75rem 0.75rem 0.75rem 1rem"
            {...rest}
          >
            <Icon
              name="AlertSolid"
              color="warning800"
              size="20px"
              m={{ r: "0.75rem" }}
            />
            <Text textWeight="500">{warning}</Text>
          </Div>
        )}
      </Div>
    );
  }
);

export default DefaultInput;
