import React, { useState, useEffect } from "react";
import { Div, Text, Button, Icon } from "atomize";
import { Transition } from "react-transition-group";

const duration = 400;

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  opacity: 1,
};

const transitionStyles = {
  entering: { opacity: 0, transform: "translateY(100%)" },
  entered: { opacity: 1, transform: "translateY(0%)" },
  exiting: { opacity: 0, transform: "translateY(100%)" },
  exited: { opacity: 0, transform: "translateY(100%)" },
};

export default function ArchiveBar({
  listing,
  user,
  setShowArchiveModal,
  unarchiveListing,
  archieving,
}) {
  const [isOpen, setIsOpen] = useState(true);

  const handleScroll = () => {
    if (window.scrollY > 200 && isOpen) {
      setIsOpen(false);
    } else if (window.scrollY < 200 && !isOpen) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  return (
    <Transition in={isOpen} timeout={duration} unmountOnExit>
      {(state) => (
        <Div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          shadow="up1"
          zIndex="99"
          pos="fixed"
          bottom="0"
          left="0"
          right="0"
          bg="white"
          d="flex"
          align="center"
          justify="center"
          flexWrap="wrap"
          p="1rem"
          border={{ t: "1px solid" }}
          borderColor="o-light"
        >
          {listing.data.status !== "ARCHIVED" ? (
            <>
              <Text textSize="subheader" textColor="medium" textWeight="500">
                You can
              </Text>
              <Button
                onClick={() => setShowArchiveModal(true)}
                m="0 1rem"
                p="0 1rem 0 0.75rem"
                align="center"
                bg="white"
                hoverBg="danger100"
                border="1px solid"
                borderColor="danger300"
                textColor="danger600"
                shadow="1"
                rounded="md"
                disabled={user.role !== "ADMIN" && user.role !== "EDITOR"}
              >
                <Icon
                  m={{ r: "0.5rem" }}
                  name="Delete"
                  size="20px"
                  color="danger600"
                />
                <Text textWeight="600">Archive</Text>
              </Button>
              <Text textSize="subheader" textColor="medium" textWeight="500">
                this from listing to hide this from Swopr. You can unarchive it
                later.
              </Text>
            </>
          ) : (
            <>
              <Text textSize="subheader" textColor="medium" textWeight="500">
                This listing is archived and hidden from Swopr.
              </Text>
              <Button
                onClick={unarchiveListing}
                disabled={archieving}
                m="0 1rem"
                p="0 1rem"
                align="center"
                bg="white"
                hoverBg="gray100"
                border="1px solid"
                borderColor="o-dark"
                textColor="medium"
                shadow="1"
                rounded="md"
                disabled={user.role !== "ADMIN" && user.role !== "EDITOR"}
              >
                <Text textWeight="600">Unarchive</Text>
              </Button>
              <Text textSize="subheader" textColor="medium" textWeight="500">
                to show it on Swopr.
              </Text>
            </>
          )}
        </Div>
      )}
    </Transition>
  );
}
