import React from "react";
import { Div, Text, Row, Col } from "atomize";

import CustomContainer from "../atoms/CustomContainer";
import PricingDetails from "./PricingDetails";

export default function ProductSidebar({
  listing,
  open,
  ownerEmail,
  openCallModal,
}) {
  return (
    <Div
      d={{ xs: "none", md: "block" }}
      pos="fixed"
      left="0"
      right="0"
      top="6rem"
    >
      <CustomContainer>
        <Row>
          <Col size={{ md: "6", xl: "5" }} offset={{ md: "10", xl: "11" }}>
            <Div pos="relative">
              <Div pos="absolute" top="0" right="0" left="0" bottom="0">
                <Div p={{ l: { lg: "2rem", xl: "3rem" } }}>
                  <Text
                    textSize="paragraph"
                    textWeight="500"
                    textColor="text-medium"
                  >
                    Lease for:
                  </Text>
                  <PricingDetails
                    listing={listing}
                    open={open}
                    ownerEmail={ownerEmail}
                    openCallModal={openCallModal}
                  />
                </Div>
              </Div>
            </Div>
          </Col>
        </Row>
      </CustomContainer>
    </Div>
  );
}
