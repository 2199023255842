import React, { useEffect, useContext } from "react";
import { Text, Div, Button } from "atomize";
import DesktopFilterDropdownWrapper from "./DesktopFilterDropdownWrapper";
import Location from "../filters/Location";
import Email from "../filters/Email";
import Plan from "../filters/Plan";
import { FiltersContext } from "../../contexts/filtersProvider";

export default function DesktopFilters({
  isLoading,
  //   setShowMoreFilter,
  appliedFilters,
  setFilters,
  getFilteredCars,
  locations,
  availableFilters,
  emails,
}) {
  const dropdownWrapperRef = React.useRef(null);
  const [selectedFilter, setSelectedFilter] = React.useState(null);
  const { resetFilters } = useContext(FiltersContext);

  useEffect(() => {
    return () => {
      window.removeEventListener("click", checkClickOutside);
      window.removeEventListener("keydown", checkOutsideFocus);
    };
  }, []);

  const onApplying = (filters) => {
    setSelectedFilter(null);
    getFilteredCars(filters);
  };

  const checkClickOutside = (e) => {
    if (!dropdownWrapperRef?.current?.contains(e.target)) {
      changeDropdown(null);
    }
  };

  const checkOutsideFocus = (e) => {
    if (e.keyCode === 9) {
      if (!dropdownWrapperRef.current.contains(document.activeElement)) {
        changeDropdown(null);
      }
    }
  };

  const changeDropdown = (value) => {
    setSelectedFilter(value);

    if (!selectedFilter && value) {
      window.addEventListener("click", checkClickOutside);
      window.addEventListener("keydown", checkOutsideFocus);
    } else if (value === null) {
      window.removeEventListener("click", checkClickOutside);
      window.removeEventListener("keydown", checkOutsideFocus);
    }
  };

  return (
    <>
      <Div d="inline-block">
        <Div
          ref={dropdownWrapperRef}
          d="flex"
          flexWrap="wrap"
          w="100%"
          align="center"
        >
          {/* <DesktopFilterDropdownWrapper
        isLoading={isLoading}
        selectedFilter={selectedFilter}
        setSelectedFilter={changeDropdown}
        label="Lease Payment"
        appliedFilters={appliedFilters}
        onApplying={onApplying}
      >
        <Div p="1.5rem">
          <Text textWeight="500" textColor="light" m={{ b: "1rem" }}>
            Select a range for monthly lease.
          </Text>
          <LeasePayment
            min={{
              name: "minLeasePayment",
              value: appliedFilters.minLeasePayment,
            }}
            max={{
              name: "maxLeasePayment",
              value: appliedFilters.maxLeasePayment,
            }}
            limit={[
              availableFilters.minLeasePayment,
              availableFilters.maxLeasePayment,
            ]}
            setFilters={setFilters}
          />
        </Div>
      </DesktopFilterDropdownWrapper>

     
      <DesktopFilterDropdownWrapper
        isLoading={isLoading}
        selectedFilter={selectedFilter}
        setSelectedFilter={changeDropdown}
        label="Months Remaining"
        appliedFilters={appliedFilters}
        onApplying={onApplying}
      >
        <Div p="1.5rem">
          <Text textWeight="500" textColor="light" m={{ b: "1rem" }}>
            Select a range for monthly lease.
          </Text>
          <MonthsRemaining
            min={{
              name: "minMonthsRemaining",
              value: appliedFilters.minMonthsRemaining,
            }}
            max={{
              name: "maxMonthsRemaining",
              value: appliedFilters.maxMonthsRemaining,
            }}
            setFilters={setFilters}
            limit={[
              availableFilters.minMonthsRemaining,
              availableFilters.maxMonthsRemaining,
            ]}
          />
        </Div>
      </DesktopFilterDropdownWrapper> */}

          {/* Location Filter */}
          <DesktopFilterDropdownWrapper
            isLoading={isLoading}
            selectedFilter={selectedFilter}
            onApplying={onApplying}
            appliedFilters={appliedFilters}
            setSelectedFilter={changeDropdown}
            label="Location"
            minW="328px"
          >
            <Location
              locations={locations}
              appliedFilters={appliedFilters}
              setFilters={setFilters}
            />
          </DesktopFilterDropdownWrapper>

          {/* <DesktopFilterDropdownWrapper
        isLoading={isLoading}
        // m="0 0.125rem 0.5rem 0"
        selectedFilter={selectedFilter}
        onApplying={onApplying}
        appliedFilters={appliedFilters}
        setSelectedFilter={changeDropdown}
        label="Year"
        minW="328px"
      >
        <Div p="1.5rem">
          <Text textWeight="500" textColor="light" m={{ b: "1rem" }}>
            Select a range for manufacturing year.
          </Text>
          <Year
            min={{
              name: "minYear",
              value: appliedFilters.minYear,
            }}
            max={{
              name: "maxYear",
              value: appliedFilters.maxYear,
            }}
            setFilters={setFilters}
            limit={[availableFilters.minYear, availableFilters.maxYear]}
          />
        </Div>
      </DesktopFilterDropdownWrapper> */}

          <DesktopFilterDropdownWrapper
            isLoading={isLoading}
            selectedFilter={selectedFilter}
            onApplying={onApplying}
            appliedFilters={appliedFilters}
            setSelectedFilter={changeDropdown}
            label="Plan"
            minW="328px"
          >
            <Plan
              plans={["Basic", "Advanced"]}
              appliedFilters={appliedFilters}
              setFilters={setFilters}
            />
          </DesktopFilterDropdownWrapper>

          <DesktopFilterDropdownWrapper
            isLoading={isLoading}
            selectedFilter={selectedFilter}
            onApplying={onApplying}
            appliedFilters={appliedFilters}
            setSelectedFilter={changeDropdown}
            label="Email"
            minW="328px"
            m="0 0.125rem 0rem 0"
          >
            <Email
              list={emails}
              appliedFilters={appliedFilters}
              setFilters={setFilters}
            />
          </DesktopFilterDropdownWrapper>
        </Div>
      </Div>
      <Button
        onClick={() => {
          resetFilters();
          getFilteredCars({});
        }}
        d="inline-block"
        textAlign="center"
        bg="white"
        flexGrow={{ lg: "1" }}
        textWeight="500"
        textColor="brand800"
        m={{ l: "12px" }}
        p={{ xs: "0 1rem", md: "0 0.65rem", lg: "0 0.5rem" }}
      >
        Reset filters
      </Button>
    </>
  );
}
