import React from "react";
import { useDropzone } from "react-dropzone";
import { Div, Text } from "atomize";

import { axiosInstance } from "../../utilities/axiosInstance";
import MultiImageThumbnail from "./MultiImageThumbnail";

const MultiImageDropzone = ({
  listingImages,
  images,
  setImages,
  listing,
  updateListing,
  setListing,
  areImagesUploading,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newfile = [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ];
      const currentImages = images.map(({ file }) => file);
      let newFiles = currentImages.concat(newfile);
      setImages(newFiles);
    },
    accept: "image/jpeg, image/jpg,image/png",
    maxSize: 2000000,
    multiple: true,
  });

  const handleImageDelete = (imageName) => {
    //delete from state only
    const remainingFiles = images
      .filter(({ file }) => file.name !== imageName)
      .map(({ file }) => file);
    setImages(remainingFiles);
  };

  const handlelistingImage = async (imageId) => {
    // delete from db than update state
    await axiosInstance.post(`/listings/delete/image`, {
      imageId,
      listingId: listing.id,
    });
    const newListing = {
      data: {
        ...listing,
        images: listing.images.filter((img) => img.id !== imageId),
      },
    };
    setListing(newListing);
  };

  return (
    <>
      {listingImages?.length > 0 || images?.length > 0 ? (
        <Div
          className="custom-border"
          m={{ t: "4px" }}
          p="1rem"
          rounded="lg"
          d="flex"
          align="flex-start"
          flexDir="Column"
          cursor="pointer"
          pos="relative"
          style={{
            pointerEvents: areImagesUploading ? "none" : "auto",
            userSelect: "none",
          }}
          {...getRootProps()}
        >
          {areImagesUploading && (
            <Div
              pos="absolute"
              w="98%"
              h="98%"
              left="50%"
              top="50%"
              transform="translate(-50%,-50%)"
              d="flex"
              justify="center"
              align="center"
              bg="black"
              opacity="0.72"
              zIndex="10"
              rounded="xl"
              cursor="auto"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <Text
                textColor="white"
                textWeight="500"
                textSize="subheader"
                textAlign="center"
              >
                Please wait, image(s) are uploading...
              </Text>
            </Div>
          )}
          <input {...getInputProps()} />
          {isDragActive ? (
            <Text
              m="auto"
              textAlign="center"
              textWeight="600"
              textSize="paragraph"
              textColor="text-medium"
            >
              Drop your images here
            </Text>
          ) : (
            <>
              <Div
                // d="flex" flexDir="row" flexWrap="wrap"
                className="multi-image-dropzone-container"
                style={{
                  gridTemplateColumns:
                    listingImages.length + images.length > 6
                      ? "repeat(auto-fit, minmax(5rem,1fr))"
                      : "repeat(auto-fit, 5rem)",
                }}
              >
                {listingImages?.map((file) => (
                  <MultiImageThumbnail
                    imageSrc={file.xsmallImageUrl}
                    id={file.id}
                    onClick={handlelistingImage}
                  />
                ))}
                {images.map(({ file }) => (
                  <MultiImageThumbnail
                    imageSrc={file.preview}
                    onClick={handleImageDelete}
                    id={file.name}
                  />
                ))}
              </Div>
              <Text
                m={{ t: "1rem" }}
                style={{ whiteSpace: "nowrap" }}
                textWeight="500"
                textSize="paragraph"
                textColor="text-light"
              >
                Drag images here or{" "}
                <Text tag="span" textColor="brand600">
                  upload images
                </Text>
              </Text>
            </>
          )}
        </Div>
      ) : (
        <Div
          className="custom-border"
          m={{ t: "4px" }}
          p="2.5rem"
          rounded="xl"
          d="flex"
          align="center"
          justify="center"
          flexDir="Column"
          cursor="pointer"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Text
              textAlign="center"
              textWeight="600"
              textSize="paragraph"
              textColor="text-medium"
            >
              Drop your images here
            </Text>
          ) : (
            <>
              <Text
                textWeight="600"
                textSize="paragraph"
                textColor="text-medium"
              >
                Drag image here or{" "}
                <Text tag="span" textColor="brand600">
                  upload images
                </Text>
              </Text>
              <Text
                m={{ t: "4px" }}
                style={{ whiteSpace: "nowrap" }}
                textWeight="500"
                textSize="tiny"
                textColor="text-light"
              >
                Maximum file size - 2 MB
              </Text>
              <Text
                m={{ t: "2px" }}
                style={{ whiteSpace: "nowrap" }}
                textWeight="500"
                textSize="tiny"
                textColor="text-light"
              >
                Supported files - (png, jpg, jpeg)
              </Text>
            </>
          )}
        </Div>
      )}
    </>
  );
};

export default MultiImageDropzone;
