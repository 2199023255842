import React from "react";
import { Div } from "atomize";
// import { useRouter } from "next/router";

import Header from "./Header";

export default function Root({ children }) {
  //   const router = useRouter();
  //   const { token, action } = router.query;
  return (
    <Div textColor="dark" textSize="paragraph">
      <Header
      //   action={action} token={token}
      />
      {children}
    </Div>
  );
}
