import React from "react";
import { Div, Row, Col, Button, Icon } from "atomize";

import CustomContainer from "../atoms/CustomContainer";

export default function ListingFooter({
  back,
  next,
  submitLabel = "Next",
  nextReplacer,
  currentStep,
  isEditing,
}) {
  return (
    <Div
      pos="fixed"
      bottom="0"
      right="0"
      left="0"
      p={{ y: "1rem" }}
      border={{ t: "1px solid" }}
      borderColor="o-light"
      bg="white"
      zIndex="10"
      className="listing-footer"
    >
      <CustomContainer>
        <Row>
          <Col
            size={{
              xs: "16",
              sm: "14",
              md: "10",
              lg: "10",
              xl: "8",
            }}
            offset={{ sm: "1", md: "0", lg: "0", xl: "4" }}
          >
            <Div
              p={{ xl: "0 2.5rem" }}
              d="flex"
              align="center"
              justify="space-between"
            >
              <Div>
                {back && (
                  <Button
                    type="button"
                    bg="white"
                    textColor="medium"
                    border="1px solid"
                    borderColor="o-medium"
                    shadow="1"
                    minW="6rem"
                    h="3rem"
                    p="0"
                    textWeight="600"
                    onClick={back}
                  >
                    <Icon
                      name="LeftArrow"
                      size="20px"
                      color="medium"
                      m={{ r: "0.5rem" }}
                    />
                    Back
                  </Button>
                )}
              </Div>
              {nextReplacer && nextReplacer}
              {!nextReplacer && currentStep !== 5 && (
                <Button
                  type="submit"
                  bg="brand700"
                  minW="7rem"
                  h="3rem"
                  p="0 1.5rem"
                  textWeight="600"
                  className="disabled-color"
                  onClick={next}
                  disabled={isEditing}
                >
                  {isEditing && currentStep !== 3 ? (
                    <Icon name="Loading2" color="white" />
                  ) : (
                    submitLabel
                  )}
                </Button>
              )}
            </Div>
          </Col>
        </Row>
      </CustomContainer>
    </Div>
  );
}
