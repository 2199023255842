import React from "react";
import { Div, Icon, Text, Tag } from "atomize";

export default function ProductDescription({ listing }) {
  return (
    <Div m={{ t: "3rem" }} p={{ b: "2.5rem" }} maxW={{ md: "90%", lg: "80%" }}>
      <Text textSize="title" textWeight="600">
        {listing.make
          ? `${listing.make}${listing.model ? ` ${listing.model}` : ""}${
              listing.manufacturingYear ? ` ${listing.manufacturingYear}` : ""
            }`
          : "-- Untitled listing --"}
      </Text>
      <Tag
        minW="6.875rem"
        h="1.5rem"
        bg="o-light"
        m={{ t: "0.75rem" }}
        textColor="text-medium"
        textTransform="uppercase"
        textSize="overline"
        rounded="md"
        style={{ letterSpacing: "0.1em" }}
        prefix={
          <Icon
            name="Location"
            size="10px"
            m={{ r: "5.5px" }}
            color="text-medium"
          />
        }
      >
        {listing.state}
      </Tag>
      <Text textSize="subheader" m={{ t: "2rem" }} textColor="text-medium">
        {listing.description}
      </Text>
    </Div>
  );
}
