import axios from "axios";
import { get } from "lodash";

const configureAxios = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 8000,
  });

export const axiosInstance = configureAxios();

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const appState = localStorage.getItem("appState");
      if (appState) {
        const token = get(JSON.parse(appState), "token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);
