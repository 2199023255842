import React, { useContext, useState } from "react";
import { Div, Text, Icon, Button, Image, Anchor } from "atomize";
import moment from "moment";
import { AuthContext } from "../../contexts/auth";

import SecondaryButton from "../atoms/SecondaryButton";
import InfoTooltip from "./InfoTooltip";
import { toast } from "react-toastify";

export default function PricingDetails({
  listing,
  open,
  ownerEmail,
  openCallModal,
}) {
  const { user } = useContext(AuthContext);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);

  const specifications = [
    {
      field: "VIN",
      value: listing.vin,
    },
    {
      field: "Months remaining on lease",
      value: listing.monthsRemaining,
    },
    {
      field: "Miles per month",
      value: listing.milesPerMonth,
    },
    {
      field: "Current miles",
      value: listing.currentMiles,
    },
    {
      field: "Remaining miles",
      value: listing.remainingMiles,
    },
    {
      field: "Leasing company",
      value: listing.leasingCompany,
    },
    {
      field: "Leasing end date",
      value: moment(listing.leaseEnd).format("DD MMMM YYYY"),
    },
  ];

  const handleRedirect = (type) => {
    type === "assistance"
      ? window.open(`mailto:hello@swopr.com`)
      : window.open(
          `mailto:${ownerEmail}?subject=${listing.make} ${listing.model} ${listing.manufacturingYear}`
        );
  };
  console.log(ownerEmail);
  const getIsDisabled = () => {
    if (Object.keys(user).length) {
      return user.email === ownerEmail;
    }

    return false;
  };

  const handleContactButtonClick = () => {
    if (!Object.keys(user).length) {
      return document.querySelector(".sign-in-button").click();
    }
    return open();
  };

  const copyTextToClipboard = async () => {
    let domain = 'swopr.com';
    if(process.env.REACT_APP_ENVIRONMENT === 'staging'){
      domain = 'swopr.vercel.app';
    }
    
    const url = `https://${domain}/cars/${listing.slug}`

    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(url);
    } else {
      return document.execCommand('copy', true, url);
    }
  }

  const handleCopy = () => {
    copyTextToClipboard()
    .then(() => {
     toast.success('Link Copied!')
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return (
    <>
      <Text m={{ t: "0.5rem" }} textSize="display2" textWeight="600">
        ${Math.ceil(listing.monthlyCost)}
        <Text
          textColor="text-light"
          textSize="title"
          textWeight="600"
          tag="span"
        >
          / month
        </Text>
      </Text>
      {listing.incentive && (
        <Div
          d="flex"
          justify="space-between"
          m={{ t: "0.5rem" }}
          p={{ b: "4.5px" }}
          border={{ b: "1px solid" }}
          borderColor="o-light"
        >
          <Text textSize="caption" textWeight="500" textColor="text-light">
            after applying{" "}
            <Text textColor="brand700" tag="span">
              ${listing.incentive}
            </Text>{" "}
            additional incentive.
          </Text>
          <Div pos="relative">
            <Icon
              onMouseEnter={() => setShowInfoTooltip(true)}
              onMouseLeave={() => setShowInfoTooltip(false)}
              name="InfoSolid"
              size="17px"
              color="brand700"
            />
            <InfoTooltip
              monthlyCost={listing.monthlyCost}
              monthlyPayment={listing.monthlyPayment}
              isOpen={showInfoTooltip}
            />
          </Div>
        </Div>
      )}
      {listing.incentive && (
        <Text
          textColor="text-medium"
          textSize="caption"
          textWeight="500"
          m={{ t: "7.5px" }}
        >
          Actual Payment: ${listing.monthlyPayment}
        </Text>
      )}
      <Button
        m={{ t: "1.75rem" }}
        justify="space-between"
        bg="brand700"
        w="100%"
        h="3rem"
        textSize="paragraph"
        textWeight="600"
        disabled
        onClick={handleContactButtonClick}
      >
        Contact Seller
        <Image src="/images/icons/send.svg" w="20px" />
      </Button>

      <Div d="flex">
        {listing.allowEmail && (
          <SecondaryButton
            m={{ r: listing.allowPhone ? "16px" : "0px", t: "16px" }}
            flexGrow={listing.allowPhone ? "0 1 50%" : "0 1 100%"}
            maxW={listing.allowPhone ? "50%" : "100%"}
            justify="space-between"
            onClick={() => handleRedirect("contact")}
            isdisabled={getIsDisabled()}
          >
            Email Seller
            <Image
              src="/images/icons/message-seller.svg"
              w="16px"
              style={{ opacity: getIsDisabled() ? 0.2 : 1 }}
            />
          </SecondaryButton>
        )}
        {listing.allowPhone && (
          <Anchor
            d="block"
            m={{ t: "16px" }}
            flexGrow={listing.allowEmail ? "0 1 50%" : "0 1 100%"}
            maxW={listing.allowEmail ? "50%" : "100%"}
            href={`tel:${listing.phone}`}
            onClick={(e) => {
              window.innerWidth >= 576 && e.preventDefault();
            }}
          >
            <SecondaryButton
              justify="space-between"
              m="0"
              w="100%"
              isdisabled={getIsDisabled()}
              onClick={() => {
                window.innerWidth >= 576 && openCallModal();
              }}
            >
              Call seller
              <Image
                src="/images/icons/call-seller.svg"
                w="16px"
                style={{ opacity: getIsDisabled() ? 0.2 : 1 }}
              />
            </SecondaryButton>
          </Anchor>
        )}
      </Div>

      <Div d="flex">
      <SecondaryButton
        m={{ r: "0px", t: "16px" }}
        flexGrow="1"
        maxW="100%"
        justify="space-between"
        onClick={handleCopy}
          >
          Share Link
          <Image
            src="/images/icons/link-icon.svg"
            w="16px"
          />
          </SecondaryButton>
     </Div>


      <Div m={{ t: "3rem", b: "rem" }}>
        {specifications.map((item, index, id) => {
          return item.value ? (
            <Div
              d="flex"
              justify="space-between"
              align="center"
              key={index}
              p={{
                t: "8.5px",
                b: "11.5px",
              }}
              border={{
                b: "1px solid",
                t: index === 0 ? "1px solid" : "0",
              }}
              borderColor="o-light"
            >
              <Text textColor="text-light" textWeight="500" textSize="caption">
                {item.field}
              </Text>
              <Text textWeight="500" textSize="caption">
                {item.value}
              </Text>
            </Div>
          ) : null;
        })}
      </Div>
      <Button
        m={{ t: "1.75rem" }}
        justify="space-between"
        bg="white"
        w="100%"
        h="3rem"
        textSize="paragraph"
        textWeight="600"
        textColor="text-medium"
        border="1px solid"
        borderColor="o-medium"
        shadow="1"
        hoverShadow="0"
        onClick={handleRedirect.bind(this, "assistance")}
        transition
      >
        Contact Swopr for assistance
        <Image src="/images/icons/email-solid-brand.svg" w="16px" />
      </Button>
    </>
  );
}
