import React, { useContext } from "react";
import { Div, Icon, Text, Button } from "atomize";
import { Transition } from "react-transition-group";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
// import {
//   listingIcon,
//   messageIcon,
//   accountIcon,
//   logoutIcon,
// } from "../common/atoms/IconsSvg";
// import { UnreadMsgsContext } from "../../../contexts/UnreadMessagesProvider";
// import StripeCheckoutButton from "../payment/StripeCheckoutButton";

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function ListingDropdown({ listItem, slug }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedListIndex, setSelectedListIndex] = React.useState(0);
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const focusKeyDown = (e) => {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }

    if (isOpen) {
      // Enter key
      if (e.keyCode === 13) {
        // go to link
        listItem[selectedListIndex].onClick();
      }

      // Up key
      else if (e.keyCode === 38) {
        let newValue = selectedListIndex - 1;

        if (newValue < 0) {
          newValue = listItem.length - 1;
        }

        setSelectedListIndex(newValue);
      }

      // Down key
      else if (e.keyCode === 40) {
        let newValue = selectedListIndex + 1;

        if (newValue > listItem.length - 1) {
          newValue = 0;
        }

        setSelectedListIndex(newValue);
      }
    } else {
      // Enter key
      if (e.keyCode === 13) {
        setIsOpen(true);
      }
    }
  };

  return (
    <Div pos="relative">
      <Button
        bg="white"
        shadow="1"
        hoverShadow="2"
        className="profile-dropdown"
        rounded="lg"
        border="1px solid"
        borderColor="o-medium"
        p="0"
        w="2.5rem"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        onKeyDown={focusKeyDown}
        onBlur={() => setIsOpen(false)}
      >
        <Icon name="OptionsVertical" size="20px" color="medium" />
      </Button>
      <Transition in={isOpen} timeout={duration} unmountOnExit>
        {(state) => (
          <Div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            minW="13rem"
            overflow="hidden"
            pos="absolute"
            right="0"
            top="100%"
            bg="white"
            rounded="lg"
            border="1px solid"
            borderColor="o-light"
            shadow="4"
          >
            <Div p="0.5rem" border={{ b: "1px solid" }} borderColor="o-light">
              {/* {listItem.payment && listItem.plan === "basic" && (
                <StripeCheckoutButton
                  listing={listItem}
                  price={340}
                  upgradePlan={true}
                  p="0 1rem"
                  d={{ xs: "flex", sm: "none" }}
                  align="center"
                  bg="white"
                  onMouseEnter={() => setSelectedListIndex("pay")}
                  className={`profile-dropdown--list ${
                    selectedListIndex === "pay" && "active"
                  }`}
                  textColor="medium"
                  h="2.5rem"
                  rounded="lg"
                  transition
                  text={
                    <>
                      <Icon
                        name="Card"
                        size="20px"
                        color="light"
                        m={{ r: "0.75rem" }}
                      />
                      <Text textWeight="600">Upgrade Plan</Text>
                    </>
                  }
                />
              )} */}
              {[
                {
                  icon: "EyeSolid",
                  name: "Preview Listing",
                  path: `/listing/${slug}`,
                },
                {
                  icon: "EditSolid",
                  name: "Edit Details",
                  path: `/listing/${slug}/edit`,
                  hide: user.role !== "ADMIN" && user.role !== "EDITOR",
                },
              ]
                .filter((item) => !item.hide)
                .map((item, index) => {
                  const isSelected = selectedListIndex === index;
                  return (
                    <Div
                      p="0 1rem"
                      d="flex"
                      align="center"
                      // hoverBg="gray200"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        history.push(item.path);
                      }}
                      onMouseEnter={() => setSelectedListIndex(index)}
                      className={`profile-dropdown--list ${
                        isSelected && "active"
                      }`}
                      textColor="medium"
                      // hoverTextColor="dark"
                      h="2.5rem"
                      rounded="lg"
                      transition
                    >
                      <Icon
                        name={item.icon}
                        size="20px"
                        color="light"
                        m={{ r: "0.75rem" }}
                      />
                      <Text textWeight="600">{item.name}</Text>
                    </Div>
                  );
                })}
            </Div>
          </Div>
        )}
      </Transition>
    </Div>
  );
}
