import { createContext, useState, useEffect } from "react";
import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";

export const AuthContext = createContext({ user: {} });

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const appState = localStorage["appState"];
    if (appState) {
      const parsedState = JSON.parse(appState);
      return {
        user: parsedState.user ? parsedState.user : {},
        isLoggedIn: !!parsedState.user,
      };
    }
    return {
      user: {},
      isLoggedIn: false,
    };
  });

  /**
   * On mount
   */
  useEffect(() => {
    const appState = localStorage["appState"];
    if (appState) {
      const parsedState = JSON.parse(appState);
      const decoded = jwt_decode(parsedState.token);
      const currentTime = Date.now() / 1000;

      if (decoded.exp < currentTime) {
        clearUserData();
        localStorage.clear();
      } else {
        setAuth({
          user: parsedState.user ? parsedState.user : {},
          isLoggedIn: !!parsedState.user,
        });
      }
    }
  }, []);

  /**
   * logout user
   */
  const logout = () => {
    localStorage.removeItem("appState");
    Cookie.remove("token");

    setAuth({
      user: {},
      isLoggedIn: false,
    });
  };

  /**
   * Login a user
   * @param {userState} appState
   */
  const login = (appState) => {
    localStorage["appState"] = JSON.stringify(appState);
    Cookie.set("token", appState.token, { expires: 360 });
    setAuth({
      user: appState.user,
      isLoggedIn: true,
    });
  };

  const clearUserData = () => {
    setAuth({ user: {}, isLoggedIn: false });
  };

  return (
    <AuthContext.Provider
      value={{
        user: auth.user,
        isLoggedIn: auth.isLoggedIn,
        logoutUser: logout,
        loginUser: login,
        setUser: (user) => setAuth({ user: user, isLoggedIn: !!user }),
        clearUserData: clearUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
