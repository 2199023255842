import React, { useContext } from "react";
import { Div, Text, Row, Col, Icon, Button, Image } from "atomize";

import CustomContainer from "../atoms/CustomContainer";
import { isAllFieldsFilled } from "../../utilities/helpers";
import { AuthContext } from "../../contexts/auth";

export default function Sidebar({
  options,
  currentStep,
  setStepCount,
  saveAndExit,
  listing = {},
  canShowCheckIcon,
}) {
  const { user } = useContext(AuthContext);
  const isAdminOREditor = user.role === "ADMIN" || user.role === "EDITOR";
  return (
    <Div
      d={{ xs: "none", xl: "block" }}
      pos="fixed"
      top="7.5rem"
      left="0"
      right="0"
    >
      <CustomContainer>
        <Row>
          <Col size="3" offset={{ xl: "1" }}>
            <Div pos="relative">
              <Div pos="absolute" top="0" right="0" left="0">
                <Div fontFamily="primary">
                  {options.map((option, index) => (
                    <Div
                      key={option.name}
                      m={{ b: "8px" }}
                      d="flex"
                      align="center"
                      cursor="pointer"
                      tabIndex="0"
                      onClick={() => setStepCount(index + 1)}
                      onKeyDown={() => setStepCount(index + 1)}
                    >
                      <Icon
                        m={{ r: "13.67px" }}
                        name={
                          isAllFieldsFilled(
                            index + 1,
                            listing,
                            canShowCheckIcon
                          )
                            ? "Success"
                            : "Dot"
                        }
                        color={
                          isAllFieldsFilled(
                            index + 1,
                            listing,
                            canShowCheckIcon
                          )
                            ? "success700"
                            : index + 1 === currentStep
                            ? "brand700"
                            : "gray400"
                        }
                        size="20px"
                      ></Icon>
                      <Text
                        textColor={
                          index + 1 === currentStep
                            ? "text-dark"
                            : isAllFieldsFilled(
                                index + 1,
                                listing,
                                canShowCheckIcon
                              )
                            ? "text-medium"
                            : "text-light"
                        }
                        textSize="paragraph"
                        textWeight="600"
                      >
                        {option.name}
                      </Text>
                    </Div>
                  ))}
                  {isAdminOREditor && (
                    <Button
                      style={{ whiteSpace: "nowrap" }}
                      prefix={
                        <Image
                          src="/images/icons/save.svg"
                          w="20px"
                          m={{ r: "0.5rem" }}
                        />
                      }
                      textAlign="center"
                      w="8rem"
                      h="2.5rem"
                      textColor="brand700"
                      textWeight="600"
                      textSize="paragraph"
                      bg="brand200"
                      hoverBg="brand300"
                      m={{ t: "2.5rem" }}
                      onClick={saveAndExit}
                    >
                      Save & Exit
                    </Button>
                  )}
                </Div>
              </Div>
            </Div>
          </Col>
        </Row>
      </CustomContainer>
    </Div>
  );
}
