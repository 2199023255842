import React from "react";
import { Div, Text } from "atomize";
// import MapDisplay from "../map/MapDisplay";

export default function ProductLocation({ listing }) {
  return (
    <Div p={{ t: "3.5rem" }} maxW={{ md: "85%", lg: "70%" }}>
      <Text textSize="title" textWeight="500">
        Location
      </Text>
      <Text
        m={{ t: "0.5rem", b: "1.75rem" }}
        textColor="text-medium"
        textSize="subheader"
      >
        {listing.address}
      </Text>
      {/* <MapDisplay latitude={listing.latitude} longitude={listing.longitude} /> */}
    </Div>
  );
}
