export const theme = {
  fontFamily: {
    primary:
      '"matter","Graphik", "SF Pro Text", -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
  colors: {
    // Brand
    brand100: "#F4F8FF",
    brand200: "#E3EDFF",
    brand300: "#CCDDFF",
    brand400: "#9FBFFF",
    brand500: "#6197FF",
    brand600: "#2260FF",
    brand700: "#0948EA",
    brand800: "#0037C4",
    brand900: "#00288D",
    // Success
    success100: "#F2FEFB",
    success200: "#D9F4EC",
    success300: "#B2F1DD",
    success400: "#85E4C6",
    success500: "#3ED3A3",
    success600: "#22C993",
    success700: "#0BB07B",
    success800: "#00865A",
    success900: "#006242",
    // Warning
    warning100: "#FFFCF5",
    warning200: "#FFF6E4",
    warning300: "#FFECC7",
    warning400: "#FFDC99",
    warning500: "#FFCA65",
    warning600: "#FFBB38",
    warning700: "#FFAD0D",
    warning800: "#FF8F00",
    warning900: "#F07300",
    // Danger
    danger100: "#FFF8F8",
    danger200: "#FFEAEA",
    danger300: "#FFC2C2",
    danger400: "#FF8D8D",
    danger500: "#FF5D5D",
    danger600: "#F03D3D",
    danger700: "#DD2727",
    danger800: "#BD0303",
    danger900: "#A50000",
    // Info
    info100: "#F8FBFF",
    info200: "#EEF7FF",
    info300: "#DCEEFF",
    info400: "#B3DAFF",
    info500: "#83C3FE",
    info600: "#4BA7FE",
    info700: "#0284FE",
    info800: "#026DD6",
    info900: "#01408F",
    // Gray
    gray100: "#FAFBFB",
    gray200: "#F7F8F9",
    gray300: "#F1F2F4",
    gray400: "#E7EAED",
    gray500: "#C9CED6",
    gray600: "#B0B7C3",
    gray700: "#A7AEBB",
    gray800: "#98A1B1",
    gray900: "#8A94A6",
    // Black
    black100: "#717D92",
    black200: "#627088",
    black300: "#596780",
    black400: "#4E5D78",
    black500: "#455571",
    black600: "#364766",
    black700: "#283A5B",
    black800: "#14284B",
    black900: "#0A1F44",
    // Outlines
    "o-light": "#F1F2F4",
    "o-medium": "#E7EAED",
    "o-base": "#C9CED6",
    "o-dark": "#B0B7C3",
    // Text
    "text-disabled": "#B0B7C3",
    "text-light": "#8A94A6",
    "text-medium": "#4E5D78",
    "text-dark": "#0A1F44",
    disabled: "#B0B7C3",
    light: "#8A94A6",
    medium: "#4E5D78",
    dark: "#0A1F44",
    // Pastels
    pastel6: "#F5926F",
    // dropdown
    "dropdown-gradient":
      "linear-gradient(180deg, rgba(255, 255, 255, 0.06) 52.6%, rgba(10, 31, 68, 0.06) 100%), #FFFFFF",
  },
  grid: {
    containerMaxWidth: "82rem",
    colCount: 16,
  },
  textSize: {
    size: {
      overline: "10px",
      caption: "12px",
      body: "14px",
      paragraph: "14px",
      subheader: "16px",
      subheader2: "19px",
      title: "20px",
      heading: "24px",
      display1: "28px",
      "display1.5": "32px",
      display2: "34px",
      display3: "44px",
      display5: "64px",
    },
    height: {
      overline: "16px",
      caption: "20px",
      body: "22px",
      paragraph: "24px",
      subheader: "28px",
      subheader2: "30px",
      title: "32px",
      heading: "36px",
      display1: "40px",
      "display1.5": "40px",
      display2: "48px",
      display3: "56px",
      display5: "72px",
    },
  },
  shadows: {
    1: "0 1px 1px 0 rgba(10, 31, 68, 0.1)",
    2: "0 2px 6px 0 rgba(10, 31, 68, 0.08)",
    3: "0 9px 14px 0 rgba(10, 31, 68, 0.08)",
    4: "0 32px 40px 0 rgba(10, 31, 68, 0.1)",
    up1: "0 -1px 1px 0 rgba(10, 31, 68, 0.1)",
    inner1: "inset 0px 2px 2px rgba(10, 31, 68, 0.12)",
  },
};
