import React from "react";
import { Div, Image, Button, Icon } from "atomize";

const SingleImageThumbnail = ({
  imageSrc,
  open,
  removeImage,
  getRootProps,
  getInputProps,
  showReplace,
  isLoading = false,
  isMessageImage,
}) => {
  return (
    <Div
      pos="relative"
      m={{ t: "4px" }}
      w="100%"
      rounded="lg"
      d="flex"
      flexDir="column"
      align="center"
      overflow="hidden"
    >
      <Div
        p={{ b: "68%" }}
        bgImg={imageSrc}
        w="100%"
        bgSize="cover"
        bgPos="center"
      />
      {isLoading && (
        <>
          <Div
            pos="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg="black"
            opacity="0.72"
          ></Div>
          <Icon
            name="Loading"
            pos="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            size="20px"
            color="white"
          />
        </>
      )}
      <Div
        style={{ position: "absolute", alignSelf: "flex-end" }}
        d="flex"
        p="1rem"
      >
        <Div {...getRootProps()} rounded="md" m={{ r: "8px" }}>
          <input {...getInputProps()}></input>
          {showReplace && !isLoading && (
            <Button
              tabIndex="-1"
              type="button"
              prefix={
                <Image
                  src="/images/icons/Replace.svg"
                  w="1rem"
                  m={{ r: "0.5rem" }}
                />
              }
              textAlign="center"
              w="88px"
              h="32px"
              textColor="text-medium"
              textWeight="500"
              textSize="caption"
              bg="white"
              rounded="md"
              border="1px solid"
              borderColor="o-light"
            >
              Replace
            </Button>
          )}
        </Div>
        {!isLoading && !isMessageImage && (
          <Button
            type="button"
            onClick={() => removeImage()}
            prefix={
              <Image
                src="/images/icons/Delete.svg"
                w="1rem"
                m={{ r: "0.5rem" }}
              />
            }
            textAlign="center"
            w="88px"
            h="32px"
            textColor="white"
            rounded="md"
            textWeight="500"
            textSize="caption"
            bg="danger800"
          >
            Remove
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default SingleImageThumbnail;
