import React, { useContext } from "react";
import { Div, Text, Anchor } from "atomize";
// import { toast } from "react-toastify";

import { axiosInstance } from "../../utilities/axiosInstance";

import SingleImageDropzone from "../common/SingleImageDropzone";
import MultiImageDropzone from "../common/MultiImageDropzone";
import ListingFooter from "./ListingFooter";
import { AuthContext } from "../../contexts/auth";

export default function CarImagesForm({
  currentStep,
  setCurrentStep,
  currentPlan,
  setShowPricingModal,
  listingData,
  thumbnail,
  updateImages,
  images,
  updateListing,
  setThumbnail,
  setImages,
  setListing,
  areImagesUploading,
}) {
  const { user } = useContext(AuthContext);
  const { data: listing, isEditing } = listingData;

  const removeCover = () => {
    const { id } = listing;
    console.log(id);
    axiosInstance
      .post(`listings/${id}`, { coverImage: null })
      .then((response) => {
        updateListing(response.data.data, false);
      })
      .catch((error) => {
        // toast.error(getErrorMessage(error));
      });
  };

  const filterImages = (listing) => {
    if (listing.plan === "advanced") {
      return listing.images;
    }
    //For basic plan show only 12 images
    return listing.images.filter((image, index) => index < 12);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.role !== "ADMIN" && user.role !== "EDITOR") {
      return setCurrentStep(currentStep + 1);
    }
    updateImages(e);
  };

  return (
    <Div>
      <Text textWeight="500" textColor="light" m={{ b: "0.5rem" }}>
        Step {currentStep} of 5
      </Text>

      <Text
        textColor="text-dark"
        textSize="display2"
        textWeight="600"
        m={{ b: "2.5rem" }}
      >
        Images
      </Text>
      <form onSubmit={handleSubmit}>
        <Div>
          <Div m={{ t: "2.5rem" }}>
            <Text
              m={{ b: "0.25rem" }}
              textWeight="500"
              textSize="caption"
              textColor="text-light"
            >
              Cover image
            </Text>
            <SingleImageDropzone
              thumbnail={thumbnail}
              setThumbnail={setThumbnail}
              coverUrl={listing.mediumCoverUrl}
              removeCover={removeCover}
            />
          </Div>
          <Div m={{ t: "2.5rem" }}>
            {currentPlan === "basic" ? (
              <Text
                m={{ b: "0.25rem" }}
                textWeight="500"
                textSize="caption"
                textColor="text-light"
              >
                Upload upto 12 images for the vehicle.{" "}
                <Anchor
                  tabIndex="0"
                  textColor="black"
                  hoverTextColor="black"
                  textDecor="underline"
                >
                  <Text
                    textColor="text-dark"
                    textDecor="underline"
                    tag="span"
                    cursor="pointer"
                    onClick={() => setShowPricingModal(true)}
                  >
                    Update plan
                  </Text>
                </Anchor>{" "}
                to increase limit.
              </Text>
            ) : (
              <Text
                m={{ b: "0.25rem" }}
                textWeight="500"
                textSize="caption"
                textColor="text-light"
              >
                Upload images for the vehicle.
              </Text>
            )}

            <MultiImageDropzone
              listing={listing}
              listingImages={filterImages(listing)}
              images={images}
              setImages={setImages}
              updateListing={updateListing}
              setListing={setListing}
              areImagesUploading={areImagesUploading}
            />
          </Div>
        </Div>
        <ListingFooter
          back={() => setCurrentStep(currentStep - 1)}
          isEditing={isEditing}
          currentStep={currentStep}
        />
      </form>
    </Div>
  );
}
