import React, { useContext } from "react";
import { Div, Text, Icon } from "atomize";
// import StripeCheckoutButton from "../payment/StripeCheckoutButton";
import { PlanPriceContext } from "../../contexts/PlansPriceProvider";

export default function UpgradePricingMobile({ close, listing, plans }) {
  const { features } = useContext(PlanPriceContext);
  return (
    <Div>
      <Div rounded="8px" overflow="hidden" bg="white" d="flex" flexDir="column">
        <Div bg="brand700" h="4px"></Div>
        <Text
          p="1.75rem 1rem 1.5rem"
          textSize="title"
          textWeight="600"
          textAlign="center"
          textColor="brand700"
          textTransform="capitalize"
        >
          {plans["advanced"].name}
        </Text>

        <Div d="flex" flexDir="column" p={{ x: "32px" }}>
          {Object.keys(features).map((key) => (
            <Div
              className="pricing-upgrade-mobile-feature"
              d="flex"
              w="100%"
              p="11.5px 0rem 12.5px"
              align="center"
              border={{ b: "1px solid" }}
              borderColor="o-light"
            >
              <Icon name="Checked" color="brand700" />
              <Text textWeight="500" textColor="medium" m={{ l: "20px" }}>
                {features[key].name}
                {key === "imageLimit" && ` ( Unlimited )`}
              </Text>
            </Div>
          ))}
        </Div>

        <Div p="1.43rem 2rem 2.5rem">
          <Text
            textAlign="center"
            textColor="black400"
            textWeight="500"
            textSize="caption"
            m={{ b: "0.25rem" }}
          >
            For additional
          </Text>

          <Text
            textAlign="center"
            textColor="light"
            textWeight="500"
            m={{ b: "1.5rem" }}
          >
            <Text tag="span" textColor="dark" textSize="title" textWeight="600">
              ${plans["advanced"].price - plans["basic"].price}
            </Text>{" "}
            /per vehicle
          </Text>

          {/* <StripeCheckoutButton
            listing={listing}
            price={plans["advanced"].price - plans["basic"].price}
            upgradePlan={true}
            text="Upgrade Package"
            style={{ width: "100%", maxWidth: "unset", borderRadius: "100px" }}
          /> */}

          <Text
            textAlign="center"
            textColor="black400"
            textWeight="500"
            textSize="caption"
            m={{ t: "16px" }}
          >
            The upgrade amount includes what you already paid for the Basic
            plan.
          </Text>
        </Div>
      </Div>
    </Div>
  );
}
