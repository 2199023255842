import Axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const PlanPriceContext = createContext();

const features = {
  vehicleListing: {
    name: "Vehicle listing",
  },
  imageLimit: {
    name: "Image uploads",
  },
  // professionalPhotography: {
  //   name: "Professional photography",
  // },
  homepageFeature: {
    name: "Homepage featured visibility",
  },
  socialMediaPost: {
    name: "Social media posting",
  },
};

export const PlanPriceProvider = ({ children }) => {
  const [advancePlanPrice, setAdvancePlanPrice] = useState(68);
  const [basicPlanPrice, setBasicPlanPrice] = useState(38);
  const [basicPlanPriceId, setBasicPlanPriceId] = useState("");
  const [advancedPlanPriceId, setAdvancedPlanPriceId] = useState("");

  const plans = {
    basic: {
      name: "basic",
      imageLimit: 12,
      professionalPhotography: false,
      homepageFeature: false,
      socialMediaPost: false,
      stripePriceId: basicPlanPriceId,
      price: basicPlanPrice,
    },
    advanced: {
      name: "advanced",
      imageLimit: null,
      professionalPhotography: true,
      homepageFeature: true,
      socialMediaPost: true,
      stripePriceId: advancedPlanPriceId,
      price: advancePlanPrice,
    },
  };

  const setPlanPrices = (prices) => {
    const activePrices = prices.filter((price) => price.active);
    const basicPlanPrices = activePrices.filter(
      (price) => price.product === process.env.REACT_APP_STRIPE_BASIC_PRODUCT
    );
    const advancedPlanPrices = activePrices.filter(
      (price) => price.product === process.env.REACT_APP_STRIPE_ADVANCED_PRODUCT
    );

    setBasicPlanPrice(Math.round(basicPlanPrices[0].unit_amount / 100));
    setAdvancePlanPrice(Math.round(advancedPlanPrices[0].unit_amount / 100));
    setBasicPlanPriceId(basicPlanPrices[0].id);
    setAdvancedPlanPriceId(advancedPlanPrices[0].id);
  };

  useEffect(() => {
    Axios.get("https://dashboard.stripe.com/v1/prices", {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
      },
    }).then((result) => setPlanPrices(result.data.data));
  }, []);

  return (
    <PlanPriceContext.Provider value={{ plans, features }}>
      {children}
    </PlanPriceContext.Provider>
  );
};
