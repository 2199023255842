import React, { useEffect, useState } from "react";
import { Div, Text, Input, Button, Icon } from "atomize";
import validator from "validator";

import Dropdown from "../atoms/Dropdown";
import { axiosInstance } from "../../utilities/axiosInstance";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utilities/helpers";
import ConfirmRoleModal from "../modals/ConfirmRoleModal";

const initialState = {
  email: "",
  role: "VIEWER",
};
export default function TeamMembersMain() {
  const [invite, setInvite] = useState(initialState);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [members, setMembers] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [memberToBeUpdated, setMemberToBeUpdated] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const response = await axiosInstance.get("/admin/invites");
        setMembers(response.data.data);
      } catch (error) {
        toast.error(getErrorMessage(error));
      }
    })();
  }, []);

  const handleSendInvite = async () => {
    if (!validator.isEmail(invite.email)) {
      return toast.error("Enter a valid email address.");
    }
    try {
      setSendingInvite(true);
      await axiosInstance.post("/admin/invite", invite);
      setSendingInvite(false);
      setInvite(initialState);
      toast.success("Invite sent successfully.");
    } catch (error) {
      toast.error(getErrorMessage(error));
      setSendingInvite(false);
    }
  };

  const updateRole = async (role, email) => {
    try {
      await axiosInstance.post("/admin/update-role", { email, role });
      if (role === "CUSTOMER") {
        //remove the member from state
        setMembers((prev) => prev.filter((member) => member.email !== email));
      } else {
        //update member role in state
        setMembers((prev) =>
          prev.map((member) =>
            member.email === email ? { ...member, role } : member
          )
        );
      }
      setShowConfirmModal(false);
      setMemberToBeUpdated({});
    } catch (error) {
      setShowConfirmModal(false);
      setMemberToBeUpdated({});
      toast.error(getErrorMessage(error));
    }
  };

  const onSelect = (value, member) => {
    if (value.key === member.role) return;
    setMemberToBeUpdated({ ...member, newRole: value.key });
    setShowConfirmModal(true);
  };
  return (
    <>
      <Text
        textColor="text-dark"
        textSize="display2"
        textWeight="600"
        m={{ b: "2.5rem" }}
      >
        Team members
      </Text>
      <Div
        rounded="lg"
        shadow="2"
        border="1px solid"
        borderColor="o-medium"
        d="flex"
        align={{ sm: "center" }}
        flexDir={{ xs: "column", sm: "row" }}
        m={{ b: "2.5rem" }}
      >
        <Div flexGrow="1" pos="relative">
          <Input
            transition="none"
            bg="transparent"
            border={{
              b: { xs: "1px solid", sm: "none" },
              r: { sm: "1px solid" },
            }}
            borderColor="o-medium"
            focusBorderColor="o-medium"
            rounded="0"
            p="0 1rem 0 3rem"
            h="4rem"
            placeholder="Enter email address"
            textWeight="500"
            value={invite.email}
            onChange={(e) =>
              setInvite((prev) => ({ ...prev, email: e.target.value }))
            }
          />
          <Icon
            name="Email"
            size="18px"
            color="light"
            pos="absolute"
            left="1.25rem"
            top="50%"
            transform="translateY(-50%)"
          />
        </Div>
        <Div d="flex" justify="space-between">
          <Div w="120px" m={{ l: "0.5rem" }}>
            <Dropdown
              cursor="pointer"
              list={[
                { key: "EDITOR", text: "Can edit" },
                { key: "VIEWER", text: "Can view" },
              ]}
              onChange={(value) =>
                setInvite((prev) => ({ ...prev, role: value.key }))
              }
              h="4rem"
              bg="white"
              border="none"
              initialValue={invite.role}
            />
          </Div>
          <Button
            m="0.5rem"
            minW="152px"
            h="3rem"
            textWeight="600"
            bg="brand700"
            disabled={sendingInvite}
            className="disabled-color"
            onClick={handleSendInvite}
          >
            {sendingInvite ? (
              <Icon name="Loading2" color="white" />
            ) : (
              "Send Invite"
            )}
          </Button>
        </Div>
      </Div>
      <Div
        m={{ b: "0.5rem" }}
        d="flex"
        align="center"
        justify="space-between"
        p="0.5rem 0"
        border={{ y: "1px solid" }}
        borderColor="o-light"
      >
        <Text textSize="caption" textColor="light" textWeight="500">
          {members.length} Team {members.length > 1 ? "members" : "member"}
        </Text>
        <Text textSize="caption" textColor="light" textWeight="500">
          Member’s role
        </Text>
      </Div>
      {members.map((member) => {
        return (
          <Div
            p="1rem 0 1.5rem"
            d="flex"
            align="flex-start"
            justify="space-between"
            border={{ b: "1px solid" }}
            borderColor="o-light"
          >
            <Div d="flex" align="center">
              <Div
                bgImg={member.avatarUrl || "/images/users/default-img.svg"}
                bgSize="cover"
                bgPos="center"
                w="2.5rem"
                h="2.5rem"
                rounded="circle"
                m={{ r: "1rem" }}
              />
              <Div>
                <Text m={{ b: "2px" }} textWeight="600" textColor="dark">
                  {member.firstName}&nbsp;{member.lastName}
                </Text>
                <Text textColor="light">{member.email}</Text>
              </Div>
            </Div>
            <Dropdown
              cursor="pointer"
              list={[
                { key: "EDITOR", text: "Can edit" },
                { key: "VIEWER", text: "Can view" },
                { key: "CUSTOMER", text: "Remove" },
              ]}
              onChange={(value) => onSelect(value, member)}
              p="0"
              bg="white"
              border="none"
              initialValue={member.role}
              iconProps={{ m: { l: "0.5rem" } }}
              menuProps={{ left: "auto", right: "0" }}
            />
          </Div>
        );
      })}
      <ConfirmRoleModal
        close={() => setShowConfirmModal(false)}
        isOpen={showConfirmModal}
        handleConfirm={updateRole}
        member={memberToBeUpdated}
      />
    </>
  );
}
