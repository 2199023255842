import React from "react";
import { Text, Div, Image } from "atomize";
import Calendar from "react-calendar";
import Modal from "./Modal";
// import "react-calendar/dist/Calendar.css";

export default function DateSelector({
  list,
  label,
  placeholder,
  value,
  onChange,
  minDate,
  maxDate,
  ...rest
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      {label && (
        <Text
          className="truncate-text"
          textSize="caption"
          textColor="light"
          textWeight="500"
          m={{ b: "0.25rem" }}
        >
          {label}
        </Text>
      )}
      <Div
        pos="relative"
        d="flex"
        tabIndex="0"
        onClick={() => setIsOpen(!isOpen)}
        align="center"
        justify="space-between"
        bg="dropdown-gradient"
        border="1px solid"
        borderColor="o-light"
        textSize="14px"
        textWeight="500"
        rounded="6px"
        p="0 1rem"
        h="3rem"
        textColor={value ? "medium" : "light"}
      >
        {value || "YYYY-MM-DD"}
        <Image src="/images/icons/calendar.svg" w="20px" m={{ l: "0.5rem" }} />
      </Div>
      <Modal w="auto" p="2rem" isOpen={isOpen} close={setIsOpen}>
        <Calendar
          defaultView="decade"
          maxDate={maxDate}
          minDate={minDate}
          onClickDay={(value, event) => {
            onChange(value);
            setIsOpen(false);
          }}
          {...rest}
        />
      </Modal>
    </>
  );
}
