import React from "react";
import { Text, Div, Icon, Button } from "atomize";
import { Transition } from "react-transition-group";

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function DesktopFilterDropdownWrapper({
  isLoading,
  label,
  children,
  selectedFilter,
  m = { xs: "0 8px 0rem 0", lg: "0 10px 0rem 0" },
  setSelectedFilter,
  appliedFilters,
  onApplying,
  ...rest
}) {
  const show = selectedFilter === label;

  return (
    <Div
      pos="relative"
      tabIndex="0"
      className="focus-container"
      m={m}
      onFocus={() => setSelectedFilter(label)}
      // onBlur={() => console.log(document.activeElement)}
    >
      {/* Button */}
      <Div
        d="flex"
        align="center"
        className="focus"
        justify="space-between"
        bg="dropdown-gradient"
        p="0.5rem 15px"
        border="1px solid"
        borderColor="o-medium"
        rounded="md"
        cursor="pointer"
      >
        <Text textWeight="500" textColor="medium" m={{ r: "6px" }}>
          {label}
        </Text>
        <Icon name="DownArrow" color="text-medium" size="20px" />
      </Div>

      {/* Filter */}
      <Transition in={show} timeout={duration} unmountOnExit>
        {(state) => (
          <Div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            pos="absolute"
            top="calc(100% + 9px)"
            left="0"
            minW="384px"
            bg="white"
            rounded="lg"
            border="1px solid"
            borderColor="o-light"
            shadow="3"
            zIndex="3"
            {...rest}
          >
            {children}
            <Div
              border={{ t: "1px solid" }}
              borderColor="o-light"
              p="1rem 1.5rem 1.5rem"
              d="flex"
              justify="space-between"
            >
              <Div></Div>
              <Button
                h="2.5rem"
                w="6rem"
                bg={isLoading ? "brand800" : "brand700"}
                textWeight="600"
                rounded="md"
                onClick={() => onApplying(appliedFilters)}
              >
                {isLoading ? <Icon name="Loading2" color="white" /> : "Apply"}
              </Button>
            </Div>
          </Div>
        )}
      </Transition>
    </Div>
  );
}
