import React, { useState, useEffect } from "react";
import { Div, Text, Row, Col, Button } from "atomize";
import {
  getValidationMessage,
  getErrorMessage,
  isValidStr,
  formatDateV2,
} from "../../utilities/helpers";
import { axiosInstance } from "../../utilities/axiosInstance";

import FormGlobalMessage from "../atoms/FormGlobalMessage";
import DefaultInput from "../atoms/DefaultInput";
import ProfilePicEditor from "../atoms/ProfilePicEditor";
import Dropdown from "../atoms/Dropdown";
import DateSelector from "../atoms/DateSelector";
import { uploadAvatarImageToS3 } from "../../utilities/aws";

export default function EditProfileForm({ user, removeProfilePic, setUser }) {
  const [formData, setFormData] = useState({
    profilePic: [],
    firstName: user.firstName,
    lastName: user.lastName,
    gender: user.gender || "MALE",
    dob: formatDateV2(user.dob),
    formSubmitting: false,
    errors: {},
    globalError: null,
    globalSuccess: null,
  });

  /**
   * Change the form values
   * @param {new values} e
   */
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  /**
   * Interaction change
   * @param {newvalues} newValues
   */
  const interactionChange = (newValues) => {
    setFormData({ ...formData, ...newValues });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      ...user,
      dob: formatDateV2(user.dob),
      gender: user.gender || "MALE",
    });
  }, [user]);

  const updateProfile = async (e) => {
    e.preventDefault();
    const { profilePic, firstName, lastName, gender, dob } = formData;
    const fileName = await uploadAvatarImageToS3(profilePic[0]);
    const data = new FormData();
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("gender", gender);
    // data.append("file", profilePic && profilePic[0]);
    fileName && data.append("fileName", fileName);
    data.append("dob", dob);

    interactionChange({
      formSubmitting: true,
      globalError: null,
      globalSuccess: null,
    });

    axiosInstance
      .post("users/profile", data, { timeout: 20000 })
      .then((response) => {
        setTimeout(() => {
          setUser(response.data.data);
          interactionChange({
            formSubmitting: false,
            globalSuccess: "Saved successfully",
          });

          setTimeout(() => {
            interactionChange({
              globalSuccess: "",
            });
          }, 2000);
        }, 5000);

        const appState = JSON.parse(localStorage["appState"]);
        appState.user = response.data.data;
        localStorage["appState"] = JSON.stringify(appState);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          interactionChange({
            formSubmitting: false,
            errors: {
              firstName: getValidationMessage(error, "firstName"),
              lastName: getValidationMessage(error, "lastName"),
              gender: getValidationMessage(error, "gender"),
              dob: getValidationMessage(error, "dob"),
            },
          });
        } else {
          interactionChange({
            formSubmitting: false,
            globalError: getErrorMessage(error),
          });
        }
      });
  };

  return (
    <>
      {
        <FormGlobalMessage
          m={{ b: "1.5rem" }}
          error={formData.globalError}
          success={formData.globalSuccess}
        />
      }
      <form onSubmit={updateProfile}>
        <Div>
          <Text
            textColor="text-dark"
            textSize="display2"
            textWeight="600"
            m={{ b: "4.5rem" }}
          >
            Edit profile
          </Text>
          <ProfilePicEditor
            profilePic={formData.profilePic}
            setProfilePic={(files) =>
              handleChange({ target: { name: "profilePic", value: files } })
            }
            user={user}
            removeProfilePic={() => {
              setFormData((prev) => ({ ...prev, profilePic: [] }));
              removeProfilePic();
            }}
          />
          <Row>
            <Col size={{ xs: "16", md: "8" }}>
              <DefaultInput
                label="First name"
                name="firstName"
                h="3rem"
                m={{ b: "1.5rem" }}
                value={formData.firstName}
                handleChange={handleChange}
                error={
                  isValidStr(formData.errors.firstName) &&
                  formData.errors.firstName
                }
              />
            </Col>
            <Col size={{ xs: "16", md: "8" }}>
              <DefaultInput
                label="Last name"
                name="lastName"
                placeholder=""
                h="3rem"
                m={{ b: "1.5rem" }}
                value={formData.lastName}
                handleChange={handleChange}
                error={
                  isValidStr(formData.errors.lastName) &&
                  formData.errors.lastName
                }
              />
            </Col>
          </Row>
          <Row>
            <Col size={{ xs: "16", md: "8" }}>
              <DateSelector
                label="Date of birth"
                m={{ b: "2.5rem" }}
                value={formData.dob}
                onChange={(value) =>
                  handleChange({
                    target: { name: "dob", value: formatDateV2(value) },
                  })
                }
                error={
                  isValidStr(formData.errors.dob) ? formData.errors.dob : null
                }
              />
            </Col>
            <Col size={{ xs: "16", md: "8" }}>
              <Dropdown
                label="Gender"
                list={[
                  { key: "MALE", text: "Male" },
                  { key: "FEMALE", text: "Female" },
                  { key: "INTERSEX", text: "Intersex" },
                  { key: "TRANSGENDER", text: "Transgender" },
                  { key: "OTHER", text: "Others" },
                ]}
                h="3rem"
                m={{ b: "2.5rem" }}
                initialValue={formData.gender}
                onChange={(value) =>
                  handleChange({ target: { name: "gender", value: value.key } })
                }
                error={
                  isValidStr(formData.errors.gender)
                    ? formData.errors.gender
                    : null
                }
              />
            </Col>
          </Row>
          <Button
            disabled={formData.formSubmitting}
            type="submit"
            bg="brand700"
            minW="9rem"
            h="3rem"
            textWeight="600"
          >
            Save Profile
          </Button>
        </Div>
      </form>
    </>
  );
}
