import React from "react";
import { Div, Text, Icon } from "atomize";

import Root from "./Root";

export default function PageError({ errorMessage }) {
  return (
    <Root>
      <Div
        p={{ y: "4rem" }}
        minH="100vh"
        d="flex"
        align="center"
        justify="center"
        textAlign="center"
      >
        <Icon name="Alert" size="18px" m={{ r: "0.75rem" }} color="danger700" />
        <Text textWeight="500" textColor="danger700">
          Error {errorMessage}
        </Text>
      </Div>
    </Root>
  );
}
