import React, { useState, createContext, useEffect } from "react";
import { axiosInstance } from "../utilities/axiosInstance";

const initialState = {
  model: "",
  make: "",
  // minLeasePayment: 0,
  // maxLeasePayment: 100000,
  // minMonthsRemaining: 0,
  // maxMonthsRemaining: 1000,
  // bodyTypes: [],
  // colors: [],
  // fuelTypes: [],
  // minPassengers: 2,
  // maxPassengers: 9,
  sort: "latest",
  locations: [],
  userIds: [],
  // minYear: 1990,
  // maxYear: 2020,
  plans: [],
};

export const FiltersContext = createContext();

export const FiltersProvider = ({ children }) => {
  const [appliedFilters, setAppliedFilters] = useState(initialState);
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  // const [monthlyCostLimit, setMonthlyCostLimit] = useState([0, 0]);

  useEffect(() => {
    (async () => {
      const response = await axiosInstance.get("listings/filters/min-max", {
        params: { all: true },
      });

      setAppliedFilters((prev) => ({
        ...prev,
        ...response.data.data,
        // bodyTypes: [],
        // colors: [],
        // fuelTypes: [],
      }));

      // setMonthlyCostLimit([
      //   response.data.data.minLeasePayment,
      //   response.data.data.maxLeasePayment,
      // ]);
    })();
  }, []);

  const setFilters = (name, value) => {
    if (name === "make") {
      setAppliedFilters((prevState) => ({
        ...prevState,
        [name]: value,
        model: "",
      }));
    } else {
      setAppliedFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const resetFilters = () => {
    // const response = await axiosInstance.get("listings/filters/min-max", {
    //   params: { all: true },
    // });

    setAppliedFilters((prev) => ({
      ...initialState,
      // ...response.data.data,
      // bodyTypes: [],
      // colors: [],
      // fuelTypes: [],
    }));
  };

  return (
    <FiltersContext.Provider
      value={{
        setFilters,
        appliedFilters,
        resetFilters,
        showMoreFilter,
        setShowMoreFilter,
        showMobileFilter,
        setShowMobileFilter,
        // setMonthlyCostLimit,
        // monthlyCostLimit,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};
