import React from "react";
import { Div } from "atomize";

var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
let map;
let marker;

export default class MapDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    const { updateLocationFromMap } = this.props;
    map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [this.props.longitude, this.props.latitude],
      zoom: 10,
    });

    map.addControl(new mapboxgl.NavigationControl());

    marker = new mapboxgl.Marker({
      draggable: true,
    })
      .setLngLat([this.props.longitude, this.props.latitude])
      .addTo(map);

    var geolocate = new mapboxgl.GeolocateControl();

    map.addControl(geolocate);

    geolocate.on("geolocate", function (e) {
      var lon = e.coords.longitude;
      var lat = e.coords.latitude;
      updateLocationFromMap(lon, lat);
    });

    marker.on("dragend", this.onDragEnd);
  }

  onDragEnd() {
    var lngLat = marker.getLngLat();
    // coordinates.style.display = "block";
    this.props.updateLocationFromMap(lngLat.lng, lngLat.lat);
  }

  componentDidUpdate() {
    map.jumpTo({ center: [this.props.longitude, this.props.latitude] });
    marker.remove();
    marker = new mapboxgl.Marker({
      draggable: true,
    })
      .setLngLat([this.props.longitude, this.props.latitude])
      .addTo(map);
    marker.on("dragend", this.onDragEnd);
  }

  render() {
    return (
      <>
        <Div
          h="272px"
          id="map"
          pos="relative"
          rounded="md"
          overflow="hidden"
        ></Div>
        <pre id="coordinates" className="coordinates"></pre>
      </>
    );
  }
}
