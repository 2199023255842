import React from "react";
import { Div, Icon, Text, Button } from "atomize";

import Modal from "../atoms/Modal";
import DefaultInput from "../atoms/DefaultInput";
import { axiosInstance } from "../../utilities/axiosInstance";
import FormGlobalMessage from "../atoms/FormGlobalMessage";
import { getErrorMessage } from "../../utilities/helpers";

class EditEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      formSubmitting: false,
      globalError: null,
      globalSuccess: null,
    };
    this.updateEmail = this.updateEmail.bind(this);
  }

  updateEmail = (e) => {
    e.preventDefault();
    this.setState({
      formSubmitting: true,
      globalError: null,
      globalSuccess: null,
    });
    axiosInstance
      .post("users/update-email", { email: this.state.email })
      .then((response) => {
        this.setState({
          formSubmitting: false,
          email: "",
          globalSuccess: "Please check your inbox",
        });
      })
      .catch((error) => {
        this.setState({
          globalError: getErrorMessage(error),
          formSubmitting: false,
        });
      });
  };

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { isOpen, closeModal } = this.props;
    const { email, globalError, globalSuccess, formSubmitting } = this.state;
    return (
      <Modal
        maxW="35rem"
        isOpen={isOpen}
        // close={closeModal}
      >
        <Div
          d="flex"
          justify="space-between"
          border={{ b: "1px solid" }}
          borderColor="o-light"
          p="1.5rem"
        >
          <Text
            textSize="heading"
            textColor="black900"
            textWeight="600"
            p={{ l: "1.5rem" }}
          >
            Edit Email
          </Text>
          <Button
            onClick={closeModal}
            type="button"
            h="2.5rem"
            w="2.5rem"
            bg="gray200"
            border="1px solid"
            borderColor="gray300"
            rounded="circle"
            d="flex"
            align="center"
            justify="center"
          >
            <Icon name="Cross" color="black800" />
          </Button>
        </Div>
        <Div p={{ xs: "1.5rem 2rem 3rem", sm: "1.5rem 3rem 4rem" }}>
          <Text m={{ b: "2.75rem" }} textSize="subheader" textWeight="500">
            We’ll mail you the instructions to update your email address.
          </Text>
          <form onSubmit={this.updateEmail}>
            {
              <FormGlobalMessage
                error={globalError}
                success={globalSuccess}
                m={{ b: "1.5rem" }}
              />
            }
            <DefaultInput
              label="Email Address"
              placeholder="Enter new email address"
              m={{ b: "2.5rem" }}
              value={email}
              type="email"
              name="email"
              required
              handleChange={this.handleChange}
            />

            <Div d="flex" flexDir={{ xs: "column", sm: "row" }} align="center">
              <Button
                type="submit"
                bg="brand700"
                maxW={{ sm: "13rem" }}
                flexGrow={{ sm: "1" }}
                h="3rem"
                shadow="1"
                w={{ xs: "100%", sm: "auto" }}
                textWeight="600"
                m={{ xs: "0 0 0.5rem 0", sm: "0 1rem 0 0" }}
                disabled={formSubmitting}
              >
                Change password
              </Button>
              <Button
                type="button"
                bg="white"
                textColor="text-medium"
                border="1px solid"
                borderColor="o-medium"
                maxW={{ sm: "9rem" }}
                flexGrow={{ sm: "1" }}
                h="3rem"
                shadow="1"
                w={{ xs: "100%", sm: "auto" }}
                textWeight="600"
                m={{ xs: "0 0 0.5rem 0", sm: "0 1rem 0 0" }}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </Div>
          </form>
        </Div>
      </Modal>
    );
  }
}

export default EditEmail;
