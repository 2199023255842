import React, { useContext } from "react";
import { Div, Text, Icon, Button, Image } from "atomize";
import { AuthContext } from "../../contexts/auth";

export default function ListingBilling({
  plans,
  currentPlan,
  setShowPricingModal,
  listing,
  isNotEditable,
}) {
  const { user } = useContext(AuthContext);
  if (!currentPlan) return null;

  return (
    <>
      <Div m={{ b: "4rem" }}>
        <Div rounded="md" border="1px solid" borderColor="o-light" shadow="2">
          <Div
            border={{ b: "1px solid" }}
            borderColor="o-light"
            p="1rem 1.5rem"
            d="flex"
          >
            <Div
              d="flex"
              align="center"
              justify="center"
              h="3rem"
              w="3rem"
              bg={currentPlan === "advanced" ? "brand200" : "warning200"}
              border="1px solid"
              borderColor={
                currentPlan === "advanced" ? "brand300" : "warning300"
              }
              shadow="1"
              rounded="circle"
              m={{ r: "1rem" }}
            >
              {currentPlan === "advanced" ? (
                <Image src="/images/icons/crown.svg" w="24px" />
              ) : (
                <Icon name="StarSolid" color="pastel6" />
              )}
            </Div>
            <Div d="flex" flexDir="column" align="flex-start" justify="center">
              <Text
                textSize="title"
                textColor={currentPlan === "advanced" ? "brand800" : "pastel6"}
                textWeight="600"
                textTransform="capitalize"
              >
                {currentPlan}
              </Text>

              <Button
                onClick={() => {
                  const isAdminOREditor =
                    user.role === "ADMIN" || user.role === "EDITOR";
                  isAdminOREditor && setShowPricingModal(true);
                }}
                bg="white"
                h="auto"
                rounded="sm"
                p="0"
                d="flex"
                align="center"
                textColor="medium"
                hoverTextColor="dark"
                cursor="pointer"
                d={isNotEditable ? "none" : "flex"}
              >
                <Text textSize="caption" textWeight="500">
                  Change plan
                </Text>
                <Icon
                  name="DownArrow"
                  size="16px"
                  color="medium"
                  m={{ l: "2px" }}
                ></Icon>
              </Button>
            </Div>
          </Div>
          <Div p="1rem 1.5rem 1.25rem">
            <Text textSize="title" textColor="dark" textWeight="600">
              ${plans[currentPlan].price}
            </Text>

            <Text textSize="caption" textColor="light" textWeight="500">
              One time payment (Exclusive of taxes)
            </Text>
          </Div>
        </Div>
      </Div>
    </>
  );
}
