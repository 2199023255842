import React, { useState, useRef } from "react";
import { Div, Icon } from "atomize";
import { Transition } from "react-transition-group";
import DefaultInput from "./DefaultInput";

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function InputAutoComplete({
  result,
  label,
  placeholder,
  onChange,
  onSelect,
  name,
  value,
  ...rest
}) {
  const menuItemRef = useRef([]);
  const menu = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  // const [isFocused, setIsFocused] = useState(false);
  const [selectedListIndex, setSelectedListIndex] = useState(0);

  const scrollIntoView = (index) => {
    const currentElementTop = menuItemRef.current[index].offsetTop;
    const currentElementHeight = menuItemRef.current[index].clientHeight;
    const menuHeight = menu.current.clientHeight;
    const scrollPos = menu.current.scrollTop;
    // const checkViewPos = currentElementTop - scrollPos < menuHeight;

    // If down overflow
    if (currentElementTop - scrollPos + currentElementHeight > menuHeight - 8) {
      menu.current.scrollTop =
        currentElementTop + currentElementHeight - menuHeight + 8;
    }
    // If Up overflow
    else if (currentElementTop - scrollPos < 8) {
      menu.current.scrollTop = currentElementTop - 8;
    }
  };

  const focusKeyDown = (e) => {
    if ([37, 38, 39, 40, 13].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }

    if (isOpen) {
      // Enter key
      if (e.keyCode === 13) {
        onSelect(result[selectedListIndex]);
        setIsOpen(false);
      }

      // Up key
      else if (e.keyCode === 38) {
        let newValue = selectedListIndex - 1;

        if (newValue < 0) {
          newValue = result.length - 1;
        }

        setSelectedListIndex(newValue);
        scrollIntoView(newValue);
      }

      // Down key
      else if (e.keyCode === 40) {
        let newValue = selectedListIndex + 1;

        if (newValue > result.length - 1) {
          newValue = 0;
        }

        setSelectedListIndex(newValue);
        scrollIntoView(newValue);
      }
    } else {
      // Enter key
      if (e.keyCode === 13) {
        setIsOpen(true);
      }
    }
  };

  const onFocus = () => {
    // setIsFocused(true);
  };

  const onBlur = () => {
    // setIsFocused(false);
    setIsOpen(false);
  };

  return (
    <>
      <Div pos="relative" zIndex="10" {...rest}>
        <DefaultInput
          placeholder="Search Location"
          h="3rem"
          p="0.75rem 1rem 0.75rem 3rem"
          name="locSearch"
          onChange={(e) => {
            if (e.target.value === "") {
              setIsOpen(false);
              onChange(e);
            } else {
              setIsOpen(true);
              onChange(e);
            }
          }}
          onKeyDown={focusKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          autoComplete="off"
        />
        <Icon
          name="Search"
          color="medium"
          size="20px"
          pos="absolute"
          top="50%"
          left="1rem"
          transform="translateY(-50%)"
          style={{ pointerEvents: "none" }}
        />
        <Transition in={isOpen} timeout={duration} unmountOnExit>
          {(state) => (
            <Div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
              bg="white"
              pos="absolute"
              top="calc(100% + 1px)"
              left="0"
              right="0"
              p="0.5rem"
              shadow="3"
              rounded="lg"
              border="1px solid"
              borderColor="o-light"
              maxH="220px"
              overflow="scroll"
              ref={menu}
            >
              {result.map((item, index) => {
                const isSelected = index === selectedListIndex;
                return (
                  <Div
                    ref={(el) => (menuItemRef.current[index] = el)}
                    bg={isSelected && "gray200"}
                    textColor={isSelected ? "black" : "medium"}
                    textWeight="500"
                    rounded="lg"
                    p="0.5rem 1rem"
                    cursor="pointer"
                    onClick={() => onSelect(item)}
                    onMouseEnter={() => setSelectedListIndex(index)}
                    key={index}
                  >
                    {item.place_name}
                  </Div>
                );
              })}
            </Div>
          )}
        </Transition>
      </Div>
    </>
  );
}
