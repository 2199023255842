import React from "react";
import { Div, Text, Icon, Button } from "atomize";
// import StripeCheckoutButton from "../payment/StripeCheckoutButton";

export default function PricingList({
  theme,
  listKey,
  setCurrentPlan,
  close,
  plans,
  currentPlan,
  listing = {},
}) {
  return (
    <>
      <Div bg={theme} h="4px"></Div>
      <Text
        p="2.5rem 1rem 1.5rem"
        textSize="title"
        textWeight="600"
        textAlign="center"
        textColor={theme}
        border={{ b: "1px solid" }}
        borderColor="o-light"
        textTransform="capitalize"
      >
        {listKey}
      </Text>
      <Div
        p="11.25px 1rem"
        d="flex"
        align="center"
        justify="center"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Icon
          name={plans[listKey].vehicleListing ? "Checked" : "Checked"}
          color={plans[listKey].vehicleListing ? theme : theme}
        />
      </Div>
      <Div
        p="11.25px 1rem"
        d="flex"
        align="center"
        justify="center"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Text textWeight="500" textColor="medium">
          {plans[listKey].imageLimit ? plans[listKey].imageLimit : "Unlimited"}
        </Text>
      </Div>
      {/* <Div
        p="11.25px 1rem"
        d="flex"
        align="center"
        justify="center"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Icon
          name={plans[listKey].professionalPhotography ? "Checked" : "Cross"}
          color={plans[listKey].professionalPhotography ? theme : "gray600"}
        />
      </Div> */}
      <Div
        p="11.25px 1rem"
        d="flex"
        align="center"
        justify="center"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Icon
          name={plans[listKey].homepageFeature ? "Checked" : "Cross"}
          color={plans[listKey].homepageFeature ? theme : "gray600"}
        />
      </Div>
      <Div
        p="11.25px 1rem"
        d="flex"
        align="center"
        justify="center"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Icon
          name={plans[listKey].socialMediaPost ? "Checked" : "Cross"}
          color={plans[listKey].socialMediaPost ? theme : "gray600"}
        />
      </Div>
      <Div p="2rem 2rem 3.5rem">
        {listing.payment && listing.plan === "basic" && (
          <Text
            textAlign="center"
            textColor="black400"
            textWeight="500"
            textSize="caption"
            m={{ b: "0.25rem" }}
          >
            {listing.payment && listing.plan === "basic" && listKey === "basic"
              ? "Current package"
              : listing.payment &&
                listing.plan === "basic" &&
                listKey === "advanced"
              ? "For additional"
              : ""}
          </Text>
        )}
        <Text
          textAlign="center"
          textColor="light"
          textWeight="500"
          // m={{ b: "0.5rem" }}
          m={{ b: "1.5rem" }}
        >
          <Text tag="span" textColor="dark" textSize="title" textWeight="600">
            $
            {listing.payment &&
            listing.plan === "basic" &&
            listKey === "advanced"
              ? plans["advanced"].price - plans["basic"].price
              : plans[listKey].price}
          </Text>{" "}
          /per vehicle
        </Text>
        {!listing.payment && (
          <Text
            textSize="caption"
            textWeight="500"
            textAlign="center"
            textColor="medium"
            m={{ b: "1.75rem" }}
          >
            One-time payment
          </Text>
        )}
        <Button
          bg={theme}
          textWeight="600"
          w="100%"
          rounded="circle"
          shadow="1"
          h="3rem"
          onClick={() => {
            setCurrentPlan(listKey);
          }}
        >
          {currentPlan === listKey ? (
            <>
              <Icon
                pos="absolute"
                left="1rem"
                top="50%"
                transform="translateY(-50%)"
                name="Success"
                size="18px"
                color="white"
                m={{ r: "0.5rem" }}
              />
              Selected
            </>
          ) : (
            "Choose Package"
          )}
        </Button>
      </Div>
    </>
  );
}
