import React, { useContext, useEffect, useState } from "react";
import { Div, Text, Icon } from "atomize";
import Signup from "../pages/SignUp";
import { axiosInstance } from "../utilities/axiosInstance";
import { toast } from "react-toastify";
import { getErrorMessage } from "../utilities/helpers";
import { AuthContext } from "../contexts/auth";

const Invite = ({ location }) => {
  const { loginUser } = useContext(AuthContext);
  const [display, setdisplay] = useState("loading");
  const [token, setToken] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
    const action = urlParams.get("action");
    const isAccountExist = urlParams.get("account");

    if (token && action === "invite" && isAccountExist) {
      setToken(token);
      setdisplay("signing");
    }

    if (token && action === "invite" && !isAccountExist) {
      setToken(token);
      setdisplay("signup-form");
    }
  }, []);

  useEffect(() => {
    if (display === "signing") {
      (async () => {
        try {
          const response = await axiosInstance.post(
            "/admin/update-role-signin",
            { token }
          );
          if (response.data.success) {
            const appState = {
              token: response.data.meta.token,
              user: response.data.data,
            };
            loginUser(appState);
            toast.success("Signup success");
          }
        } catch (error) {
          toast.error(getErrorMessage(error));
        }
      })();
    }
  }, [display]);

  if (display === "loading") {
    return (
      <Div
        p={{ y: "4rem" }}
        minH="100vh"
        d="flex"
        align="center"
        justify="center"
        textAlign="center"
      >
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Div>
    );
  }

  if (display === "signup-form") {
    return <Signup token={token} />;
  }

  if (display === "signing") {
    return (
      <Div
        p={{ y: "4rem" }}
        minH="100vh"
        d="flex"
        align="center"
        justify="center"
        textAlign="center"
      >
        <Text textColor="medium" textWeight="500" textSize="subheader">
          Signing you in
        </Text>
        <Icon m={{ l: "1rem" }} name="Loading2" color="brand600" />
      </Div>
    );
  }
};

export default Invite;
