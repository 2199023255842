import React from "react";
import { Div, Row, Col } from "atomize";

import CustomContainer from "../components/atoms/CustomContainer";
import Root from "../components/common/Root";
import ProfileSidebar from "../components/account/ProfileSidebar";
import TeamMembersMain from "../components/account/TeamMembersMain";

export default function AccountSettings() {
  return (
    <Root>
      <ProfileSidebar />
      {/* <ProfileTopbar /> */}
      <Div p={{ t: { xs: "7.5rem", lg: "7.5rem" }, b: "18.5rem" }}>
        <CustomContainer>
          <Row>
            <Col
              size={{
                xs: "16",
                lg: "10",
                xl: "8",
              }}
              offset={{ lg: "5", xl: "5" }}
            >
              <TeamMembersMain />
            </Col>
          </Row>
        </CustomContainer>
      </Div>
    </Root>
  );
}
