import React, { useContext, useState } from "react";
import { Div, Text, Button, Icon } from "atomize";
import ListingFooter from "./ListingFooter";
import ListingBilling from "./ListingBilling";
import { axiosInstance } from "../../utilities/axiosInstance";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../utilities/helpers";
import { AuthContext } from "../../contexts/auth";

const Payment = ({
  plans,
  currentStep,
  setCurrentStep,
  listing,
  updateListing,
  setCurrentPlan,
  currentPlan,
  showPricingModal,
  setShowPricingModal,
  changeListingData,
}) => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const makePayment = async () => {
    if (user.role !== "ADMIN" && user.role !== "EDITOR") {
      return;
    }
    setLoading(true);
    try {
      await axiosInstance.post("/admin/make-payment", {
        listingId: listing.id,
      });
      changeListingData({
        data: { payment: true },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(getErrorMessage(error));
    }
  };

  const undoPayment = async () => {
    if (user.role !== "ADMIN" && user.role !== "EDITOR") {
      return;
    }
    setLoading(true);
    try {
      await axiosInstance.post("/admin/undo-payment", {
        listingId: listing.id,
      });
      changeListingData({
        data: { payment: false },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(getErrorMessage(error));
    }
  };

  return (
    <Div>
      <Text textWeight="500" textColor="light" m={{ b: "0.5rem" }}>
        Step {currentStep} of 5
      </Text>
      <Text
        textColor="text-dark"
        textSize="display2"
        textWeight="600"
        m={{ b: "2.5rem" }}
      >
        Payment
      </Text>

      <ListingBilling
        plans={plans}
        listing={listing}
        setCurrentPlan={setCurrentPlan}
        currentPlan={currentPlan}
        showPricingModal={showPricingModal}
        setShowPricingModal={setShowPricingModal}
        setStepCount={setCurrentStep}
        updateListing={updateListing}
        isNotEditable={true}
      />

      <ListingFooter
        back={() => setCurrentStep(currentStep - 1)}
        currentStep={currentStep}
        nextReplacer={
          <Button
            type="submit"
            bg={!listing.payment ? "brand700" : "danger600"}
            minW="7rem"
            h="3rem"
            p="0 1.5rem"
            textWeight="600"
            id="next_btn"
            onClick={!listing.payment ? makePayment : undoPayment}
            disabled={user.role !== "ADMIN" && user.role !== "EDITOR"}
          >
            {loading ? (
              <Icon name="Loading2" color="white" />
            ) : !listing.payment ? (
              "Make payment"
            ) : (
              "Undo payment"
            )}
          </Button>
        }
      />
    </Div>
  );
};

export default Payment;
