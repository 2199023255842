import React, { useContext } from "react";
import { Div, Text, Row, Col } from "atomize";
import { capitalizeFirstLetter } from "../../utilities/helpers";
import Axios from "axios";

import DefaultInput from "../atoms/DefaultInput";
import DefaultTextarea from "../atoms/DefaultTextarea";
import Dropdown from "../atoms/Dropdown";
import MapDisplay from "../map/MapDisplay";
import InputAutoComplete from "../atoms/InputAutoComplete";
import ListingFooter from "./ListingFooter";
import { AuthContext } from "../../contexts/auth";

export default function VehicleDetailForm({
  currentStep,
  setCurrentStep,
  listing,
  handleChange,
  changeListingData,
  onSelectChange,
  searchResults,
  searchText,
  searchLocation,
  fetchCarModels,
  fetchCarTrims,
  updateLocationFromSearch,
  updateLocationFromMap,
  onSubmit,
}) {
  const { user } = useContext(AuthContext);
  const {
    makes,
    bodyTypes,
    transmissionTypes,
    fuelTypes,
    colors,
    data,
    models,
    trims,
  } = listing;

  const {
    vin,
    make,
    model,
    manufacturingYear,
    fuelType,
    trim,
    bodyType,
    transmission,
    maxPassengers,
    color,
    description,
    // address,
    latitude,
    longitude,
  } = data;

  const fetchDetailsByVin = async (vin) => {
    if (!String(vin).trim()) return;
    try {
      const response = await Axios.get(
        "https://specifications.vinaudit.com/v3/specifications",
        {
          params: {
            key: "FYOQZFO92LTT7XQ",
            format: "json",
            include: "attributes,colors",
            vin,
          },
        }
      );

      if (response.data.success) {
        const {
          make,
          model,
          year: manufacturingYear,
          trim,
          transmission_type: transmission,
          fuel_type,
        } = response.data.attributes;

        if (make) {
          fetchCarModels(make);
        }
        if (model) {
          fetchCarTrims(model, make);
        }

        const fuelTypes = [
          "GASOLINE",
          "DIESEL",
          "ELECTRIC",
          "CNG",
          "HYBRID",
          "OTHER",
        ];
        const colors = response.data.colors
          .filter((color) => color.category === "Exterior")
          .map((color) => color.name);
        console.log("BEFORE CHNAGE");
        changeListingData({
          colors: colors.length ? colors : [],
          data: {
            make,
            model,
            manufacturingYear,
            transmission: transmission.toUpperCase(),
            trim,
            fuelType: fuelTypes.includes(fuel_type.toUpperCase())
              ? fuel_type.toUpperCase()
              : "OTHER",
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.role !== "ADMIN" && user.role !== "EDITOR") {
      return setCurrentStep(currentStep + 1);
    }
    onSubmit(e);
  };

  return (
    <Div>
      <Text textWeight="500" textColor="light" m={{ b: "0.5rem" }}>
        Step {currentStep} of 5
      </Text>
      <Text
        textColor="text-dark"
        textSize="display2"
        textWeight="600"
        m={{ b: "2.5rem" }}
      >
        Vehicle details
      </Text>
      <form onSubmit={handleSubmit} id="updateVehicleDetails">
        <Row>
          <Col size="8">
            <DefaultInput
              label="VIN number"
              name="vin"
              placeholder=""
              h="3rem"
              m={{ b: "1.5rem" }}
              onChange={handleChange}
              value={vin}
              onBlur={(e) => fetchDetailsByVin(e.target.value)}
            />
          </Col>
          <Col size="8">
            {makes && (
              <Dropdown
                isSearchable={true}
                inputPlaceholder="Select a make"
                label="Make*"
                list={[
                  { key: null, text: "--" },
                  ...makes?.map((make) => ({
                    key: make.name,
                    text: make.name,
                  })),
                ]}
                name="make"
                onChange={(value) => onSelectChange("make", value.key)}
                h="3rem"
                m={{ b: "1.5rem" }}
                initialValue={make}
              />
            )}
          </Col>
          <Col size="8">
            {models && (
              <Dropdown
                isSearchable={true}
                inputPlaceholder="Select a model"
                label="Model*"
                list={[
                  { key: null, text: "--" },
                  ...models.map((model) => ({
                    key: model.name,
                    text: model.name,
                  })),
                ]}
                onChange={(value) => onSelectChange("model", value.key)}
                h="3rem"
                m={{ b: "1.5rem" }}
                initialValue={model}
              />
            )}
          </Col>
          <Col size="8">
            {trims && (
              <Dropdown
                isSearchable={true}
                inputPlaceholder="Select a trim"
                label="Trim"
                list={[
                  { key: null, text: "--" },
                  ...trims?.map((trim) => ({
                    key: trim.name,
                    text: trim.name,
                  })),
                ]}
                onChange={(value) => onSelectChange("trim", value.key)}
                h="3rem"
                dropdownSize="lg"
                m={{ b: "1.5rem" }}
                initialValue={trim}
              />
            )}
          </Col>
          <Col size="8">
            <DefaultInput
              label="Manufacturing year*"
              type="number"
              name="manufacturingYear"
              placeholder=""
              h="3rem"
              m={{ b: "1.5rem" }}
              onChange={handleChange}
              value={manufacturingYear}
              min="0"
              max={`${new Date().getFullYear()}`}
            />
          </Col>
          <Col size="8">
            {fuelTypes && (
              <Dropdown
                label="Fuel type*"
                list={[
                  { key: null, text: "--" },
                  ...fuelTypes.map((fuelType) => ({
                    key: fuelType,
                    text: capitalizeFirstLetter(fuelType),
                  })),
                ]}
                onChange={(value) => onSelectChange("fuelType", value.key)}
                h="3rem"
                m={{ b: "1.5rem" }}
                initialValue={fuelType}
              />
            )}
          </Col>
          <Col size="8">
            {bodyTypes && (
              <Dropdown
                label="Body type*"
                list={[
                  { key: null, text: "--" },
                  ...bodyTypes.map((bodyType) => ({
                    key: bodyType,
                    text: capitalizeFirstLetter(bodyType),
                  })),
                ]}
                onChange={(value) => onSelectChange("bodyType", value.key)}
                h="3rem"
                m={{ b: "1.5rem" }}
                initialValue={bodyType}
              />
            )}
          </Col>
          <Col size="8">
            {transmissionTypes && (
              <Dropdown
                label="Transmission*"
                list={[
                  { key: null, text: "--" },
                  ...transmissionTypes.map((trsType) => ({
                    key: trsType,
                    text: capitalizeFirstLetter(trsType),
                  })),
                ]}
                onChange={(value) => onSelectChange("transmission", value.key)}
                h="3rem"
                m={{ b: "1.5rem" }}
                initialValue={transmission}
              />
            )}
          </Col>
          <Col size="8">
            <Dropdown
              label="Max passengers*"
              list={[
                { key: null, text: "--" },
                { key: "2", text: "2" },
                { key: "4", text: "4" },
                { key: "5", text: "5" },
                { key: "6", text: "6" },
                { key: "7", text: "7" },
                { key: ">7", text: ">7" },
              ]}
              onChange={(value) => onSelectChange("maxPassengers", value.key)}
              h="3rem"
              m={{ b: "1.5rem" }}
              initialValue={maxPassengers}
            />
          </Col>
          <Col size="8">
            {colors && (
              <Dropdown
                isSearchable={true}
                inputPlaceholder="Select a color"
                label="Color*"
                list={[
                  { key: null, text: "--" },
                  ...colors.map((color) => ({
                    key: capitalizeFirstLetter(color),
                    text: capitalizeFirstLetter(color),
                  })),
                ]}
                onChange={(value) => onSelectChange("color", value.key)}
                h="3rem"
                m={{ b: "1.5rem" }}
                initialValue={color}
              />
            )}
          </Col>
          <Col size="16">
            <DefaultTextarea
              label="Vehicle description (500 characters)"
              name="description"
              m={{ b: "1.5rem" }}
              onChange={handleChange}
              value={description}
              maxlength="500"
            />
          </Col>
          <Col size="16">
            <Text
              textSize="caption"
              textColor="text-light"
              textWeight="500"
              m={{ b: "0.25rem" }}
            >
              Vehicle location
            </Text>
            <InputAutoComplete
              list={searchResults}
              result={searchResults}
              placeholder="Search location"
              m={{ b: "1rem" }}
              value={searchText}
              onChange={searchLocation}
              onSelect={updateLocationFromSearch}
            />
            <MapDisplay
              latitude={latitude}
              longitude={longitude}
              updateLocationFromMap={updateLocationFromMap}
            />
          </Col>
        </Row>
        <ListingFooter
          isEditing={listing.isEditing}
          currentStep={currentStep}
        />
      </form>
    </Div>
  );
}
