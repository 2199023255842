import React, { useContext } from "react";
import { Div, Text, Icon, Button } from "atomize";
import { PlanPriceContext } from "../../contexts/PlansPriceProvider";

export default function PricingListFull({
  theme,
  listKey,
  setCurrentPlan,
  close,
  plans,
  currentPlan,
}) {
  const { features } = useContext(PlanPriceContext);
  return (
    <>
      <Div bg={theme} h="4px"></Div>
      <Text
        p="2.5rem 1rem 1.5rem"
        textSize="title"
        textWeight="600"
        textAlign="center"
        textColor={theme}
        border={{ b: "1px solid" }}
        borderColor="o-light"
        textTransform="capitalize"
      >
        {listKey}
      </Text>
      {Object.keys(features).map((key) => (
        <Div
          p="0.75rem 1.5rem"
          d="flex"
          align="center"
          border={{ b: "1px solid" }}
          borderColor="o-light"
        >
          {key === "imageLimit" ? (
            <>
              <Icon
                name="Checked"
                color={theme || "gray600"}
                m={{ r: "1rem" }}
              />
              {features[key].name} ({" "}
              {plans[listKey][key]
                ? `Upto ${plans[listKey].imageLimit}`
                : "Unlimited"}{" "}
              )
            </>
          ) : (
            <>
              <Icon
                name={plans[listKey][key] ? "Checked" : "Cross"}
                color={theme || "gray600"}
                m={{ r: "1rem" }}
              />
              {features[key].name}
            </>
          )}
        </Div>
      ))}
      {/* <Div
        p="0.75rem 1.5rem"
        d="flex"
        align="center"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Icon name="Checked" color={theme || "gray600"} m={{ r: "1rem" }} />
        Image upload ({" "}
        {plans[listKey].imageLimit
          ? `Upto ${plans[listKey].imageLimit}`
          : "Unlimited"}{" "}
        )
      </Div>
      <Div
        p="0.75rem 1.5rem"
        d="flex"
        align="center"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Icon
          name={plans[listKey].professionalPhotography ? "Checked" : "Cross"}
          color={plans[listKey].professionalPhotography ? theme : "gray600"}
          m={{ r: "1rem" }}
        />
        Professional photography
      </Div>
      <Div
        p="0.75rem 1.5rem"
        d="flex"
        align="center"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Icon
          name={plans[listKey].homepageFeature ? "Checked" : "Cross"}
          color={plans[listKey].homepageFeature ? theme : "gray600"}
          m={{ r: "1rem" }}
        />
        Homepage featured visibility
      </Div>
      <Div
        p="0.75rem 1.5rem"
        d="flex"
        align="center"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Icon
          name={plans[listKey].socialMediaPost ? "Checked" : "Cross"}
          color={plans[listKey].socialMediaPost ? theme : "gray600"}
          m={{ r: "1rem" }}
        />
        Social Media Post
      </Div> */}
      <Div p="2rem 2rem 3.5rem">
        <Text
          textAlign="center"
          textColor="light"
          textWeight="500"
          m={{ b: "0.5rem" }}
        >
          <Text tag="span" textColor="dark" textSize="title" textWeight="600">
            ${plans[listKey].price}
          </Text>{" "}
          /per vehicle
        </Text>
        <Text
          textSize="caption"
          textWeight="500"
          textAlign="center"
          textColor="medium"
          m={{ b: "1.75rem" }}
        >
          One-time payment
        </Text>
        <Button
          bg={theme}
          textWeight="600"
          w="100%"
          rounded="circle"
          shadow="1"
          h="3rem"
          onClick={() => {
            setCurrentPlan(listKey);
            close && close();
          }}
        >
          {currentPlan === listKey ? (
            <>
              <Icon
                pos="absolute"
                left="1rem"
                top="50%"
                transform="translateY(-50%)"
                name="Success"
                size="18px"
                color="white"
                m={{ r: "0.5rem" }}
              />
              Selected
            </>
          ) : (
            "Choose Package"
          )}
        </Button>
      </Div>
    </>
  );
}
