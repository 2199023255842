import React from "react";
import { Div, Text } from "atomize";
import { Transition } from "react-transition-group";

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function AditionalIncentiveTooltip({ isOpen }) {
  return (
    <Transition in={isOpen} timeout={duration} unmountOnExit>
      {(state) => (
        <Div
          rounded="lg"
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          shadow="4"
          zIndex="5"
          pos="absolute"
          w="200px"
          left="1.75rem"
          top="-1rem"
          bg="black800"
          p="0.75rem 1rem 1.25rem"
        >
          <svg
            width="8"
            height="15"
            style={{
              position: "absolute",
              top: "16px",
              left: "-8px",
              width: "8px",
              height: "15px",
            }}
            viewBox="0 0 8 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 15L1.55635 8.95908C0.713523 8.16893 0.713523 6.83107 1.55635 6.04093L8 0V15Z"
              fill="#14284B"
            />
          </svg>
          <Text textSize="caption" textColor="light" textWeight="500">
            An additional cash offer to be paid upon successful vehicle
            transfer.
          </Text>
        </Div>
      )}
    </Transition>
  );
}
