import React from "react";
import { Div, Icon, Text } from "atomize";
import { Link, useRouteMatch } from "react-router-dom";

const profileOptions = [
  {
    name: "Edit profile",
    icon: "EditSolid",
    link: "/profile/edit-profile",
    id: "editProfile",
  },
  {
    name: "Settings",
    icon: "SettingsSolid",
    link: "/profile/account-settings",
    id: "accountSettings",
  },
];

export default function ProfileTopbar({ step }) {
  let { url } = useRouteMatch();

  return (
    <Div p={{ l: "1.5rem" }} d={{ xs: "flex", lg: "none" }} m={{ t: "8rem" }}>
      <Div
        d="flex"
        border={{ b: "1px solid" }}
        borderColor="gray300"
        minW="calc(100vw - 3rem)"
      >
        {profileOptions.map((option) => (
          <Link key={option.name} href={option.link}>
            <Div
              key={option.name}
              d="flex"
              align="center"
              p={{ b: "0.75rem", r: "0.5rem" }}
              m={{ r: "1rem" }}
              style={{ whiteSpace: "nowrap" }}
              pos="relative"
            >
              <Icon
                m={{ r: "12px" }}
                name={option.icon}
                color={url === option.link ? "dark" : "light"}
                size="20px"
              ></Icon>
              <Text
                textSize="paragraph"
                textWeight="600"
                textColor={url === option.link ? "dark" : "light"}
              >
                {option.name}
              </Text>
              {url === option.link && (
                <Div
                  pos="absolute"
                  bottom="0"
                  left="0"
                  right="0"
                  h="2px"
                  bg="dark"
                ></Div>
              )}
            </Div>
          </Link>
        ))}
      </Div>
    </Div>
  );
}
