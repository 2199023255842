import React from "react";
import { Div, Icon, Text } from "atomize";
import { Transition } from "react-transition-group";
import { useHistory } from "react-router-dom";
// import { UnreadMsgsContext } from "../../../contexts/UnreadMessagesProvider";

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function ProfileDropdown({ user, logoutUser }) {
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedListIndex, setSelectedListIndex] = React.useState(0);
  //   const { msgCount } = useContext(UnreadMsgsContext);

  const dropdownMenuList = [
    {
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3333 6.66659C13.3333 8.49992 11.8333 9.99992 10 9.99992C8.16667 9.99992 6.66667 8.49992 6.66667 6.66659C6.66667 4.83325 8.16667 3.33325 10 3.33325C11.8333 3.33325 13.3333 4.83325 13.3333 6.66659Z"
            fill="#8A94A6"
          />
          <path
            d="M10 11.6666C14.5956 11.6666 17.5 13.5624 17.5 17.4999H2.5C2.5 13.5624 5.40444 11.6666 10 11.6666Z"
            fill="#8A94A6"
          />
        </svg>
      ),
      text: "Team Members",
      onClick: () => {
        history.push("/profile/team-members");
      },
      hide: user.role !== "ADMIN",
    },
    // {
    //   icon: (
    //     <svg
    //       width="20"
    //       height="20"
    //       viewBox="0 0 20 20"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M16.375 4.16675C16.9963 4.16675 17.5 4.65644 17.5 5.2605V14.7397C17.5 15.3437 16.9963 15.8334 16.375 15.8334H3.625C3.00368 15.8334 2.5 15.3437 2.5 14.7397V5.2605C2.5 4.65644 3.00368 4.16675 3.625 4.16675H16.375ZM15.6934 7.03783C15.4381 6.65489 14.9207 6.55141 14.5377 6.80671L10 9.83187L5.46225 6.80671C5.07931 6.55141 4.56192 6.65489 4.30662 7.03783C4.05133 7.42077 4.15481 7.93816 4.53775 8.19346L9.53775 11.5268C9.81767 11.7134 10.1823 11.7134 10.4622 11.5268L15.4623 8.19346C15.8452 7.93816 15.9487 7.42077 15.6934 7.03783Z"
    //         fill="#8A94A6"
    //       />
    //     </svg>
    //   ),
    //   text: "Messages",
    //   onClick: () => {
    //     history.push("/messages");
    //   },
    // },
    {
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#8A94A6"
            fill-rule="evenodd"
            d="M9.77777778,2 L14.2222222,2 L14.8148148,4.90865385 L16.8641975,6.08653846 L19.7777778,5.125 L22,8.875 L19.7530864,10.7980769 L19.7530864,13.2019231 L22,15.125 L19.7777778,18.875 L16.8641975,17.9134615 L14.8148148,19.0913462 L14.2222222,22 L9.77777778,22 L9.18518519,19.0913462 L7.13580247,17.9134615 L4.22222222,18.875 L2,15.125 L4.24691358,13.2019231 L4.24691358,10.7980769 L2,8.875 L4.22222222,5.125 L7.13580247,6.08653846 L9.18518519,4.90865385 L9.77777778,2 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
          />
        </svg>
      ),
      text: "Account",
      onClick: () => {
        history.push("/profile/edit-profile");
      },
    },
    {
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.50065 9.16658H15.4888L12.7447 6.42251C12.4193 6.09707 12.4193 5.56943 12.7447 5.244C13.0702 4.91856 13.5978 4.91856 13.9232 5.244L18.0893 9.41001L18.0953 9.41613C18.1725 9.49475 18.231 9.58488 18.2708 9.68093C18.3115 9.77919 18.334 9.88693 18.334 9.99992C18.334 10.2273 18.2429 10.4334 18.0953 10.5837L18.0893 10.5898L13.9232 14.7558C13.5978 15.0813 13.0702 15.0813 12.7447 14.7558C12.4193 14.4304 12.4193 13.9028 12.7447 13.5773L15.4888 10.8333H7.50065C7.04041 10.8333 6.66732 10.4602 6.66732 9.99992C6.66732 9.53968 7.04041 9.16658 7.50065 9.16658Z"
            fill="#8A94A6"
          />
          <path
            d="M7.50065 15.8333C7.50065 15.373 7.12756 14.9999 6.66732 14.9999H3.75065C3.52053 14.9999 3.33398 14.8134 3.33398 14.5833V5.41658C3.33398 5.18647 3.52053 4.99992 3.75065 4.99992H6.66732C7.12756 4.99992 7.50065 4.62682 7.50065 4.16659C7.50065 3.70635 7.12756 3.33325 6.66732 3.33325H3.75065C2.60006 3.33325 1.66732 4.26599 1.66732 5.41658V14.5833C1.66732 15.7338 2.60006 16.6666 3.75065 16.6666H6.66732C7.12756 16.6666 7.50065 16.2935 7.50065 15.8333Z"
            fill="#8A94A6"
          />
        </svg>
      ),
      text: "Logout",
      onClick: () => {
        logoutUser();
      },
    },
  ];

  const focusKeyDown = (e) => {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }

    if (isOpen) {
      // Enter key
      if (e.keyCode === 13) {
        // go to link
        dropdownMenuList[selectedListIndex].onClick();
      }

      // Up key
      else if (e.keyCode === 38) {
        let newValue = selectedListIndex - 1;

        if (newValue < 0) {
          newValue = dropdownMenuList.length - 1;
        }

        setSelectedListIndex(newValue);
      }

      // Down key
      else if (e.keyCode === 40) {
        let newValue = selectedListIndex + 1;

        if (newValue > dropdownMenuList.length - 1) {
          newValue = 0;
        }

        setSelectedListIndex(newValue);
      }
    } else {
      // Enter key
      if (e.keyCode === 13) {
        setIsOpen(true);
      }
    }
  };

  return (
    <Div pos="relative">
      <Div
        tabIndex="0"
        p={{ l: "1.5rem" }}
        className="profile-dropdown"
        d="flex"
        h="4rem"
        align="center"
        cursor="pointer"
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={focusKeyDown}
        onBlur={() => setIsOpen(false)}
      >
        <Div
          w="1.5rem"
          h="1.5rem"
          shadow="2"
          rounded="circle"
          bgImg={user.avatarUrl || "/images/users/default-img.svg"}
          bgSize="cover"
          bgPos="center"
          className="profile-dropdown--image"
          m={{ r: "0.5rem" }}
        />
        <Text
          className="header-link"
          d={{ xs: "none", md: "block" }}
          m="0 0.5rem"
          textWeight="500"
          w="96px"
          overflow="hidden"
          style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}
        >
          {user.fullName}
        </Text>
        <Icon
          className="header-icon"
          name={isOpen ? "UpArrow" : "DownArrow"}
          color="text-medium"
          size="20px"
        />
      </Div>
      <Transition in={isOpen} timeout={duration} unmountOnExit>
        {(state) => (
          <Div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            minW="15rem"
            overflow="hidden"
            pos="absolute"
            right="0"
            top="100%"
            bg="white"
            rounded="lg"
            border="1px solid"
            borderColor="o-light"
            shadow="4"
          >
            <Div p="0.5rem" border={{ b: "1px solid" }} borderColor="o-light">
              {dropdownMenuList
                .slice(0, dropdownMenuList.length - 1)
                .filter((item) => !item.hide)
                .map((item, index) => {
                  const isSelected = selectedListIndex === index;
                  return (
                    <Div
                      key={`dropdown-menu-list-${index}`}
                      onMouseEnter={() => setSelectedListIndex(index)}
                      cursor="pointer"
                      h="2.5rem"
                      rounded="md"
                      bg={isSelected ? "gray200" : "white"}
                      d="flex"
                      p="0.5rem 1rem"
                      textWeight="600"
                      className={`profile-dropdown--list ${
                        isSelected && "active"
                      }`}
                      align="center"
                      onClick={item.onClick}
                      pos="relative"
                    >
                      {item.icon}
                      <Text m={{ l: "0.75rem" }}>{item.text}</Text>
                      {/* {item.text === "Messages" && !!msgCount && (
                        <Div
                          pos="absolute"
                          bg={isSelected ? "danger500" : "danger400"}
                          h="10px"
                          w="10px"
                          rounded="circle"
                          left="29px"
                          top="9px"
                        />
                      )} */}
                    </Div>
                  );
                })}
            </Div>
            <Div
              d="flex"
              bg={
                dropdownMenuList.length - 1 === selectedListIndex && "gray200"
              }
              p="0.75rem 1.5rem 1.25rem"
              className={`profile-dropdown--list ${
                dropdownMenuList.length - 1 === selectedListIndex && "active"
              }`}
              textWeight="600"
              align="center"
              justify="space-between"
              onMouseEnter={() =>
                setSelectedListIndex(dropdownMenuList.length - 1)
              }
              cursor="pointer"
              onClick={dropdownMenuList[dropdownMenuList.length - 1].onClick}
            >
              <Text textColor="danger700">
                {dropdownMenuList[dropdownMenuList.length - 1].text}
              </Text>
              {dropdownMenuList[dropdownMenuList.length - 1].icon}
            </Div>
          </Div>
        )}
      </Transition>
    </Div>
  );
}
