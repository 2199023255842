import React from "react";
import { Div, Text } from "atomize";

export default function NoResultFound() {
  return (
    <Div
      d="flex"
      justify="center"
      align="center"
      flexDir="column"
      p={{ y: "100px" }}
      borderColor="#F1F2F4"
      // border={{ t: "1px solid" }}
    >
      <Div
        h="10rem"
        w="10rem"
        bgImg="/images/illustrations/no-result.png"
        bgSize="cover"
        bgPos="center"
        m={{ b: "2rem" }}
      />
      <Text
        textAlign="center"
        textSize="display1"
        textWeight="600"
        m={{ b: "0.5rem" }}
      >
        No results found
      </Text>
      <Text
        textAlign="center"
        textColor="light"
        textSize="subheader"
        textWeight="500"
        m={{ b: "2.25rem" }}
      >
        Try changing the specifics
      </Text>
    </Div>
  );
}
