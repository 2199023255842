import React, { useEffect } from "react";
import { Text, Div, Icon } from "atomize";
import { Transition } from "react-transition-group";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const checkKey = (e) => {
  //
};

export default function Modal({
  isOpen,
  children,
  close,
  align = "flex-start",
  ...rest
}) {
  const modalRef = React.useRef(null);

  const onEntering = () => {
    window.addEventListener("keydown", checkKey);
    disableBodyScroll(modalRef.current);
  };

  const onExiting = () => {
    window.removeEventListener("keydown", checkKey);
    enableBodyScroll(modalRef.current);
  };

  React.useEffect(() => {
    return () => {
      window.removeEventListener("keydown", checkKey);
      clearAllBodyScrollLocks();
    };
  }, []);

  return (
    <Transition
      in={isOpen}
      onEntering={onEntering}
      onExiting={onExiting}
      timeout={duration}
      unmountOnExit
    >
      {(state) => (
        <>
          <Div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            onClick={() => close && close(false)}
            pos="fixed"
            zIndex="100"
            top="-10px"
            left="-10px"
            right="-10px"
            bottom="-10px"
            bg="rgba(0,0,0, 0.4)"
          />
          <Div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            ref={modalRef}
            onClick={() => close && close(false)}
            tabIndex="-1"
            cursor="pointer"
            pos="fixed"
            zIndex="100"
            top="0"
            left="0"
            right="0"
            bottom="0"
            shadow="2"
            p={{ sm: "3rem 2rem" }}
            overflow="auto"
            d="flex"
            align="center"
            justify="flex-start"
            flexDir="column"
          >
            <Div flexGrow="1" d="flex" align={align} justify="center" w="100%">
              <Div
                cursor="default"
                onClick={(e) => e.stopPropagation()}
                bg="white"
                w="100%"
                maxW="48rem"
                rounded={{ sm: "lg" }}
                minH={{ xs: "100vh", sm: "0" }}
                {...rest}
              >
                {children}
              </Div>
            </Div>
          </Div>
        </>
      )}
    </Transition>
  );
}
