import React, { useContext } from "react";
import { Div, Text } from "atomize";

import PricingList from "./PricingList";
import { PlanPriceContext } from "../../contexts/PlansPriceProvider";

export default function PricingPlan({
  close,
  plans,
  listing,
  currentPlan,
  setCurrentPlan,
}) {
  const { features } = useContext(PlanPriceContext);
  return (
    <Div d="flex">
      <Div flexGrow="1" p={{ l: "2.5rem" }}>
        <Text
          textAlign="center"
          textWeight="600"
          p="2.5rem 1rem 1.85rem"
          border={{ b: "1px solid" }}
          borderColor="o-light"
        >
          What’s included
        </Text>
        {Object.keys(features).map((key) => (
          <Div
            p="0.75rem 0"
            d="flex"
            align="center"
            border={{ b: "1px solid" }}
            borderColor="o-light"
            key={key}
          >
            <Div
              h="4px"
              w="4px"
              bg="disabled"
              m={{ r: "0.75rem" }}
              rounded="lg"
            />
            <Text textWeight="500" textColor="medium">
              {features[key].name}
            </Text>
          </Div>
        ))}
      </Div>
      <Div
        w="14rem"
        m={{ t: "-0.5rem" }}
        shadow="3"
        rounded={{ tl: "8px" }}
        overflow="hidden"
        bg="white"
        pos="relative"
        zIndex="1"
      >
        <PricingList
          close={close}
          theme="pastel6"
          listKey="basic"
          plans={plans}
          currentPlan={currentPlan}
          listing={listing}
          setCurrentPlan={setCurrentPlan}
        />
      </Div>
      <Div
        w="14rem"
        m={{ t: "-0.5rem" }}
        rounded={{ tr: "8px", br: "8px" }}
        overflow="hidden"
        bg="white"
      >
        <PricingList
          close={close}
          theme="brand700"
          listKey="advanced"
          plans={plans}
          currentPlan={currentPlan}
          listing={listing}
          setCurrentPlan={setCurrentPlan}
        />
      </Div>
    </Div>
  );
}
