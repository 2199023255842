import React from "react";
import { Div, Text, Button, Icon } from "atomize";
import Modal from "../common/atoms/Modal";

export default function ListingDeleteModal({
  isOpen,
  close,
  onOkay,
  loading,
  count,
}) {
  return (
    <Modal
      maxW="39rem"
      w="100%"
      isOpen={isOpen}
      close={close}
      align="center"
      rounded={{ sm: "xl" }}
      p="0"
    >
      <Div
        d="flex"
        align="center"
        justify="space-between"
        border={{ b: "1px solid" }}
        borderColor="o-light"
        p="1.25rem 2rem 1rem"
      >
        <Text
          textSize="heading"
          textWeight="500"
          maxW={{ xs: "140px", sm: "100%" }}
          className="truncate-text"
        >
          Delete listings {`(${count})`}
        </Text>
        <Button
          onClick={close}
          type="button"
          h="2.5rem"
          w="2.5rem"
          bg="gray200"
          border="1px solid"
          borderColor="gray300"
          rounded="circle"
          d="flex"
          align="center"
          justify="center"
        >
          <Icon name="Cross" color="black800" />
        </Button>
      </Div>
      <Div p="1.25rem 2rem 2rem">
        <Text textColor="medium" textSize="subheader" m={{ b: "1rem" }}>
          Are you sure you want to delete these listings? You can't revert this.
        </Text>
        <Div d="flex" flexDir={{ xs: "column", sm: "row" }} align="center">
          <Button
            type="submit"
            bg="danger100"
            hoverBg="danger200"
            textColor="danger700"
            border="1px solid"
            borderColor="danger300"
            maxW={{ sm: "10rem" }}
            flexGrow={{ sm: "1" }}
            h="3rem"
            w={{ xs: "100%", sm: "auto" }}
            textWeight="600"
            m={{ xs: "0 0 0.5rem 0", sm: "0 1rem 0 0" }}
            disabled={loading}
            onClick={onOkay}
          >
            <Icon
              name="Delete"
              size="18px"
              m={{ r: "0.5rem" }}
              color="danger700"
            />
            Delete listings
          </Button>
          <Button
            type="button"
            bg="white"
            textColor="text-medium"
            border="1px solid"
            borderColor="o-medium"
            maxW={{ sm: "9rem" }}
            flexGrow={{ sm: "1" }}
            h="3rem"
            shadow="1"
            w={{ xs: "100%", sm: "auto" }}
            textWeight="600"
            m={{ xs: "0 0 0.5rem 0", sm: "0 1rem 0 0" }}
            onClick={close}
          >
            Cancel
          </Button>
        </Div>
      </Div>
    </Modal>
  );
}
