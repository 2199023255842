import React from "react";
import { Div, Text, Icon, Button } from "atomize";
// import StripeCheckoutButton from "../payment/StripeCheckoutButton";
// import { PlanPriceContext } from "../../contexts/PlansPriceProvider";

import Modal from "../atoms/Modal";

export default function MoreImageUploadErrorModal({
  isOpen,
  setCurrentPlan,
  close,
}) {
  return (
    <Modal
      maxW="32rem"
      isOpen={isOpen}
      // close={close}
      align="center"
      rounded={{ sm: "xl" }}
    >
      <Div
        p="1.5rem"
        d="flex"
        justify="space-between"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Div p="0 0 0 0.5rem" d="flex">
          <Div
            d="flex"
            align="center"
            justify="center"
            h="2.5rem"
            w="2.5rem"
            bg="brand200"
            m={{ r: "1rem" }}
            rounded="circle"
          >
            <Icon name="AlertSolid" size="20px" color="brand600" />
          </Div>
          <Text textSize="heading" textColor="black900" textWeight="600">
            Want to add more?
          </Text>
        </Div>
        <Button
          onClick={close}
          h="2.5rem"
          w="2.5rem"
          bg="gray200"
          border="1px solid"
          borderColor="gray300"
          rounded="circle"
          d="flex"
          align="center"
          justify="center"
        >
          <Icon name="Cross" color="black800" />
        </Button>
      </Div>
      <Div p="1rem 2rem 2rem">
        <Text textSize="subheader" textWeight="500" m={{ b: "2rem" }}>
          When you’re on the BASIC PLAN, max 12 images can be uploaded. You can
          update to our Advanced listing to add more images.
        </Text>
        <Div
          d="flex"
          align={{ md: "center" }}
          flexDir={{ xs: "column", md: "row" }}
        >
          <Button
            bg="brand700"
            h="3rem"
            p="0 1.5rem"
            textWeight="600"
            m={{ r: { md: "2rem" } }}
            onClick={() => {
              setCurrentPlan("advanced");
            }}
          >
            Upgrade to Advanced Plan
          </Button>

          <Text
            close={close}
            cursor="pointer"
            textDecor="underline"
            textColor="medium"
            textWeight="600"
            textAlign="center"
            onClick={close}
            m={{ l: { md: "2rem" } }}
          >
            Continue with Basic Plan
          </Text>
        </Div>
      </Div>
    </Modal>
  );
}
