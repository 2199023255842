import React, { useEffect } from "react";
import { Text, Div, Icon, Input } from "atomize";
import { Transition } from "react-transition-group";

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function Dropdown({
  list,
  label,
  placeholder,
  onChange,
  name,
  initialValue,
  error,
  success,
  warning,
  h,
  p,
  bg,
  border,
  borderColor,
  dropdownSize,
  isSearchable,
  inputPlaceholder,
  iconProps,
  menuProps,
  ...rest
}) {
  const menu = React.useRef(null);

  const [value, setValue] = React.useState(initialValue);
  const [inputValue, setInputValue] = React.useState("");
  const [isFocused, setIsFocused] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const initialIndex = list.findIndex(
    (listItem) => listItem.key === initialValue
  );
  const [selectedListIndex, setSelectedListIndex] = React.useState(
    initialIndex === -1 ? 0 : initialIndex
  );

  const scrollIntoView = (index) => {
    if (!menu.current) return;
    const outOfViewMin = 12;
    const outOfViewMax = menu.current.clientHeight - 32;
    const scrollPos = menu.current.scrollTop;
    const currentElementTop = index * 32 + 12;

    const checkViewPos = currentElementTop - scrollPos;

    // If down overflow
    if (checkViewPos > outOfViewMax) {
      menu.current.scrollTop = currentElementTop - outOfViewMax + 12;
    }
    // If up overflow
    else if (checkViewPos < outOfViewMin) {
      menu.current.scrollTop = currentElementTop - 12;
    }
  };

  const focusKeyDown = (e) => {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) {
      e.preventDefault();
    }

    if (isOpen) {
      // Enter key
      if (e.keyCode === 13) {
        setValue(list[selectedListIndex].key);
        onChange && onChange(list[selectedListIndex]);
        setIsOpen(false);
      }

      // Up key
      else if (e.keyCode === 38) {
        let newValue = selectedListIndex - 1;

        if (newValue < 0) {
          newValue = list.length - 1;
        }

        setSelectedListIndex(newValue);
        scrollIntoView(newValue);
      }

      // Down key
      else if (e.keyCode === 40) {
        let newValue = selectedListIndex + 1;

        if (newValue > list.length - 1) {
          newValue = 0;
        }

        setSelectedListIndex(newValue);
        scrollIntoView(newValue);
      }
    } else {
      // Enter key
      if (e.keyCode === 13) {
        setIsOpen(true);
      }
    }
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
    setIsOpen(false);
  };

  const onMenuItemClick = (item, index) => {
    setSelectedListIndex(index);
    setValue(item.key);
    scrollIntoView(index);
    setIsOpen(false);
    onChange && onChange(item);
  };

  const filterItems = (items) => {
    if (!isSearchable) {
      return items;
    }
    return items.filter((item) =>
      item.text.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  };

  useEffect(() => {
    isOpen && scrollIntoView(selectedListIndex);
    const index = list.findIndex((listItem) => listItem.key === initialValue);
    setValue(initialValue);
    setSelectedListIndex(initialIndex !== -1 ? index : 0);
  }, [isOpen, initialValue, list]);

  return (
    <Div {...rest}>
      {label && (
        <Text
          className="truncate-text"
          textSize="caption"
          textColor="light"
          textWeight="500"
          m={{ b: "0.25rem" }}
        >
          {label}
        </Text>
      )}
      <Div
        pos="relative"
        d="flex"
        tabIndex="0"
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={() => !isSearchable && setIsOpen(!isOpen)}
        onKeyDown={focusKeyDown}
        align="center"
        justify="space-between"
        bg={bg || (isSearchable ? "gray200" : "dropdown-gradient")}
        border={border || (isSearchable ? "" : "1px solid")}
        borderColor={borderColor || (isFocused ? "brand300" : "o-light")}
        textSize="body"
        textWeight="500"
        rounded="md"
        p={p || (isSearchable ? "" : "0 1rem")}
        textColor="medium"
        h={h}
      >
        {isSearchable ? (
          <Div
            pos="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            className="edit-searchable-dropown"
          >
            <Input
              h="100%"
              minW="100%"
              cursor="auto"
              p="0.75rem 1rem"
              textSize="paragraph"
              border="1px solid"
              borderColor="o-light"
              focusBorderColor="o-base"
              textColor="text-medium"
              placeholder={inputPlaceholder}
              placeholderTextColor="light"
              bg="transparent"
              rounded="md"
              shadow="inner1"
              transition="background,box-shadow,borderColor 0.4s ease-in-out"
              value={initialValue || ""}
              onChange={(e) => {
                setInputValue(e.target.value);
                onChange({ key: e.target.value });
              }}
              onKeyDown={focusKeyDown}
              onFocus={() => setIsOpen(true)}
              onBlur={() => setIsOpen(false)}
            />
          </Div>
        ) : list.length && list[selectedListIndex] ? (
          list[selectedListIndex].text
        ) : (
          "Select"
        )}

        {!isSearchable && <Icon name="DownArrow" size="20px" color="medium" />}

        {list && list.length > 1 && (
          <Transition in={isOpen} timeout={duration} unmountOnExit>
            {(state) => (
              <Div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                }}
                pos="absolute"
                zIndex="2"
                top="calc(100% + 1px)"
                left="0"
                right="0"
                bg="white"
                rounded="md"
                border="1px solid"
                borderColor="o-light"
                shadow="3"
                p="0.75rem"
                minW="fit-content"
                maxH={dropdownSize === "lg" ? "23rem" : "11.5rem"}
                overflow="auto"
                className="dropdown--menu"
                {...menuProps}
                ref={menu}
              >
                {list &&
                  list.map((item, index) => (
                    <Div
                      key={`${item.key}-${index}`}
                      onClick={() => onMenuItemClick(item, index)}
                      p="0.5rem 0.75rem"
                      rounded="lg"
                      h="2rem"
                      bg={selectedListIndex === index ? "brand100" : "white"}
                      textColor={
                        selectedListIndex === index ? "dark" : "medium"
                      }
                      hoverTextColor="dark"
                      textWeight="500"
                      cursor="pointer"
                      transition
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {item.text}
                    </Div>
                  ))}
              </Div>
            )}
          </Transition>
        )}
      </Div>
      {error && (
        <Text m={{ t: "0.25rem" }} textWeight="500" textColor="danger600">
          {error}
        </Text>
      )}
      {warning && (
        <Text m={{ t: "0.25rem" }} textWeight="500" textColor="warning700">
          {warning}
        </Text>
      )}
      {success && (
        <Text m={{ t: "0.25rem" }} textWeight="500" textColor="success700">
          {success}
        </Text>
      )}
    </Div>
  );
}
