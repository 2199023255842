import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Row, Col, Div } from "atomize";
import { axiosInstance } from "../utilities/axiosInstance";
import {
  getErrorMessage,
  formatDate,
  getAddressFromGeocode,
  getStateFromLocResult,
  getFormatedData,
  isAllFieldsFilled,
  calculateMonthsRemaining,
  capitalizeFirstLetter,
} from "../utilities/helpers";
import { PlanPriceContext } from "../contexts/PlansPriceProvider";

import CustomContainer from "../components/atoms/CustomContainer";
import Root from "../components/common/Root";
import Sidebar from "../components/edit/Sidebar";
import LoadingScreen from "../components/common/LoadingScreen";
import VehicleDetailForm from "../components/edit/VehicleDetailForm";
import ListingBilling from "../components/edit/ListingBilling";
import MoreImageUploadErrorModal from "../components/edit/MoreImageUploadErrorModal";
import LeaseDetailForm from "../components/edit/LeaseDetailForm";
import CarImagesForm from "../components/edit/CarImagesForm";
import CarContactForm from "../components/edit/CarContactForm";
import Payment from "../components/edit/Payment";
import PricingModal from "../components/pricing/PricingModal";
import { uploadListingImagesToS3 } from "../utilities/aws";

const options = [
  { name: "Vehicle details" },
  { name: "Lease details" },
  { name: "Images" },
  { name: "Contact" },
  { name: "Payment" },
];

class EditListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 1,
      showUpgradeModal: false,
      showPricingModal: false,
      thumbnail: [],
      images: [],
      listingData: {
        data: null,
        makes: [],
        trims: [],
        models: [],
        bodyTypes: [
          "HATCHBACK",
          "SEDAN",
          "MUV",
          "SUV",
          "COUPE",
          "CONVERTIBLE",
          "WAGON",
          "VAN",
          "JEEP",
        ],
        transmissionTypes: ["MANUAL", "AUTOMATIC", "CVT", "SEMI"],
        fuelTypes: ["GASOLINE", "DIESEL", "ELECTRIC", "CNG", "HYBRID", "OTHER"],
        colors: [],
        searchText: "",
        searchResults: [],
        isLoading: true,
        isEditing: false,
        canShowCheckIcon: false,
      },
      areImagesUploading: false,
    };
    this.imagesCount = 0;
  }

  /**
   * Show pricing modal
   * @param {value} value
   */
  setShowPricingModal = (value) => {
    this.setState({ showPricingModal: value });
  };

  /**
   * Change current step
   * @param {value} value
   */
  setCurrentStep = (value) => {
    this.setState({ currentStep: value });
  };

  /**
   * Set thumnail image
   * @param {value} value
   */
  setThumbnail = (value) => {
    this.setState({ thumbnail: value });
  };

  /**
   * Set images
   * @param {value} value
   */
  setImages = (files) => {
    const modifiedFiles = files.map((file) => ({
      file,
      isProcessed: false,
    }));
    this.setState({ images: modifiedFiles });
  };

  /**
   * Change the listing values
   * @param {new values} e
   */
  changeListingData = (e, callback) => {
    this.setState(
      (prev) => ({
        listingData: {
          ...prev.listingData,
          ...e,
          data: { ...prev.listingData.data, ...e.data },
        },
      }),
      () => {
        callback && callback();
      }
    );
  };

  /**
   * Change in the current listing data
   * @param {event} e
   */
  handleChange = (e) => {
    const { value, name } = e.target;
    const { leaseEnd } = this.state.listingData.data;
    if (name === "remainingMiles" && leaseEnd) {
      const monthsRemaining = calculateMonthsRemaining(leaseEnd);

      monthsRemaining &&
        this.setState((prev) => ({
          listingData: {
            ...prev.listingData,
            data: {
              ...prev.listingData.data,
              milesPerMonth: Math.round(value / monthsRemaining),
            },
          },
        }));
    }
    this.setState((prev) => ({
      listingData: {
        ...prev.listingData,
        data: { ...prev.listingData.data, [name]: value },
      },
    }));
  };

  /**
   * Validate if all the fields are filled
   */
  validateSteps = () => {
    const { history } = this.props;
    let count = 1;
    let flag = true;
    while (count <= 4) {
      flag = isAllFieldsFilled(count, this.state.listingData.data);
      if (flag === false) {
        this.setCurrentStep(count);
        return;
      }
      count++;
    }
    flag && history.push("/listing");
  };

  /**
   * Set current plan
   * @param {plan} plan
   */
  setCurrentPlan = (plan) => {
    if (!plan) return this.setState({ showPricingModal: false });
    const { id } = this.state.listingData.data;
    axiosInstance
      .post(`listings/${id}`, { plan })
      .then((response) => {
        this.changeListingData(
          {
            data: {
              ...response.data.data,
              images: this.state.listingData.data.images,
              leaseEnd: formatDate(response.data.data.leaseEnd),
              color: capitalizeFirstLetter(response.data.data.color),
            },
          },
          () => {
            this.setState({ showPricingModal: false });
          }
        );
      })
      .catch((error) => {
        toast.error(getErrorMessage(error));
      });
  };

  /**
   * On change of select
   * @param {name} name
   * @param {value} value
   */
  onSelectChange = (name, value) => {
    // Fetch models & trims for this make
    if (name === "make") {
      this.changeListingData({ data: { model: "", trim: "" } });
      value &&
        this.fetchCarModels(value);
    }
    // Fetch trims for this modal
    if (name === "model" && value) {
      this.changeListingData({ data: { trim: "" } });

      const isValidModel = this.state.listingData.models.find(
        (model) => model.name === value
      );
      isValidModel
        ? this.fetchCarTrims(value, this.state.listingData.data.make)
        : this.changeListingData({ trims: [] });
    }
    // Calculate milesPerMonth on leaseEnd change
    if (name === "leaseEnd") {
      const { remainingMiles } = this.state.listingData.data;
      const monthsRemaining = calculateMonthsRemaining(value);

      if (remainingMiles && monthsRemaining) {
        const milesPerMonth = Math.round(remainingMiles / monthsRemaining);
        this.changeListingData({
          data: { milesPerMonth, leaseEnd: formatDate(value) },
        });
      }
      value = formatDate(value);
    }

    // Just change the data of form
    this.changeListingData({
      data: { [name]: value },
    });
  };

  /**
   * Update the listing after form submitted succesfully
   * @param {listing} listing
   * @param {updateSteps} updateSteps
   */
  updateListing = (data, updateSteps) => {
    const { currentStep } = this.state;

    const flag = isAllFieldsFilled(currentStep, data);

    this.changeListingData(
      {
        data: {
          ...data,
          images: this.state.listingData.data.images,
          leaseEnd: formatDate(data.leaseEnd),
          color: capitalizeFirstLetter(data.color),
        },
        isEditing: false,
      },
      this.setState((prev) => ({
        currentStep:
          currentStep < 5 && flag && updateSteps !== false
            ? currentStep + 1
            : currentStep,
        thumbnail: data.mediumCoverUrl ? [] : prev.thumbnail,
      })),
      () => {
        flag && currentStep === 4 && this.validateSteps();
      }
    );

    !flag &&
      updateSteps !== false &&
      toast.info("Please fill all the fields to proceed", { autoClose: 2000 });
  };

  /**
   * Fet car basic data, makes & then fetch the listing data
   */
  fetchCarsData = () => {
    axios
      .get("https://specifications.vinaudit.com/v3/selections", {
        params: {
          key: "FYOQZFO92LTT7XQ",
          list: "make",
          format: "json",
        },
      })
      .then((response) => {
        this.changeListingData(
          {
            makes: response.data.selections.makes,
          },
          () => {
            this.fetchListing();
          }
        );
      })
      .catch(() => {
        this.changeListingData(
          {
            makes: [],
          },
          () => {
            this.fetchListing();
          }
        );
      });
  };

  fetchListing = () => {
    const { slug } = this.props.match.params;

    axiosInstance
      .get(`listings/${slug}`)
      .then((response) => {
        this.imagesCount = response.data.data.images.length;

        const nextData = {
          data: {
            ...response.data.data,
            leaseEnd: formatDate(response.data.data.leaseEnd),
            color: capitalizeFirstLetter(response.data.data.color),
          },
          searchText: response.data.data.address,
          isLoading: false,
        };

        this.changeListingData(nextData);

        if (response.data.data.make)
          this.fetchCarModels(response.data.data.make);
        if (response.data.data.model) {
          this.fetchCarTrims(response.data.data.model, response.data.data.make);
        }
        if (response.data.data.vin) {
          this.fetchCarColors(response.data.data.vin);
        }
      })
      .catch((error) => {
        this.changeListingData({
          isLoading: false,
          errorMessage: getErrorMessage(error),
        });
      });
  };

  /**
   * Fetch the car modals
   * @param {previousData} previousData
   */
  fetchCarModels = (currentMake) => {
    const make = this.state.listingData.makes.find(
      (make) => make.name === currentMake
    );
    make &&
      axios
        .get("https://specifications.vinaudit.com/v3/selections", {
          params: {
            key: "FYOQZFO92LTT7XQ",
            id: make.name.replace(" ", "-"),
            format: "json",
          },
        })
        .then((response) => {
          const models = response.data.selections?.makes?.length
            ? response.data.selections.makes[0].models
            : [];
          this.changeListingData({ models });
        })
        .catch(() => {
          this.changeListingData({ models: [] });
        });
  };

  /**
   * Fetch all the car trims
   * @param {previousData} previousData
   */
  fetchCarTrims = (currentModel, make) => {
    axios
      .get("https://specifications.vinaudit.com/v3/selections", {
        params: {
          key: "FYOQZFO92LTT7XQ",
          id: `${make.replace(" ", "-")}_${currentModel.replace(" ", "-")}`,
          format: "json",
        },
      })
      .then((response) => {
        const trims =
          response.data.selections?.makes &&
          response.data.selections?.makes[0]?.models &&
          response.data.selections?.makes[0]?.models[0]?.trims
            ? response.data.selections.makes[0].models[0].trims
            : [];
        this.changeListingData({
          trims,
          isLoading: false,
        });
      })
      .catch(() => {
        this.changeListingData({
          trims: [],
          isLoading: false,
        });
      });
  };

  async fetchCarColors(vin) {
    try {
      const response = await axios.get(
        "https://specifications.vinaudit.com/v3/specifications",
        {
          params: {
            key: "FYOQZFO92LTT7XQ",
            format: "json",
            include: "colors",
            vin,
          },
        }
      );
      if (response.data.success) {
        const colors = response.data.colors
          .filter((color) => color.category === "Exterior")
          .map((color) => color.name);
        colors.length &&
          this.changeListingData({
            colors,
          });
      }
    } catch (e) {
      this.changeListingData({
        colors: [],
      });
    }
  }

  /**
   * Update the location when map is changed
   * @param {latitude} longitude
   * @param latitude latitude
   */
  updateLocationFromMap = (longitude, latitude) => {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
    axios
      .get(encodeURI(url))
      .then((response) => {
        const { state, address } = getAddressFromGeocode(response.data);
        this.changeListingData({
          searchText: address,
          data: { longitude, latitude, state, address },
        });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  /**
   * Search location on input value change
   */
  searchLocation = async (e) => {
    const searchText = e.target.value;
    if (!searchText) return;
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}&limit=20&country=US`;
    axios
      .get(encodeURI(url))
      .then((response) => {
        this.changeListingData({
          searchText: searchText,
          searchResults: response.data.features,
        });
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  /**
   * Update location on selection
   */
  updateLocationFromSearch = (value) => {
    this.changeListingData({
      data: {
        address: value.place_name,
        longitude: value.center[0],
        latitude: value.center[1],
        state: getStateFromLocResult(value),
      },
      searchText: value.place_name,
    });
  };

  /**
   *
   * @param {*} e
   * @param {*} updateSteps
   * @param {*} type
   */
  saveListing = (e, updateSteps, type) => {
    const { history } = this.props;

    e.preventDefault();

    this.setState(
      (prev) => ({
        listingData: {
          ...prev.listingData,
          isEditing: updateSteps !== false ? true : false,
        },
      }),
      () => {
        const { currentStep, listingData } = this.state;
        const { id } = listingData.data;
        let data;
        if (type === "saveAndExit") {
          data = getFormatedData(listingData.data);
        } else {
          data = getFormatedData(listingData.data, currentStep);
        }

        axiosInstance
          .post(`listings/${id}`, data)
          .then((response) => {
            // if (response.data.data.images.length > this.imagesCount) {
            //   this.setState((prev) => ({
            //     images: [],
            //     listingData: { canShowCheckIcon: false },
            //   }));
            // }
            // const leaseEnd = response.data.data.leaseEnd;
            // const data = leaseEnd
            //   ? { ...response.data.data, leaseEnd: formatDate(leaseEnd) }
            //   : response.data.data;

            this.updateListing(response.data.data, updateSteps);
            // type === "saveAndExit" && history.push("/");
            updateSteps === false && history.push("/");
          })
          .catch((error) => {
            toast.error(getErrorMessage(error));
            this.changeListingData({ isEditing: false });
          });
      }
    );
  };

  /**
   * On sumbitting the car image form
   * @param {event} e
   * @param {boolean} isSaveAndExit
   * @returns
   */
  updateImages = (e, isSaveAndExit) => {
    e.preventDefault();

    const { thumbnail, images, currentStep, listingData } = this.state;

    const isStateEmpty = thumbnail.length === 0 || images.length === 0;

    if (thumbnail.length === 0 && images.length === 0 && !isSaveAndExit) {
      return isAllFieldsFilled(currentStep, listingData.data)
        ? this.setCurrentStep(currentStep + 1)
        : toast.info("Please upload images to proceed", { autoClose: 2000 });
    }

    if (
      !listingData.data.mediumCoverUrl &&
      listingData.data.images.length === 0 &&
      isStateEmpty &&
      !isSaveAndExit
    ) {
      return toast.info("Please upload images to proceed", { autoClose: 2000 });
    }

    if (
      listingData.data.plan === "basic" &&
      listingData.data.images.length + images.length > 12
    ) {
      return this.setState({ showUpgradeModal: true });
    }

    this.changeListingData({ isEditing: !isSaveAndExit ? true : false }, () => {
      const imagesToBeProcessed = images.filter(
        (image) => image.isProcessed === false
      );
      imagesToBeProcessed.length &&
        this.setState({ areImagesUploading: !isSaveAndExit ? true : false });

      const { id } = listingData.data;

      uploadListingImagesToS3(id, {
        images: imagesToBeProcessed,
        cover: [...thumbnail],
      });

      const markImagesProcessed = images.map((image) => ({
        file: image.file,
        isProcessed: true,
      }));

      this.setState({
        images: markImagesProcessed,
      });

      const { slug } = this.props.match.params;
      const intervalTime = images.length < 6 ? 5000 : 10000;
      let tempResponse;
      const listingImagesCountBeforeUpload = listingData.data.images.length;
      let tempImages = listingData.data.images;
      const uploadedlistingImagesCount = imagesToBeProcessed.length;

      const waitToSetAllImages = setInterval(() => {
        if (
          listingImagesCountBeforeUpload + uploadedlistingImagesCount >
          tempImages.length
        ) {
          axiosInstance
            .get(`listings/${slug}`)
            .then((response) => {
              this.imagesCount = response.data.data.images.length;

              tempImages = [...response.data.data.images];
              tempResponse = response.data.data;

              return;
            })
            .catch((error) => {
              return toast.error(getErrorMessage(error));
            });
        } else {
          clearInterval(waitToSetAllImages);
          this.setState((prev) => ({
            listingData: {
              ...prev.listingData,
              data: {
                ...prev.listingData.data,
                images: tempImages,
                mediumCoverUrl: tempResponse?.mediumCoverUrl,
                smallCoverUrl: tempResponse?.smallCoverUrl,
              },
              isEditing: false,
            },
            currentStep: !isSaveAndExit ? currentStep + 1 : currentStep,
            areImagesUploading: false,
            images: [],
            thumbnail: tempResponse?.mediumCoverUrl ? [] : prev.thumbnail,
          }));
        }
      }, intervalTime);
    });
  };

  /**
   * On click save and exit
   * @param {event} e
   */
  saveAndExit = (e) => {
    const { history } = this.props;
    const { thumbnail, images, listingData } = this.state;

    if (listingData.isEditing) {
      return history.push("/");
    }

    const updateImages = thumbnail.length !== 0 || images.length !== 0;
    updateImages && this.updateImages(e, true);
    this.saveListing(e, false, "saveAndExit");
  };

  componentDidMount() {
    this.fetchCarsData();
  }

  render() {
    const {
      thumbnail,
      images,
      listingData,
      currentStep,
      showPricingModal,
      showUpgradeModal,
      areImagesUploading,
    } = this.state;
    const isEditing = this.state.listingData;
    const { plans } = this.context;

    if (listingData.isLoading) {
      return <LoadingScreen />;
    }

    const currentPlan = listingData?.data?.plan;

    return (
      <Root>
        <Sidebar
          listing={listingData.data}
          canShowCheckIcon={listingData.canShowCheckIcon}
          options={options}
          currentStep={currentStep}
          setStepCount={this.setCurrentStep}
          saveAndExit={this.saveAndExit}
        />
        <PricingModal
          isOpen={showPricingModal}
          plans={plans}
          currentPlan={currentPlan}
          listingData={listingData}
          setCurrentPlan={this.setCurrentPlan}
          close={this.setShowPricingModal}
        />

        <MoreImageUploadErrorModal
          isOpen={showUpgradeModal}
          close={() => this.setState({ showUpgradeModal: false, images: [] })}
          listingData={listingData}
        />
        <Div p={{ t: "7.5rem", b: "15rem" }}>
          <CustomContainer>
            <Row>
              <Col
                size={{
                  xs: "16",
                  sm: "14",
                  md: "10",
                  lg: "10",
                  xl: "8",
                }}
                offset={{ sm: "1", md: "0", lg: "0", xl: "4" }}
              >
                <Div p={{ xl: "0 2.5rem" }}>
                  {currentStep === 1 && (
                    <VehicleDetailForm
                      currentStep={currentStep}
                      listing={listingData}
                      currentPlan={currentPlan}
                      bodyTypes={listingData.bodyTypes}
                      transmissionTypes={listingData.transmissionTypes}
                      fuelTypes={listingData.fuelTypes}
                      colors={listingData.colors}
                      makes={listingData.makes}
                      searchText={listingData.searchText}
                      searchResults={listingData.searchResults}
                      fetchCarModels={this.fetchCarModels}
                      fetchCarTrims={this.fetchCarTrims}
                      handleChange={this.handleChange}
                      changeListingData={this.changeListingData}
                      onSelectChange={this.onSelectChange}
                      updateLocationFromMap={this.updateLocationFromMap}
                      searchLocation={this.searchLocation}
                      updateLocationFromSearch={this.updateLocationFromSearch}
                      setCurrentStep={this.setCurrentStep}
                      onSubmit={this.saveListing}
                      isEditing={isEditing}
                    />
                  )}
                  {currentStep === 2 && (
                    <LeaseDetailForm
                      currentStep={currentStep}
                      listingData={listingData}
                      handleChange={this.handleChange}
                      onSelectChange={this.onSelectChange}
                      setCurrentStep={this.setCurrentStep}
                      onSubmit={this.saveListing}
                      isEditing={isEditing}
                    />
                  )}
                  {currentStep === 3 && (
                    <CarImagesForm
                      currentStep={currentStep}
                      thumbnail={thumbnail}
                      images={images}
                      listingData={listingData}
                      currentPlan={currentPlan}
                      setImages={this.setImages}
                      setCurrentStep={this.setCurrentStep}
                      setShowPricingModal={this.setShowPricingModal}
                      updateListing={this.updateListing}
                      setThumbnail={this.setThumbnail}
                      updateImages={this.updateImages}
                      isEditing={isEditing}
                      setListing={this.changeListingData}
                      areImagesUploading={areImagesUploading}
                    />
                  )}
                  {currentStep === 4 && (
                    <CarContactForm
                      listingData={listingData}
                      currentStep={currentStep}
                      currentPlan={currentPlan}
                      setCurrentStep={this.setCurrentStep}
                      updateListing={this.updateListing}
                      handleChange={this.handleChange}
                      onSelectChange={this.onSelectChange}
                      onSubmit={this.saveListing}
                      isEditing={isEditing}
                    />
                  )}
                  {currentStep === 5 && (
                    <Payment
                      plans={plans}
                      price={currentPlan === "basic" ? 120 : 460}
                      currentStep={currentStep}
                      listing={listingData.data}
                      setCurrentPlan={this.setCurrentPlan}
                      currentPlan={currentPlan}
                      showPricingModal={showPricingModal}
                      updateListing={this.updateListing}
                      setCurrentStep={this.setCurrentStep}
                      setShowPricingModal={this.setShowPricingModal}
                      changeListingData={this.changeListingData}
                    />
                  )}
                </Div>
              </Col>
              {currentStep !== 5 && currentStep !== 4 && (
                <Col
                  d={{ xs: "none", md: "block" }}
                  size={{ md: "6", xl: "4" }}
                >
                  <Div p={{ l: "1.5rem" }}>
                    <ListingBilling
                      plans={plans}
                      listing={listingData.data}
                      currentPlan={currentPlan}
                      showPricingModal={showPricingModal}
                      setShowPricingModal={this.setShowPricingModal}
                      setCurrentPlan={this.setCurrentPlan}
                      setStepCount={this.setCurrentStep}
                      updateListing={this.updateListing}
                    />
                  </Div>
                </Col>
              )}
            </Row>
          </CustomContainer>
        </Div>
      </Root>
    );
  }
}

EditListing.contextType = PlanPriceContext;
export default withRouter(EditListing);
