import React from "react";
import { Div, Icon, Text } from "atomize";

export default function FormGlobalMessage({
  error,
  success,
  warning,
  ...rest
}) {
  return (
    <>
      {success && (
        <Div
          bg="success200"
          m={{ t: "0.5rem" }}
          rounded="md"
          d="flex"
          align="center"
          p="0.75rem 0.75rem 0.75rem 1rem"
          {...rest}
        >
          <Icon
            name="Success"
            color="success700"
            size="20px"
            m={{ r: "0.75rem" }}
          />
          <Text textWeight="500" textColor="medium">
            {success}
          </Text>
        </Div>
      )}
      {warning && (
        <Div
          bg="warning200"
          m={{ t: "0.5rem" }}
          rounded="md"
          d="flex"
          p="0.75rem 0.75rem 0.75rem 1rem"
          {...rest}
        >
          <Icon
            name="AlertSolid"
            color="warning800"
            size="20px"
            m={{ r: "0.75rem" }}
          />
          <Text textWeight="500">{warning}</Text>
        </Div>
      )}
      {error && (
        <Div
          bg="danger200"
          m={{ t: "0.5rem" }}
          rounded="md"
          d="flex"
          p="0.75rem 0.75rem 0.75rem 1rem"
          {...rest}
        >
          <Icon
            name="AlertSolid"
            color="danger700"
            size="20px"
            m={{ r: "0.75rem" }}
          />
          <Text textWeight="500">{error}</Text>
        </Div>
      )}
    </>
  );
}
