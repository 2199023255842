import React, { useState } from "react";
import { Text, Div, Icon, Checkbox } from "atomize";
import DefaultInput from "../common/atoms/DefaultInput";

export default function SeachableDropdown({
  locations,
  appliedFilters,
  setFilters,
}) {
  const [value, setvalue] = useState("");
  const handleChange = (item, isSelected) => {
    if (!isSelected) {
      setFilters("locations", [...appliedFilters.locations, item]);
      return;
    }
    setFilters("locations", [
      ...appliedFilters.locations.filter((el) => el !== item),
    ]);
  };

  return (
    <>
      <Div p="1rem 1.5rem" border={{ b: "1px solid" }} borderColor="o-light">
        <DefaultInput
          placeholder="Search location"
          suffix={
            <Icon
              name="Search"
              size="20px"
              color="text-medium"
              pos="absolute"
              top="50%"
              right="1rem"
              transform="translateY(-50%)"
            />
          }
          handleChange={(e) => setvalue(e.target.value)}
        />
      </Div>
      <Div p="1rem 1.5rem" maxH="16rem" overflow="auto">
        {locations
          .filter((location) =>
            location.toLowerCase().startsWith(value.toLowerCase())
          )
          .map((location) => {
            const isSelected = appliedFilters.locations.includes(location);
            return (
              <Div
                tag="label"
                cursor="pointer"
                tabIndex="0"
                d="flex"
                h="2rem"
                align="center"
              >
                <Checkbox
                  size="20px"
                  activeColor="brand700"
                  m={{ r: "1rem" }}
                  checked={isSelected}
                  onChange={() => handleChange(location, isSelected)}
                />
                <Text textColor="text-medium" textWeight="500">
                  {location}
                </Text>
              </Div>
            );
          })}
      </Div>
    </>
  );
}
