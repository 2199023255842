import React, { useContext } from "react";
import { Div, Text, Icon, Image, Tag, Row, Col, Checkbox } from "atomize";
import { useHistory } from "react-router-dom";

import ListingDropdown from "./ListingDropdown";
import { AuthContext } from "../../contexts/auth";

export default function ListingListItem({
  listItem,
  index,
  select,
  addToSelected,
}) {
  const history = useHistory();
  const isSelected = select.includes(listItem);
  const { user } = useContext(AuthContext);

  return (
    <Div
      key={listItem.name}
      pos="relative"
      p="1.5rem 0"
      align="center"
      border={{ t: index === 0 && "1px solid", b: "1px solid" }}
      borderColor={isSelected ? "o-medium" : "o-light"}
      cursor="pointer"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        user?.role === "VIEWER"
          ? history.push(`/listing/${listItem.slug}`)
          : history.push(`/listing/${listItem.slug}/edit`);
      }}
    >
      <Div
        pos="absolute"
        top="0"
        bottom="0"
        w="100vw"
        left="50%"
        transform="translateX(-50%)"
        bg={isSelected ? "gray100" : "white"}
        zIndex="-1"
      ></Div>
      <Row>
        <Col size={{ xs: "12", md: "6", lg: "8" }}>
          <Div d="flex" align={{ lg: "center" }}>
            <Div
              onClick={(e) => {
                e.stopPropagation();
                addToSelected(listItem);
              }}
              tag="span"
              m={{ r: { xs: "1rem", md: "1.875rem" } }}
            >
              <Checkbox m="0px" activeColor="brand600" checked={isSelected} />
            </Div>
            <Div
              shadow="1"
              minW="9rem"
              h="6rem"
              rounded="lg"
              bg={!listItem.smallCoverUrl && "gray200"}
              bgImg={listItem.smallCoverUrl}
              bgSize="cover"
              bgPos="center"
              m={{ r: "3rem" }}
            />
            <ItemDetails
              maxW="calc(100% - 18rem)"
              flexGrow="1"
              d={{ xs: "none", lg: "block" }}
              listItem={listItem}
            />
          </Div>
        </Col>
        <Col d={{ xs: "none", md: "block" }} size={{ md: "3" }}>
          <Text
            textSize="title"
            textWeight="600"
            textColor={listItem.monthlyCost ? "black" : "medium"}
          >
            {listItem.monthlyCost ? `$${listItem.monthlyCost} ` : "- NA - "}
            <Text
              textSize="paragraph"
              textWeight="500"
              textColor="text-light"
              tag="span"
            >
              / month
            </Text>
          </Text>
        </Col>
        <Col d={{ xs: "none", md: "block" }} size={{ md: "4", lg: "2" }}>
          <Div m={{ l: "1rem" }} d="flex" align="center">
            {listItem.plan === "basic" ? (
              <Icon
                name="StarSolid"
                size="20px"
                m={{ r: "0.5rem" }}
                color="pastel6"
              />
            ) : (
              <Image
                src="/images/icons/crown.svg"
                w="20px"
                m={{ r: "0.5rem" }}
              />
            )}

            <Text
              textColor={listItem.plan === "basic" ? "pastel6" : "brand700"}
              textWeight="500"
              textTransform="capitalize"
              textSize="subheader"
            >
              {listItem.plan}
            </Text>
          </Div>
        </Col>
        <Col
          size={{ xs: "4", md: "3", lg: "3" }}
          d="flex"
          align="flex-start"
          justify="flex-end"
        >
          <Div d="flex" m={{ sm: "-0.5rem 0 0 0.5rem" }} align="center">
            <Div pos="relative" d="flex">
              {/* {listItem.payment && listItem.plan === "basic" && (
                <StripeCheckoutButton
                  listing={listItem}
                  d={{ xs: "none", sm: "flex" }}
                  price={340}
                  upgradePlan={true}
                  text="Upgrade plan"
                  shadow="1"
                  style={{
                    // boxShadow: "0 1px 1px 0 rgb(10 31 68 / 10%)",
                    borderRadius: "6px",
                    backgroundColor: "#E3EDFF",
                    color: "#0948EA",
                    marginRight: "1rem",
                    padding: "0 .75rem",
                    whiteSpace: "nowrap",
                    height: "40px",
                    width: "auto",
                    maxWidth: "unset",
                  }}
                />
              )} */}
              <ListingDropdown listItem={listItem} slug={listItem.slug} />
            </Div>
          </Div>
        </Col>
        <Col size="16" d={{ xs: "block", lg: "none" }}>
          <ItemDetails
            m={{ xs: "1rem 0 0 0rem", sm: "1rem 0 0 0rem" }}
            listItem={listItem}
          />
        </Col>
        <Col size="16" d={{ xs: "block", md: "none" }}>
          <Div
            m={{ xs: "1rem 0 0 0rem", sm: "1.25rem 0 0 0rem" }}
            d="flex"
            justify="space-between"
          >
            <Text textSize="title" textWeight="600">
              {listItem.monthlyCost ? `$${listItem.monthlyCost} ` : "NA "}
              <Text
                textSize="paragraph"
                textWeight="500"
                textColor="text-light"
                tag="span"
              >
                / month
              </Text>
            </Text>
            <Div m={{ l: "1rem" }} d="flex" align="center">
              {listItem.plan === "basic" ? (
                <Icon
                  name="StarSolid"
                  size="20px"
                  m={{ r: "0.5rem" }}
                  color="pastel6"
                />
              ) : (
                <Image
                  src="/images/icons/crown.svg"
                  w="20px"
                  m={{ r: "0.5rem" }}
                />
              )}

              <Text
                textColor={listItem.plan === "basic" ? "pastel6" : "brand700"}
                textWeight="500"
                textTransform="capitalize"
                textSize="subheader"
              >
                {listItem.plan}
              </Text>
            </Div>
          </Div>
        </Col>
      </Row>
    </Div>
  );
}

function ItemDetails({ listItem, ...rest }) {
  const history = useHistory();

  return (
    <Div {...rest}>
      <Text
        className="truncate-text"
        textSize="subheader"
        textWeight="500"
        textColor={listItem.make ? "black" : "medium"}
      >
        {listItem.make
          ? `${listItem.make}${listItem.model ? ` ${listItem.model}` : ""}${
              listItem.manufacturingYear ? ` ${listItem.manufacturingYear}` : ""
            }`
          : "-- Untitled listing --"}
      </Text>
      <Div
        className="truncate-text"
        textSize="paragraph"
        textWeight="500"
        textColor="text-light"
        m={{ t: "0.25rem", b: "1rem" }}
      >
        <Text tag="span">
          {listItem.status === "DRAFT" ? "Last Edited: " : "Created: "}
        </Text>
        <Text tag="span">
          &nbsp;
          {listItem.status === "DRAFT"
            ? listItem.updatedAt
            : listItem.createdAt}
        </Text>
      </Div>
      <Tag
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/listing/${listItem.slug}/edit`);
        }}
        minW="3.625rem"
        h="1.5rem"
        bg={
          listItem.status === "DRAFT"
            ? "brand200"
            : listItem.status === "ACTIVE"
            ? "success200"
            : "gray300"
        }
        textColor={
          listItem.status === "DRAFT"
            ? "brand700"
            : listItem.status === "ACTIVE"
            ? "success700"
            : "gray900"
        }
        textSize="overline"
        textTransform="uppercase"
        rounded="md"
        textWeight="600"
        style={{ letterSpacing: "0.1em" }}
        prefix={
          listItem.status === "DRAFT" ? (
            <Icon
              name="EditSolid"
              size="1rem"
              m={{ r: "4px" }}
              color="brand700"
            />
          ) : (
            ""
          )
        }
      >
        {listItem.status === "DRAFT"
          ? "Draft"
          : listItem.status === "ACTIVE"
          ? "Active"
          : "Archived"}{" "}
      </Tag>
    </Div>
  );
}
