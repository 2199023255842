import React, { useState, useContext, useEffect } from "react";
import { Div, Row, Col } from "atomize";
import { axiosInstance } from "../utilities/axiosInstance";
import { AuthContext } from "../contexts/auth";

import CustomContainer from "../components/atoms/CustomContainer";
import Root from "../components/common/Root";
import LoadingScreen from "../components/common/LoadingScreen";
import PageError from "../components/common/PageError";
import EditProfileForm from "../components/account/EditProfileForm";
import ProfileSidebar from "../components/account/ProfileSidebar";
import ProfileTopbar from "../components/account/ProfileTopbar";
import { getErrorMessage } from "../utilities/helpers";

export default function EditProfile() {
  const { user, setUser } = useContext(AuthContext);

  const [userData, setUserData] = useState({
    data: user,
    isLoading: !user,
    hasError: false,
    errorMessage: null,
  });

  /**
   * Change listing state with new values
   * @param {new values array} e
   */
  const changeUserData = (e) => {
    setUserData({ ...userData, ...e });
  };

  /**
   * Remove users profile pic
   */
  const removeProfilePic = () => {
    if (window.confirm("Are you sure you wish to remove?")) {
      axiosInstance
        .post("users/remove-avatar")
        .then((response) => {
          changeUserData({ data: response.data.data });
          const appState = JSON.parse(localStorage["appState"]);
          appState.user = response.data.data;
          localStorage["appState"] = JSON.stringify(appState);
          setUser(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    changeUserData({ loading: true });
    axiosInstance
      .get("users/profile")
      .then((response) => {
        changeUserData({
          data: response.data.data,
          isLoading: false,
        });
        const appState = JSON.parse(localStorage["appState"]);
        appState.user = response.data.data;
        localStorage["appState"] = JSON.stringify(appState);
        setUser(response.data.data);
      })
      .catch((error) => {
        changeUserData({
          isLoading: false,
          hasError: true,
          errorMessage: getErrorMessage(error),
        });
      });
  }, []);

  if (!userData.data) {
    return <LoadingScreen />;
  }

  if (userData.errorMessage) {
    return <PageError errorMessage={userData.errorMessage} />;
  }

  return (
    <Root>
      <ProfileSidebar />
      <ProfileTopbar />
      <Div p={{ t: { xs: "3rem", lg: "7.5rem" }, b: "18.5rem" }}>
        <CustomContainer>
          <Row>
            <Col
              size={{
                xs: "16",
                lg: "9",
                xl: "7",
              }}
              offset={{ lg: "5", xl: "5" }}
            >
              <EditProfileForm
                user={userData.data}
                setUser={setUser}
                removeProfilePic={removeProfilePic}
              />
            </Col>
          </Row>
        </CustomContainer>
      </Div>
    </Root>
  );
}
