import React, { useContext } from "react";
import { Div, Text, Checkbox } from "atomize";
import DefaultInput from "../atoms/DefaultInput";
import ListingFooter from "./ListingFooter";
import { AuthContext } from "../../contexts/auth";

export default function CarContactForm({
  currentStep,
  setCurrentStep,
  onSubmit,
  errors,
  listingData,
  handleChange,
  onSelectChange,
}) {
  const { user } = useContext(AuthContext);
  const { isEditing } = listingData;
  const { allowEmail, allowMessaging, phone, allowPhone } = listingData.data;

  console.log({ allowEmail: !allowPhone, allowMessaging, phone, allowPhone });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.role !== "ADMIN" && user.role !== "EDITOR") {
      return setCurrentStep(currentStep + 1);
    }
    onSubmit(e);
  };

  return (
    <Div>
      <Text textWeight="500" textColor="light" m={{ b: "0.5rem" }}>
        Step {currentStep} of 5
      </Text>

      <Text
        textColor="text-dark"
        textSize="display2"
        textWeight="600"
        m={{ b: "2.5rem" }}
      >
        Contact
      </Text>
      <form onSubmit={handleSubmit}>
        <Div
          border="1px solid"
          borderColor="o-medium"
          shadow="2"
          rounded="12px"
          p="2rem 2rem 1rem"
        >
          <Text
            textSize="1rem"
            m={{ b: "1rem" }}
            textColor="text-medium"
            textWeight="500"
          >
            How do you want to get connected with buyers?
          </Text>
          <Div>
            <Div
              d="flex"
              align="center"
              textWeight="500"
              textSize="14px"
              textColor="text-light"
              tabIndex="0"
              p="1.5rem 0"
              // onClick={() =>
              //   onSelectChange("allowMessaging", !allowMessaging)
              // }
              // onKeyDown={(e) => {
              //   if ((e.keyCode = 13)) {
              //     onSelectChange(
              //       "allowMessaging",
              //       !allowMessaging
              //     );
              //   }
              // }}
              // cursor="pointer"
            >
              <Checkbox
                activeColor="gray500"
                m={{ r: "1.75rem" }}
                checked={allowMessaging}
              />
              Via Swopr on-site messaging
            </Div>
          </Div>

          <Div h="1px" bg="o-light"></Div>
          <Div>
            <Div
              d="flex"
              p="1rem 0"
              align="center"
              textWeight="500"
              textSize="14px"
              textColor="text-light"
              tabIndex="0"
              cursor="pointer"
              onClick={() => onSelectChange("allowPhone", !allowPhone)}
              onKeyDown={(e) => {
                if ((e.keyCode = 13)) {
                  onSelectChange("allowPhone", !allowPhone);
                }
              }}
            >
              <Checkbox
                activeColor="brand600"
                m={{ r: "1.75rem" }}
                checked={allowPhone}
              />
              Connect via phone
            </Div>
            <Div p="0 0 0 3rem">
              <Div pos="relative">
                <DefaultInput
                  h="3rem"
                  p="0 1rem 0 2.5rem"
                  name="phone"
                  onChange={handleChange}
                  value={phone}
                  // error={isValidStr(errors.phone) && errors.phone}
                  type="number"
                  autoComplete="off"
                />
                <Text
                  pos="absolute"
                  top="50%"
                  left="1rem"
                  transform="translateY(-50%)"
                  textWeight="500"
                  textColor="text-disabled"
                  textSize="paragraph"
                >
                  +1
                </Text>
              </Div>
            </Div>
          </Div>
          <Div h="1px" m={{ t: "23.5px" }} bg="o-light"></Div>
          <Div
            d="flex"
            align="center"
            textWeight="500"
            textSize="14px"
            p="1rem 0"
            textColor="text-light"
            tabIndex="0"
            cursor="pointer"
            onClick={() => onSelectChange("allowEmail", !allowEmail)}
          >
            <Checkbox
              activeColor="brand600"
              m={{ r: "1.75rem" }}
              checked={allowEmail}
            />
            Connect via email
          </Div>
        </Div>
        <ListingFooter
          back={() => setCurrentStep(currentStep - 1)}
          isEditing={isEditing}
        />
      </form>
    </Div>
  );
}
