import React from "react";
import { Div, Text } from "atomize";
import { Transition } from "react-transition-group";

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export default function InfoTooltip({ monthlyCost, monthlyPayment, isOpen }) {
  return (
    <Transition in={isOpen} timeout={duration} unmountOnExit>
      {(state) => (
        <Div
          rounded="lg"
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          shadow="4"
          zIndex="5"
          pos="absolute"
          w="208px"
          right="-1.25rem"
          top="2.25rem"
          bg="black800"
          p="0.75rem 1rem 1.25rem"
        >
          <svg
            style={{
              position: "absolute",
              top: "-8px",
              right: "1.25rem",
              width: "16px",
              height: "8px",
            }}
            width="16"
            height="8"
            viewBox="0 0 16 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 8L6.40451 1.59511C7.19658 0.735914 8.55342 0.735912 9.34549 1.5951L15.25 8H0.5Z"
              fill="#14284B"
            />
          </svg>
          <Text
            m={{ b: "0.25rem" }}
            textSize="caption"
            textColor="light"
            textWeight="500"
          >
            The seller has added an additional cash offering to be paid upon
            transfer.
          </Text>
          <Text
            m={{ b: "0.5rem" }}
            textSize="caption"
            textColor="light"
            textWeight="500"
          >
            Original lease payment:{" "}
            <Text tag="span" textWeight="700">
              ${Math.ceil(monthlyPayment)}
            </Text>
          </Text>
          <Text textSize="caption" textColor="white" textWeight="500">
            Lease payment with incentive applied:{" "}
            <Text tag="span" textColor="success600" textWeight="700">
              ${Math.ceil(monthlyCost)}
            </Text>
          </Text>
        </Div>
      )}
    </Transition>
  );
}
