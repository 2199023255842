import Slugify from "slugify";
import { axiosInstance } from "./axiosInstance";
import AWS from "aws-sdk";

const s3 = new AWS.S3({
  region: "us-east-2",
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
});

export const uploadListingImagesToS3 = async (id, files) => {
  if (!window.Jimp) return;
  if (files.cover.length) {
    const cover = files.cover[0];
    const base64data = await toBase64(cover);
    const name = `${randomString(8)}-${slugify(cover.name)}`;

    const coverSm = (await window.Jimp.read(base64data)).resize(
      480,
      window.Jimp.AUTO
    );
    coverSm.getBuffer(cover.type, (err, buffer) => {
      uploadBuffer(buffer, "listings/covers/small", name, cover.type);
    });

    const coverMd = (await window.Jimp.read(base64data)).resize(
      720,
      window.Jimp.AUTO
    );
    coverMd.getBuffer(cover.type, (err, buffer) => {
      uploadBuffer(buffer, "listings/covers/medium", name, cover.type);
    });

    axiosInstance.post(`/listings/attach-image/${id}`, {
      names: [name],
      isCover: true,
    });
  }

  if (files.images.length) {
    const allImagesName = [];
    for (let i = 0; i < files.images.length; i++) {
      const image = files.images[i].file;
      const name = `${randomString(8)}-${slugify(image.name)}`;

      allImagesName.push(name);

      const base64data = await toBase64(image);

      const imageXs = (await window.Jimp.read(base64data)).resize(
        240,
        window.Jimp.AUTO
      );
      imageXs.getBuffer(image.type, (err, buffer) => {
        uploadBuffer(buffer, "listings/images/xsmall", name, image.type);
      });

      const imageLg = (await window.Jimp.read(base64data)).resize(
        900,
        window.Jimp.AUTO
      );
      imageLg.getBuffer(image.type, (err, buffer) => {
        uploadBuffer(buffer, "listings/images/large", name, image.type);
      });
    }
    axiosInstance.post(`/listings/attach-image/${id}`, {
      names: allImagesName,
    });
  }
};

export const uploadAvatarImageToS3 = async (file) => {
  if (!window.window.Jimp) return;
  if (!file) return;
  const name = `${randomString(8)}-${slugify(file.name)}`;
  const base64data = await toBase64(file);

  const avatarSm = (await window.Jimp.read(base64data)).resize(
    320,
    window.Jimp.AUTO
  );
  avatarSm.getBuffer(file.type, async (err, buffer) => {
    await uploadBuffer(buffer, "avatars/small", name, file.type);
  });
  return name;
};

async function uploadBuffer(buffer, path, name, mimeType) {
  const fileKey = `${path}/${name}`;
  return await s3
    .upload({
      Body: buffer,
      Bucket: process.env.REACT_APP_S3_BUCKET,
      Key: fileKey,
      ACL: "public-read",
      ContentType: mimeType,
    })
    .promise()
    .then(
      (data) => {
        return data.Key;
      },
      (error) => {
        console.log(error);
        throw error;
      }
    );
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });

function randomString(length) {
  var result = "";
  const chars =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (var i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

function slugify(string) {
  return Slugify(string, {
    replacement: "-",
    remove: /[*+~()'"!:@&,]/g,
    strict: false,
  });
}
