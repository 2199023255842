import { get } from "lodash";
const moment = require("moment");

export const getValidationMessage = (error, validation) => {
  const validationMessage = get(
    error,
    `response.data.errors.${validation}.0`,
    null
  );
  return validationMessage !== null ? validationMessage : "";
};

export const isValidStr = (str) => {
  if (
    typeof str === "undefined" ||
    !str ||
    str.length === 0 ||
    str === "" ||
    !/[^\s]/.test(str) ||
    /^\s*$/.test(str) ||
    str.replace(/\s/g, "") === ""
  ) {
    return false;
  }
  return true;
};

export const capitalizeFirstLetter = (string) => {
  if (typeof string === "string" || string instanceof String)
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  return string;
};

export const getErrorMessage = (error) => {
  const errorMessage = get(
    error,
    "response.data.message",
    "Something went wrong"
  );
  if (error.response && error.response.status === 500) {
    return "Something went wrong";
  }
  if (error.response && error.response.status === 404) {
    return "We can't find the resource you are looking for.";
  }
  return errorMessage !== null ? errorMessage : "";
};

export const formatDate = (value) => {
  if (!value) return null;
  const date = new Date(value);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const formatDateV2 = (value) => {
  let date;
  if (value) {
    date = new Date(value);
  } else {
    date = new Date();
  }
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const steps = {
  1: [
    "vin",
    "make",
    "model",
    "manufacturingYear",
    "fuelType",
    "bodyType",
    "transmission",
    "maxPassengers",
    "color",
    "description",
    "address",
  ],
  2: [
    "leasingCompany",
    "milesPerMonth",
    "currentMiles",
    "leaseEnd",
    "monthlyCost",
    "monthlyPayment",
    "monthsRemaining",
    "remainingMiles",
  ],
  3: ["smallCoverUrl", "mediumCoverUrl"], //images
  4: ["allowMessaging", "allowPhone"], //phone
  5: ["payment"],
};

export const isAllFieldsFilled = (currentStep, listing, canShowCheckIcon) => {
  if (currentStep === 3) {
    const isCurrentStepFieldsfilled =
      steps[currentStep].every((field) => listing[field] !== null) &&
      listing?.images?.length !== 0;
    return isCurrentStepFieldsfilled || !!canShowCheckIcon;
  }
  if (currentStep === 4) {
    if (listing.allowPhone) {
      return (
        steps[currentStep].every((field) => listing[field] === true) &&
        listing?.phone !== null &&
        listing?.phone !== ""
      );
    } else {
      // return listing.allowMessaging === true;
      return true;
    }
  }
  if (currentStep === 5) {
    return listing?.payment === true;
  }

  return steps[currentStep].every(
    (field) => listing[field] !== null && listing[field] !== ""
  );
};

export const getAddressFromGeocode = (result) => {
  let state = "";
  let address = "";
  result.features.forEach((feature) => {
    const placeType = feature.id.split(".");
    if (placeType[0] === "region") {
      state = feature.text;
    }
    if (placeType[0] === "address" || placeType[0] === "poi") {
      address = feature.place_name;
    }
  });
  return { state, address };
};

export const getStateFromLocResult = (result) => {
  let state = result.text;
  if (result.context && result.context.length) {
    result.context.forEach((context) => {
      const placeType = context.id.split(".");
      if (placeType[0] === "region") {
        state = context.text;
      }
    });
  }
  return state;
};

export const isPasswordStrong = (password) => {
  const result = { error: false, msg: "" };
  const regExp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);

  if (password.length < 8) {
    result.error = true;
    result.msg = "The password must be at least 8 characters.";
  } else if (!regExp.test(password)) {
    result.error = true;
    result.msg =
      "The Password must contain one uppercase letter, one lowercase letter, and one number.";
  }
  return result;
};
export const getFormatedData = (listing, currentStep) => {
  const {
    vin,
    make,
    model,
    manufacturingYear,
    fuelType,
    trim,
    bodyType,
    transmission,
    maxPassengers,
    color,
    description,
    longitude,
    latitude,
    address,
    state,
    leasingCompany,
    leaseEnd,
    currentMiles,
    remainingMiles,
    incentive,
    monthlyPayment,
    allowEmail,
    allowMessaging,
    phone,
    allowPhone,
  } = listing;

  if (currentStep === 1) {
    return {
      vin,
      make,
      model,
      manufacturingYear,
      fuelType,
      trim,
      bodyType,
      transmission,
      maxPassengers,
      color,
      description,
      longitude,
      latitude,
      address,
      state,
    };
  } else if (currentStep === 2) {
    return {
      leasingCompany,
      leaseEnd,
      currentMiles,
      remainingMiles,
      incentive,
      monthlyPayment,
    };
  } else if (currentStep === 4) {
    return { allowEmail, allowMessaging, phone, allowPhone };
  } else {
    return {
      vin,
      make,
      model,
      manufacturingYear,
      fuelType,
      trim,
      bodyType,
      transmission,
      maxPassengers,
      color,
      description,
      longitude,
      latitude,
      address,
      state,
      leasingCompany,
      leaseEnd,
      currentMiles,
      remainingMiles,
      incentive,
      monthlyPayment,
      allowEmail,
      allowMessaging,
      phone,
      allowPhone,
    };
  }
};

export const calculateMonthsRemaining = (leaseEnd) => {
  const leaseEndDate = moment(leaseEnd, "DD-MM-YYYY");
  const now = moment(new Date());
  if (leaseEndDate.toDate() < now.toDate()) return null;
  return leaseEndDate.diff(now, "months");
};
