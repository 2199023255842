import React from "react";
import { Div, Text, Icon, Button } from "atomize";

import Modal from "../atoms/Modal";

export default function ConfirmRoleModal({
  isOpen,
  close,
  handleConfirm,
  member,
}) {
  const getMemberDetail = () => {
    return `${member.firstName} ${member.lastName} (${member.email})`;
  };

  return (
    <Modal
      maxW="32rem"
      isOpen={isOpen}
      // close={close}
      align="center"
      rounded={{ sm: "xl" }}
    >
      <Div
        p="1.5rem"
        d="flex"
        justify="space-between"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Div p="0 0 0 0.5rem" d="flex">
          <Div
            d="flex"
            align="center"
            justify="center"
            h="2.5rem"
            w="2.5rem"
            bg={member.newRole === "CUSTOMER" ? "danger200" : "brand200"}
            m={{ r: "1rem" }}
            rounded="circle"
          >
            <Icon
              name="AlertSolid"
              size="20px"
              color={member.newRole === "CUSTOMER" ? "danger600" : "brand600"}
            />
          </Div>
          <Text textSize="heading" textColor="black900" textWeight="600">
            {member.newRole === "CUSTOMER"
              ? "Remove access"
              : "Change access level"}
          </Text>
        </Div>
        <Button
          onClick={close}
          h="2.5rem"
          w="2.5rem"
          bg="gray200"
          border="1px solid"
          borderColor="gray300"
          rounded="circle"
          d="flex"
          align="center"
          justify="center"
        >
          <Icon name="Cross" color="black800" />
        </Button>
      </Div>
      <Div p="1rem 2rem 2rem">
        {member.newRole === "CUSTOMER" ? (
          <Text textSize="subheader" textWeight="500" m={{ b: "2rem" }}>
            Are you sure you want to remove{" "}
            <span style={{ fontWeight: "600" }}>{getMemberDetail()}</span> from
            accessing Admin Panel?
          </Text>
        ) : member.newRole === "EDITOR" ? (
          <Text textSize="subheader" textWeight="500" m={{ b: "2rem" }}>
            Editor can manage listings and billing. Make{" "}
            <span style={{ fontWeight: "600" }}>{getMemberDetail()}</span>{" "}
            editor?
          </Text>
        ) : (
          <Text textSize="subheader" textWeight="500" m={{ b: "2rem" }}>
            Viewer can see all the listings. Make{" "}
            <span style={{ fontWeight: "600" }}>{getMemberDetail()}</span>{" "}
            viewer?
          </Text>
        )}
        <Div
          d="flex"
          align={{ md: "center" }}
          flexDir={{ xs: "column", md: "row" }}
          justify="space-between"
        >
          <Button
            flexGrow="1"
            bg="brand700"
            h="3rem"
            p="0 1.5rem"
            textWeight="600"
            m={{ r: { md: "2rem" } }}
            onClick={() => handleConfirm(member.newRole, member.email)}
          >
            Yes, confirm
          </Button>

          <Button
            flexGrow="1"
            bg="white"
            borderColor="gray400"
            border="1px solid"
            h="3rem"
            p="0 1.5rem"
            textWeight="600"
            textColor="medium"
            shadow="1"
            onClick={close}
          >
            Cancel
          </Button>
        </Div>
      </Div>
    </Modal>
  );
}
