import React, { useState, useEffect } from "react";
import { Div, Anchor, Icon, Text, Button } from "atomize";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Mousewheel } from "swiper";

SwiperCore.use([EffectFade, Mousewheel]);

export default function ProductCarousel({ listing }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [mainSwiper, setMainSwiper] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const filterImages = (listing) => {
    if (listing.plan === "advanced") {
      return listing.images;
    }
    //For basic plan show only 12 images
    return listing.images.filter((image, index) => index < 12);
  };

  const slideTo = (index) => {
    console.log(`sliding to ${index}`);
    mainSwiper?.slideTo(index, 500);
    thumbsSwiper?.slideTo(index, 500);
  };

  useEffect(() => {
    mainSwiper?.on("slideChange", function () {
      console.log(`on main swipe to ${mainSwiper.realIndex}`);

      thumbsSwiper?.slideTo(mainSwiper.realIndex, 500);
      setCurrentIndex(mainSwiper.realIndex);
    });
  }, [mainSwiper, thumbsSwiper]);

  const isFirst = currentIndex === 0;
  const isLast = currentIndex === listing.images.length;

  return (
    <Div maxW={{ md: "90%", lg: "80%" }}>
      <Div d="flex" m={{ b: "1.375rem" }} align="center">
        <Link href="/">
          <Anchor
            textWeight="500"
            hoverTextColor="text-dark"
            textColor="text-medium"
            textSize="caption"
          >
            Home
          </Anchor>
        </Link>

        <Icon
          name="RightArrow"
          size="16px"
          color="disabled"
          m={{ x: "0.5rem" }}
        />
        <Link href="/explore">
          <Anchor
            textWeight="500"
            hoverTextColor="text-dark"
            textColor="text-medium"
            textSize="caption"
          >
            Explore
          </Anchor>
        </Link>
        <Icon
          name="RightArrow"
          size="16px"
          color="disabled"
          m={{ x: "0.5rem" }}
        />

        <Text
          textWeight="600"
          textColor="text-dark"
          hoverTextColor="text-dark"
          textSize="caption"
        >
          {listing.make
            ? `${listing.make}${listing.model ? ` ${listing.model}` : ""}${
                listing.manufacturingYear ? ` ${listing.manufacturingYear}` : ""
              }`
            : "-- Untitled listing --"}
        </Text>
      </Div>
      <Swiper
        onSwiper={setMainSwiper}
        effect="fade"
        mousewheel={{
          forceToAxis: true,
        }}
      >
        {listing.mediumCoverUrl && (
          <SwiperSlide key={`swiper-thumb-${0}`} style={{ width: "72px" }}>
            <Div
              shadow="1"
              rounded="lg"
              bgImg={listing.mediumCoverUrl}
              bgSize="cover"
              bgPos="center"
              p={{ b: "68.3%" }}
            />
          </SwiperSlide>
        )}
        {filterImages(listing).map((image, index) => {
          console.log({ image });
          return (
            <SwiperSlide key={`swiper-mainimage-${index + 1}`}>
              <Div
                shadow="1"
                rounded="lg"
                bgImg={image.largeImageUrl}
                bgSize="cover"
                bgPos="center"
                p={{ b: "68.3%" }}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Div
        m={{
          xs: "0.875rem -1.5rem 0",
          sm: "0.875rem -3rem 0",
          md: "0.875rem 0 0",
        }}
        p={{ xs: "0 1.5rem", sm: "0 3rem", md: "0" }}
      >
        <Div d="flex" w="100%">
          {!isFirst && (
            <Button
              // disabled={isFirst}
              // style={isFirst ? { borderColor: 'white' } : {}}
              onClick={() => slideTo(currentIndex - 1)}
              pos="relative"
              zIndex="3"
              w="3rem"
              h="3rem"
              p="0"
              bg="white"
              border="2px solid"
              borderColor="gray300"
              shadow="3"
              rounded="circle"
            >
              <Icon
                name="LongLeft"
                color={isFirst ? "white" : "medium"}
                size="24px"
              />
            </Button>
          )}
          <Div
            w={isFirst ? "calc(100% - 3rem)" : "calc(100% - 6rem)"}
            className={`thumb-swiper ${!isFirst && "not-first"} ${
              !isLast && "not-last"
            }`}
          >
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={16}
              slidesPerView="auto"
              slidesOffsetAfter={40}
              slidesOffsetBefore={!isFirst && 40}
              mousewheel={true}
            >
              {listing.smallCoverUrl && (
                <SwiperSlide
                  className={0 === currentIndex && "currently-active"}
                  onClick={() => slideTo(0)}
                  key={`swiper-thumb-${0}`}
                  style={{ width: "72px" }}
                >
                  <Div
                    cursor="pointer"
                    shadow="1"
                    rounded="md"
                    bgImg={listing.smallCoverUrl}
                    bgSize="cover"
                    bgPos="center"
                    p={{ b: "68.3%" }}
                  />
                </SwiperSlide>
              )}
              {filterImages(listing).map((image, index) => {
                return (
                  <SwiperSlide
                    className={index + 1 === currentIndex && "currently-active"}
                    onClick={() => slideTo(index + 1)}
                    key={`swiper-thumb-${index + 1}`}
                    style={{ width: "72px" }}
                  >
                    <Div
                      cursor="pointer"
                      shadow="1"
                      rounded="md"
                      bgImg={image.xsmallImageUrl}
                      bgSize="cover"
                      bgPos="center"
                      p={{ b: "68.3%" }}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Div>
          {!isLast && (
            <Button
              // disabled={isLast}
              // style={isLast ? { borderColor: 'white' } : {}}
              onClick={() => slideTo(currentIndex + 1)}
              pos="relative"
              zIndex="2"
              w="3rem"
              h="3rem"
              p="0"
              bg="white"
              border="2px solid"
              borderColor="gray300"
              shadow="3"
              rounded="circle"
            >
              <Icon
                name="LongRight"
                color={isLast ? "white" : "medium"}
                size="24px"
              />
            </Button>
          )}
        </Div>
      </Div>
    </Div>
  );
}
