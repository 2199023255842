import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Div, Text, Row, Col, Button, Icon } from "atomize";
import { axiosInstance } from "../utilities/axiosInstance";
import { getErrorMessage } from "../utilities/helpers";

import CustomContainer from "../components/atoms/CustomContainer";
import Root from "../components/common/Root";
import LoadingScreen from "../components/common/LoadingScreen";
import ProductCarousel from "../components/carPage/ProductCarousel";
import ProductDescription from "../components/carPage/ProductDescription";
import ProductSpecifications from "../components/carPage/ProductSpecifications";
import ProductLocation from "../components/carPage/ProductLocation";
import PricingDetails from "../components/carPage/PricingDetails";
import ProductSidebar from "../components/carPage/ProductSidebar";
import ArchiveModal from "../components/carPage/ArchiveModal";
import ArchiveBar from "../components/carPage/ArchiveBar";
import { toast } from "react-toastify";
import { AuthContext } from "../contexts/auth";

export default function CarDetail() {
  let { slug } = useParams();
  const { user } = useContext(AuthContext);
  const [showArchiveModal, setShowArchiveModal] = useState();
  const [listing, setListing] = useState({
    isLoading: true,
    data: null,
    owner: null,
    error: null,
  });
  const [message, setMessage] = useState(
    "This listing contained information that violates the Swopr user agreement."
  );
  const [archieving, setArchieving] = useState(false);

  /**
   * Change Listing with new values
   * @param {new values} e
   */
  const changeListing = (e) => {
    setListing({ ...listing, ...e });
  };

  /**
   *
   * @param {listing} listing
   */
  const getUser = (listing) => {
    axiosInstance
      .get(`/listings/owner/${listing.id}`)
      .then((owner) => {
        changeListing({
          isLoading: false,
          data: listing,
          owner: owner.data.data,
          error: null,
        });
      })
      .catch((error) => {
        changeListing({
          isLoading: false,
          error: "Error getting the owner",
        });
      });
  };

  /**
   * Get listing and call for owner
   */
  const getListing = () => {
    axiosInstance
      .get(`/listings/${slug}`)
      .then((response) => {
        getUser(response.data.data);
      })
      .catch((error) => {
        changeListing({
          isLoading: false,
          error: "Error getting the listing",
        });
      });
  };

  useEffect(() => {
    getListing();
  }, []);

  const archiveListing = async () => {
    if (user.role !== "ADMIN" && user.role !== "EDITOR") return;
    setArchieving(true);
    try {
      await axiosInstance.post("/listings/archive", {
        id: listing.data.id,
        message,
      });
      setShowArchiveModal(false);
      setArchieving(false);
      changeListing({
        ...listing,
        data: { ...listing.data, status: "ARCHIVED" },
      });
    } catch (error) {
      setArchieving(false);
      toast.error(getErrorMessage(error));
    }
  };

  const unarchiveListing = async () => {
    if (user.role !== "ADMIN" && user.role !== "EDITOR") return;
    setArchieving(true);
    try {
      const response = await axiosInstance.post("/listings/unarchive", {
        id: listing.data.id,
      });
      setArchieving(false);
      changeListing({
        ...listing,
        data: {
          ...listing.data,
          status: response.data.data ? "ACTIVE" : "DRAFT",
        },
      });
    } catch (error) {
      setArchieving(false);
      toast.error(getErrorMessage(error));
    }
  };

  if (!listing.data) {
    return <LoadingScreen />;
  }

  return (
    <Root>
      <ArchiveModal
        archiveListing={archiveListing}
        message={message}
        setMessage={setMessage}
        isOpen={showArchiveModal}
        close={() => setShowArchiveModal(false)}
        archieving={archieving}
      />
      <CustomContainer>
        <Row>
          <Col
            size={{
              xs: "16",
              md: "10",
              xl: "10",
            }}
            offset={{ xl: "1" }}
          >
            <Div
              border={{ r: { md: "1px solid" } }}
              m={{ r: { md: "1.5rem", lg: "0" } }}
              borderColor="o-light"
              p={{ t: "6rem", b: "5.5rem" }}
            >
              <ProductCarousel listing={listing.data} />
              <Div border={{ b: "1px solid" }} borderColor="o-light">
                <ProductDescription listing={listing.data} />
              </Div>
              <ProductSpecifications listing={listing.data} />
              <ProductLocation listing={listing.data} />
              <Div
                // ref={mobilePdpPricingRef}
                id="mobilePdpPricing"
                border={{ t: "1px solid" }}
                borderColor="o-light"
                p={{ y: "4.5rem" }}
                m={{ t: "2.5rem" }}
                d={{ xs: "block", md: "none" }}
              >
                <Text textSize="title" textWeight="500" m={{ b: "2.5rem" }}>
                  Lease details
                </Text>
                <PricingDetails
                  listing={listing.data}
                  //   open={() => setshowChatModal(true)}
                  ownerEmail={listing.owner.email}
                  //   openCallModal={() => setshowChatModal(true)}
                />
              </Div>
            </Div>
          </Col>
        </Row>
      </CustomContainer>
      {/* <MobileFixedFooter
        fixedFooterRef={fixedFooterRef}
        monthlyCost={listing.monthlyCost}
      /> */}
      {/* <ProductChatModal
        isOpen={showChatModal}
        close={() => setshowChatModal(false)}
        owner={owner}
        listingId={listing.id}
      /> */}
      {/* <ProductCallModal
        isOpen={showCallModal}
        ownerName={owner.firstName}
        phone={listing.phone}
        close={() => setShowCallModal(false)}
      /> */}
      <ProductSidebar
        listing={listing.data}
        ownerEmail={listing.owner.email}
        // open={() => setshowChatModal(true)}
        // openCallModal={() => setShowCallModal(true)}
      />
      <ArchiveBar
        listing={listing}
        user={user}
        setShowArchiveModal={setShowArchiveModal}
        unarchiveListing={unarchiveListing}
        archieving={archieving}
      />
    </Root>
  );
}
