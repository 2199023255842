import React from "react";
import { Div, Text, Icon, Button, Radiobox, Textarea } from "atomize";

import Modal from "../atoms/Modal";

const options = [
  "This listing contained information that violates the Swopr user agreement.",
];

export default function ArchiveModal({
  isOpen,
  message,
  setMessage,
  close,
  archiveListing,
  archieving,
}) {
  return (
    <Modal
      maxW="32rem"
      isOpen={isOpen}
      // close={close}
      align="center"
      rounded={{ sm: "xl" }}
    >
      <Div
        p="1.5rem"
        d="flex"
        justify="space-between"
        border={{ b: "1px solid" }}
        borderColor="o-light"
      >
        <Div p="0 0 0 0.5rem" d="flex">
          <Div
            d="flex"
            align="center"
            justify="center"
            h="2.5rem"
            w="2.5rem"
            bg="danger200"
            m={{ r: "1rem" }}
            rounded="circle"
          >
            <Icon name="DeleteSolid" size="20px" color="danger600" />
          </Div>
          <Text textSize="heading" textColor="black900" textWeight="600">
            Archive listing.
          </Text>
        </Div>
        <Button
          onClick={close}
          h="2.5rem"
          w="2.5rem"
          bg="gray200"
          border="1px solid"
          borderColor="gray300"
          rounded="circle"
          d="flex"
          align="center"
          justify="center"
        >
          <Icon name="Cross" color="black800" />
        </Button>
      </Div>
      <Div p="1rem 2rem 2rem">
        <Div p="1rem 0 2rem">
          {options.map((item) => {
            const isChecked = item === message;
            return (
              <Div
                onClick={() => setMessage(item)}
                cursor="pointer"
                d="flex"
                m={{ b: "0.75rem" }}
              >
                <Radiobox checked={isChecked} />
                <Text textColor={isChecked ? "dark" : "light"} textWeight="500">
                  {item}
                </Text>
              </Div>
            );
          })}
          <Div
            onClick={() => setMessage("")}
            cursor="pointer"
            d="flex"
            m={{ b: "0.5rem" }}
          >
            <Radiobox checked={!options.includes(message)} />
            <Text
              textColor={options.includes(message) ? "light" : "dark"}
              textWeight="500"
            >
              Other reason
            </Text>
          </Div>
          {!options.includes(message) && (
            <Textarea
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Write reason for archiving."
              placeholderTextColor="disabled"
              m={{ t: "1rem" }}
              bg="gray200"
              shadow="inner1"
              border="none"
              fontFamily="primary"
              p="1rem"
              transition="none"
            />
          )}
        </Div>
        <Div
          d="flex"
          align={{ md: "center" }}
          flexDir={{ xs: "column", md: "row" }}
        >
          <Button
            bg="danger600"
            h="3rem"
            p="0 1.5rem"
            textWeight="600"
            m={{ r: { md: "2rem" } }}
            onClick={archiveListing}
            disabled={archieving}
          >
            Archive listing
          </Button>
        </Div>
      </Div>
    </Modal>
  );
}
