import React, { useContext } from "react";
import { Div, Row, Col } from "atomize";
import { AuthContext } from "../contexts/auth";

import CustomContainer from '../components/atoms/CustomContainer'
import Root from "../components/common/Root";
import ProfileSidebar from "../components/account/ProfileSidebar";
import ProfileTopbar from "../components/account/ProfileTopbar";
import AccountSettingsForm from "../components/account/AccountSettingsForm";

export default function AccountSettings() {
  const { user } = useContext(AuthContext);

  return (
    <Root>
      <ProfileSidebar />
      <ProfileTopbar />
      <Div p={{ t: { xs: "3rem", lg: "7.5rem" }, b: "18.5rem" }}>
        <CustomContainer>
          <Row>
            <Col
              size={{
                xs: "16",
                lg: "9",
                xl: "7",
              }}
              offset={{ lg: "5", xl: "5" }}
            >
              <AccountSettingsForm user={user} />
            </Col>
          </Row>
        </CustomContainer>
      </Div>
    </Root>
  );
}
