import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Div, Text } from "atomize";
import SingleImageThumbnail from "./SingleImageThumbnail";

const SingleImageDropzone = ({
  thumbnail,
  setThumbnail,
  coverUrl,
  removeCover,
  isLoading,
  isMessageImage,
  messageImage,
}) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      setThumbnail(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    [thumbnail]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/jpg,image/png",
    maxSize: 2000000,
    multiple: false,
  });

  // listing already has an image
  if (coverUrl || isMessageImage) {
    return (
      <SingleImageThumbnail
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        open={open}
        imageSrc={coverUrl || messageImage}
        removeImage={removeCover}
        showReplace={false}
        isMessageImage={isMessageImage}
      />
    );
  }

  // if user has selected an image
  if (thumbnail?.length) {
    return (
      <Div>
        {thumbnail.map((file) => (
          <SingleImageThumbnail
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            open={open}
            key={file.name}
            imageSrc={file.preview}
            removeImage={() => setThumbnail([])}
            showReplace={true}
            isLoading={isLoading}
          />
        ))}
      </Div>
    );
  }

  // otherwise show area to select an image
  return (
    <>
      <Div
        m={{ t: "4px" }}
        p="2.5rem"
        className="custom-border"
        rounded="xl"
        d="flex"
        align="center"
        justify="center"
        flexDir="Column"
        cursor="pointer"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Text textWeight="600" textSize="paragraph" textColor="text-medium">
            Drop your images here
          </Text>
        ) : (
          <>
            <Text
              style={{ whiteSpace: "nowrap" }}
              textWeight="600"
              textSize="paragraph"
              textColor="text-medium"
            >
              Drag image here or{" "}
              <Text tag="span" textColor="brand600">
                upload image
              </Text>
            </Text>
            <Text
              m={{ t: "4px" }}
              style={{ whiteSpace: "nowrap" }}
              textWeight="500"
              textSize="tiny"
              textColor="text-light"
            >
              Maximum file size - 5 MB
            </Text>
          </>
        )}
      </Div>
    </>
  );
};

export default SingleImageDropzone;
