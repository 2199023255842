import { useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthProvider, AuthContext } from "./contexts/auth";
import { PlanPriceProvider } from "./contexts/PlansPriceProvider";
import { FiltersProvider } from "./contexts/filtersProvider";
import "./style/app.scss";
import "react-toastify/dist/ReactToastify.min.css";

import SignIn from "./pages/SignIn";
import Listings from "./pages/Listings";
import CarDetail from "./pages/CarDetail";
import EditProfile from "./pages/EditProfile";
import AccountSettings from "./pages/AccountSettings";
import EditListing from "./pages/EditListing";
import TeamMembers from "./pages/TeamMembers";
import Invite from "./pages/Invite";

toast.configure({
  autoClose: 5000,
  draggable: false,
  position: toast.POSITION.BOTTOM_RIGHT,
});

export default function App() {
  return (
    <AuthProvider>
      <PlanPriceProvider>
        <FiltersProvider>
          <BrowserRouter>
            {/* <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/login">Sign In</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/listing">Dashboard</Link>
            </li>
          </ul>

          <hr /> */}

            <Switch>
              <PrivateRoute exact path="/" component={Listings} />
              <AuthRoute exact path="/login" component={SignIn} />
              <AuthRoute exact path="/signup" component={Invite} />
              <PrivateRoute exact path="/listing/:slug" component={CarDetail} />
              <PrivateRoute
                exact
                path="/profile/edit-profile"
                component={EditProfile}
              />
              <PrivateRoute
                exact
                path="/profile/account-settings"
                component={AccountSettings}
              />
              <AdminRoute
                exact
                path="/profile/team-members"
                component={TeamMembers}
              />
              <AdminEditorRoute
                exact
                path="/listing/:slug/edit"
                component={EditListing}
              />
              <Redirect to="/" />
            </Switch>
          </BrowserRouter>
        </FiltersProvider>
      </PlanPriceProvider>
    </AuthProvider>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

function AuthRoute({ component: Component, ...rest }) {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
}

function AdminRoute({ component: Component, ...rest }) {
  const { user } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        user?.role !== "ADMIN" ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
}

function AdminEditorRoute({ component: Component, ...rest }) {
  const { user } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        user?.role !== "ADMIN" && user?.role !== "EDITOR" ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}
