import React from "react";
import { Div } from "atomize";
import Root from "./Root";

export default function LoadingScreen() {
  return (
    <Root>
      <Div
        p={{ y: "4rem" }}
        minH="100vh"
        d="flex"
        align="center"
        justify="center"
        textAlign="center"
      >
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Div>
    </Root>
  );
}
