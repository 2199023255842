import React from "react";
import { Div } from "atomize";

import Modal from '../atoms/Modal'
import PricingPlan from "./PricingPlan";
import UpgradePricingMobile from "./UpgradePricingMobile";
import PricingListMobile from "./PricingListMobile";

export default function PricingModal({ isOpen, close, listingData = { data: {} }, ...rest }) {
  const { data: listing } = listingData;

  return (
    <Div>
      {/* Desktop */}
      <Div d={{ xs: 'none', md: 'block' }}>
        <Modal isOpen={isOpen} close={close} p="0">
          <PricingPlan close={close} listing={listing} {...rest} />
        </Modal>
      </Div>
      {/* Mobile */}
      <Div d={{ xs: 'block', md: 'none' }}>
        <Modal
          isOpen={isOpen}
          close={close}
          p="0"
          maxW="328px"
          minH="0px"
          rounded="8px"
          m={{ t: "2rem" }}
          bg={
            listing.payment && listing.plan === "basic"
              ? "white"
              : "transparent"
          }
        >
          {listing.payment && listing.plan === "basic" ? (
            <UpgradePricingMobile close={close} listing={listing} {...rest} />
          ) : (
            listing.payment === false && (
              <PricingListMobile close={close} listing={listing} {...rest} />
            )
          )}
        </Modal>
      </Div>

    </Div>
  );
}
