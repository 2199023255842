import React, { useState, useContext } from "react";
import { Div, Text, Row, Col, Icon } from "atomize";

import DefaultInput from "../atoms/DefaultInput";
import DateSelector from "../atoms/DateSelector";
import ListingFooter from "./ListingFooter";
import AditionalIncentiveTooltip from "./AditionalIncentiveTooltip";
import { AuthContext } from "../../contexts/auth";

export default function LeaseDetailForm({
  listingData,
  currentStep,
  setCurrentStep,
  onSelectChange,
  onSubmit,
  handleChange,
  errors,
}) {
  const { user } = useContext(AuthContext);
  const [
    showAdditionalIncentiveTooltip,
    setShowAdditionalIncentiveTooltip,
  ] = useState(false);

  const { isEditing } = listingData;

  const {
    leasingCompany,
    leaseEnd,
    currentMiles,
    remainingMiles,
    incentive,
    monthlyPayment,
    milesPerMonth,
  } = listingData.data;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.role !== "ADMIN" && user.role !== "EDITOR") {
      return setCurrentStep(currentStep + 1);
    }
    onSubmit(e);
  };

  return (
    <Div>
      <Text textWeight="500" textColor="light" m={{ b: "0.5rem" }}>
        Step {currentStep} of 5
      </Text>
      <Text
        textColor="text-dark"
        textSize="display2"
        textWeight="600"
        m={{ b: "2.5rem" }}
      >
        Lease details
      </Text>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col size="16">
            <DefaultInput
              label="Leasing company"
              name="leasingCompany"
              placeholder=""
              h="3rem"
              m={{ b: "1.5rem" }}
              onChange={handleChange}
              value={leasingCompany}
              // error={
              //   isValidStr(errors.leasingCompany) && errors.leasingCompany
              // }
            />
          </Col>
          <Col size="8">
            <DateSelector
              label="Lease end date (Estimated)"
              value={leaseEnd}
              minDate={new Date()}
              maxDate={null}
              onChange={(value) => onSelectChange("leaseEnd", value)}
            />
          </Col>
          <Col size="8">
            <DefaultInput
              label="Current miles"
              type="number"
              name="currentMiles"
              placeholder=""
              h="3rem"
              m={{ b: "1.5rem" }}
              onChange={handleChange}
              value={currentMiles}
              // error={isValidStr(errors.currentMiles) && errors.currentMiles}
            />
          </Col>
          <Col size="8">
            <DefaultInput
              label="Remaining miles"
              type="number"
              name="remainingMiles"
              placeholder=""
              h="3rem"
              m={{ b: "1.5rem" }}
              onChange={handleChange}
              value={remainingMiles}
              min="100"
              // error={
              //   isValidStr(errors.remainingMiles) && errors.remainingMiles
              // }
            />
          </Col>
          <Col size="8">
            <DefaultInput
              label="Miles per month (Auto calculated )"
              type="number"
              name="milesPerMonth"
              placeholder=""
              h="3rem"
              m={{ b: "1.5rem" }}
              value={milesPerMonth}
              disabled
            />
          </Col>
          <Col size="8">
            <Div m={{ b: "0.25rem" }} d="flex" align="center">
              <Text textSize="caption" textColor="text-light" textWeight="500">
                Additional incentive
              </Text>
              <Div
                onMouseEnter={() => setShowAdditionalIncentiveTooltip(true)}
                onMouseLeave={() => setShowAdditionalIncentiveTooltip(false)}
                m={{ l: "0.5rem" }}
                d="flex"
                pos="relative"
              >
                <Icon name="InfoSolid" size="16px" color="brand700" />
                <AditionalIncentiveTooltip
                  isOpen={showAdditionalIncentiveTooltip}
                />
              </Div>
            </Div>
            <DefaultInput
              type="number"
              name="incentive"
              placeholder=""
              h="3rem"
              m={{ b: "1.5rem" }}
              onChange={handleChange}
              value={incentive}
              // error={isValidStr(errors.incentive) && errors.incentive}
            />
          </Col>
          <Col size="8">
            <DefaultInput
              label="Monthly lease payment (Excluding taxes)"
              type="number"
              name="monthlyPayment"
              placeholder=""
              h="3rem"
              m={{ b: "1.5rem" }}
              onChange={handleChange}
              value={monthlyPayment}
              // error={
              //   isValidStr(errors.monthlyPayment) && errors.monthlyPayment
              // }
              step="any"
              min="10"
            />
          </Col>
        </Row>
        <ListingFooter
          back={() => setCurrentStep(currentStep - 1)}
          isEditing={isEditing}
        />
      </form>
    </Div>
  );
}
