import React, { useEffect, useState, useContext } from "react";
import Axios from "axios";
import { axiosInstance } from "../utilities/axiosInstance";
import { getErrorMessage } from "../utilities/helpers";
import { Row, Col, Div, Text, Button, Icon } from "atomize";
import ReactPaginate from "react-paginate";

import CustomContainer from "../components/atoms/CustomContainer";
import LoadingScreen from "../components/common/LoadingScreen";
import Root from "../components/common/Root";
import ListingList from "../components/listings/ListingList";
// import EmptyListing from "../components/listings/EmptyListing";
import SearchFilters from "../components/explore/SearchFilters";
import DesktopFilters from "../components/explore/DesktopFilters";
import { FiltersContext } from "../contexts/filtersProvider";
import NoResultFound from "../components/listings/NoResultFound";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export default function Listings() {
  const [listings, setListings] = useState({
    data: null,
    isLoading: true,
    errorMessage: null,
  });
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [makesAndModels, setMakesAndModels] = useState({});
  const [availableFilters, setAvailableFilters] = useState({});
  const [locations, setLocations] = useState([]);
  const [emails, setEmails] = useState([]);
  const [allModels, setAllModels] = useState([]);
  const { appliedFilters, setFilters } = useContext(FiltersContext);
  const History = useHistory()

  const fetchMakesAndModels = async () => {
    const carMakesAndModels = await Axios.get(
      `${process.env.REACT_APP_API_URL}/listings/cars/makes-models`,
      {
        params: {
          all: true,
        },
      }
    );

    setMakesAndModels({
      carMakes: carMakesAndModels.data.data.carMakes,
      carModels: carMakesAndModels.data.data.carModels,
    });
    setAllModels(carMakesAndModels.data.data.carModels);
  };

  const handleModelsOnMakesChange = (carModels) => {
    setMakesAndModels((prev) => ({ ...prev, carModels }));
  };

  const getFilteredCars = (params) => {
    setLoading(true);
    axiosInstance
      .get("/listings", {
        params: params.maxLeasePayment
          ? {
              ...params,
              maxLeasePayment: params.maxLeasePayment + 1,
              minLeasePayment: params.minLeasePayment - 1,
              all: true,
            }
          : { ...params, all: true },
      })
      .then((response) => {
        setLoading(false);
        changeListings({ data: response.data.data });
        setPagination(response.data.pagination);
      })
      .catch((error) => {
        setLoading(false);
        changeListings({
          errorMessage: getErrorMessage(error),
        });
      });
  };

  const fetchAvailableFilters = async () => {
    const minMaxValues = await Axios.get(
      `${process.env.REACT_APP_API_URL}/listings/filters/min-max`,
      {
        params: { all: true },
      }
    );

    const locations = await Axios.get(
      `${process.env.REACT_APP_API_URL}/listings/states`,
      {
        params: {
          all: true,
        },
      }
    );

    const userEmails = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/users/users-email`
    );

    setAvailableFilters(minMaxValues.data.data);
    setLocations(locations.data.data);
    setEmails(userEmails.data.data);
  };

  /**
   * Change listing state with new values
   * @param {new values array} e
   */
  const changeListings = (e) => {
    setListings({ ...listings, ...e });
  };

  useEffect(() => {
    changeListings({ isLoading: true });
    fetchMakesAndModels();
    fetchAvailableFilters();

    axiosInstance
      .get("/listings", {
        params: { ...appliedFilters, all: true },
      })
      .then((response) => {
        changeListings({ isLoading: false, data: response.data.data });
        setPagination(response.data.pagination);
      })
      .catch((error) => {
        console.log(error);
        changeListings({
          isLoading: false,
          errorMessage: getErrorMessage(error),
        });
      });
  }, []);

  const handlePageClick = (data) => {
    getFilteredCars({
      page: data.selected + 1,
      ...appliedFilters,
    });
  };

  const createListing = () => {
    axiosInstance
      .post("listings", { plan: "basic" })
      .then((response) => {
        History.push(`/listing/${response.data.data.slug}/edit`);
      })
      .catch((error) => {
        console.log(error);
        toast.error(getErrorMessage(error));
      });
  }

  const updateState = (ids)  => {
    const pageCount = Math.ceil(
      pagination.total / pagination.perPage
    );
    
    if(parseInt(pagination.currentPage) === pageCount && ids.length === listings.data.length){
      return getFilteredCars({
        page: pagination.currentPage - 1 || 1,
        ...appliedFilters,
      });
    }

      getFilteredCars({
        page: pagination.currentPage,
        ...appliedFilters,
      });


   

    // setListings((prevState) => ({
    //   ...prevState,
    //   data: prevState.data.filter(
    //     (listing) => ids.indexOf(listing.id) === -1
    //   ),
    // }));

    // setPagination(prev => ({
    //   ...prev,
    //   total: prev.total - ids.length
    // }))
  }

  if (listings.isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Root>
      <CustomContainer>
        <Row>
          <Col
            size={{
              xs: "16",
            }}
            offset={{ xl: "1" }}
            p={{ t: "7.5rem", b: "2.35rem", x: "8px" }}
          >
            <Div
              d="flex"
              flexDir={{ xs: "column", md: "row" }}
              align={{ md: "center" }}
              justify="space-between"
            >
              <Text
                textSize="display2"
                m={{ b: { xs: "1rem", md: "0" } }}
                textWeight="600"
              >
                All Listings
              </Text>
              <Button
                bg="brand700"
                w="9rem"
                h="3rem"
                rounded="lg"
                textSize="paragraph"
                textWeight="600"
                p="0.75rem 1.25rem 0.75rem 1rem"
                prefix={
                  <Icon
                    name="Plus"
                    size="1.25rem"
                    m={{ r: "0.75rem" }}
                    color="white"
                  />
                }
                  onClick={createListing}
              >
                New Listing
              </Button>
            </Div>
          </Col>
        </Row>
        <Div d={{ xs: "none", md: "block" }} m={{ b: "2.5rem" }}>
          <Row>
            <Col size={{ xs: "16" }} offset={{ xl: "1" }}>
              <Div maxW={{ lg: "42rem" }}>
                <SearchFilters
                  isLoading={loading}
                  appliedFilters={appliedFilters}
                  setFilters={setFilters}
                  getFilteredCars={getFilteredCars}
                  carMakesAndModels={makesAndModels}
                  updateModels={handleModelsOnMakesChange}
                  allModels={allModels}
                />
              </Div>
              <DesktopFilters
                isLoading={loading}
                appliedFilters={appliedFilters}
                setFilters={setFilters}
                getFilteredCars={getFilteredCars}
                locations={locations}
                emails={emails}
                availableFilters={availableFilters}
              />
            </Col>
          </Row>
        </Div>

        <Row>
          <Col
            size={{
              xs: "16",
              xl: "14",
            }}
            offset={{ xl: "1" }}
          >
            {listings?.data?.length > 0 ? (
              <ListingList
                listings={listings.data}
                totalListings={pagination.total}
                updateState={updateState}
              />
            ) : (
              <NoResultFound />
            )}

            {/* Pagination */}
            {listings?.data?.length > 0 > 0 && (
              <Div m={{ t: "2rem" }}>
                <Row>
                  <Col size={"16"}>
                    <Div p="1rem 0 5rem">
                      <ReactPaginate
                        previousLabel={
                          <Icon
                            name="LeftArrow"
                            size="16px"
                            color="text-disabled"
                          />
                        }
                        nextLabel={
                          <Icon
                            name="RightArrow"
                            size="16px"
                            color="brand700"
                          />
                        }
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(
                          pagination.total / pagination.perPage
                        )}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={pagination.currentPage - 1 || 0}
                      />
                    </Div>
                  </Col>
                </Row>
              </Div>
            )}
          </Col>
        </Row>
      </CustomContainer>
    </Root>
  );
}
