import React from "react";
import { Div, Text, Button } from "atomize";
import ChangePassword from "../modals/ChangePassword";
import EditEmail from "../modals/EditEmail";
import { capitalizeFirstLetter } from "../../utilities/helpers";

export default function AccountSettingsForm({ user }) {
  const [showChangePassword, setShowChangePassword] = React.useState(false);
  const [showEditEmail, setShowEditEmail] = React.useState(false);

  return (
    <>
      <ChangePassword
        isOpen={showChangePassword}
        close={() => setShowChangePassword(false)}
      />
      <EditEmail
        isOpen={showEditEmail}
        closeModal={() => setShowEditEmail(false)}
      />
      <Div>
        <Text
          textColor="text-dark"
          textSize="display2"
          textWeight="600"
          m={{ b: "4.5rem" }}
        >
          Account settings
        </Text>
        <Div
          p={{ xs: "23.5px 0 24.5px", lg: "15.5px 0 18.5px" }}
          d={{ xs: "block", md: "flex" }}
          align="center"
          justify="space-between"
          border={{ t: "1px solid" }}
          borderColor="o-light"
        >
          <Div>
            <Text
              textColor="text-dark"
              textSize="subheader"
              m={{ b: "0.25rem" }}
              textWeight="500"
            >
              Email
            </Text>
            <Text textColor="brand700">{user.email}</Text>
          </Div>
          {/* <Button
            type="button"
            bg="white"
            textColor="text-medium"
            minW="7rem"
            h="2.5rem"
            shadow="1"
            hoverShadow="0"
            transition
            textWeight="600"
            border="1px solid"
            borderColor="o-medium"
            m={{ t: { xs: "1.125rem", md: "0" } }}
            onClick={() => setShowEditEmail(true)}
          >
            Edit Email
          </Button> */}
        </Div>
        <Div
          p={{ xs: "23.5px 0 24.5px", lg: "15.5px 0 18.5px" }}
          d={{ xs: "block", md: "flex" }}
          align="center"
          justify="space-between"
          border={{ t: "1px solid" }}
          borderColor="o-light"
        >
          <Div>
            <Text
              textColor="text-dark"
              textSize="subheader"
              m={{ b: "0.25rem" }}
              textWeight="500"
            >
              Change password
            </Text>
            <Text textColor="text-light">
              Set a unique password to protect your account.
            </Text>
          </Div>
          <Button
            type="button"
            bg="white"
            textColor="text-medium"
            minW="9rem"
            h="2.5rem"
            shadow="1"
            textWeight="600"
            border="1px solid"
            hoverShadow="0"
            transition
            borderColor="o-medium"
            m={{ t: { xs: "1.125rem", md: "0" } }}
            onClick={() => setShowChangePassword(true)}
          >
            Change Password
          </Button>
        </Div>
        <Div
          p={{ xs: "23.5px 0 24.5px", lg: "15.5px 0 18.5px" }}
          d={{ xs: "block", md: "flex" }}
          align="center"
          justify="space-between"
          border={{ t: "1px solid" }}
          borderColor="o-light"
        >
          <Div>
            <Text
              textColor="text-dark"
              textSize="subheader"
              m={{ b: "0.25rem" }}
              textWeight="500"
            >
              Access level
            </Text>
            <Text textColor="text-light">Edit or view listings</Text>
          </Div>
          <Text
            d="flex"
            align="center"
            justify="center"
            rounded="md"
            textColor="text-medium"
            minW="7rem"
            h="2.5rem"
            textWeight="600"
            // border="1px solid"
            // borderColor="o-medium"
            m={{ t: { xs: "1.125rem", md: "0" } }}
          >
            {capitalizeFirstLetter(user.role)}
          </Text>
        </Div>
      </Div>
    </>
  );
}
